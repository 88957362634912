import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import Attack from '../../../asset/photoshop/attackhero.png';
import Khien from '../../../asset/photoshop/definehero.png';
import HealthWhite from '../../../asset/photoshop/healdhero.png';
import Money from '../../../asset/photoshop/power.png';
import Rank from '../../../asset/photoshop/rank.png';
import Wod from '../../../asset/images/wod.png'
import Ruby from '../../../asset/images/ruby.png'
import { strWallet, truncate } from '../../../commons/blockchain';
import { toastErrorMessage, toastSuccessMessage } from '../../../commons/message';
import { smallNumber } from '../../../contractAccount';
import WithSpin from '../../../HOC/WithSpin';
import { port } from '../../../port';
import { getAllHeros } from '../../../Redux/action/api/dogeReducer';
import { contractServices } from '../../../Services/contract';
import './style.scss';
function CardHero({ item, index, onShow, handleClickOpen, sell }) {
    const tilt = useRef(null);
    const [card, setCard] = useState(false)
    const [price, setPrice] = useState(0)
    const [copy, setCopy] = useState(false)
    const { priceCoin, profile } = useSelector(state => state.userReducer)
    const { resPending, setResPending } = useState({})
    const inputArea = React.useRef(null);
    const dispatch = useDispatch()
    const [isSpin, setIsSpin] = useState(false)
    function handleCopy(e) {
        navigator.permissions
            .query({ name: "clipboard-write" })
            .then((result) => {
                if (result.state === "granted" || result.state === "prompt") {
                    updateClipboard(inputArea.current?.innerText);
                }
            });
    }
    function updateClipboard(newClip) {
        navigator.clipboard.writeText(newClip).then(
            () => {
                setCopy(true);
                toast.success('Copied!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            },
            () => {
                setCopy(false);
                // setOpen(false)

            }
        );
    }
    async function harvest() {
        try {
            setIsSpin(true)
            const contract = await contractServices()
            const pendingReward = await contract.getPendingReward(contract.currentUserAddress)
            const pendingRuby = smallNumber(pendingReward[0])
            const pendingWOD = smallNumber(pendingReward[1])
            await contract.harvest()
            window.location.reload()
            toastSuccessMessage(`Successful harvest`)
            setIsSpin(false)
        } catch (error) {
            setIsSpin(false)
            toastErrorMessage('Transaction Error')
        }
    }
    async function attackHero(defenderAddress) {
        //attack a Doge
        try {
            setIsSpin(true)

            const contract = await contractServices()
            const attackerAddress = contract.currentUserAddress

            // address của player muốn attack, lấy danh sách các address hiện tại bằng getPlayerList()

            const canAttack = await contract.areInPointsRange(attackerAddress, defenderAddress)
            if (canAttack) {
                //có thể tấn công
                await contract.attackHero(defenderAddress)
                dispatch(getAllHeros({ limit: 12, page: 1 }))
                // dispatch(getHistoryAttackAll({ limit: 12, page: 1 }))
                // window.location.reload()
                toastSuccessMessage('Successful attack')
                setIsSpin(false)

            } else {
                toastErrorMessage("Unable to attack")
            }
        } catch (error) {
            toastErrorMessage("Transaction Error!")
            setIsSpin(false)

        }

    }
    useEffect(async () => {
        const options = { speed: 400, max: 25 }
        // tilt.hover()
        const width = window.innerWidth
        if (width < 800) setCard(true)

    }, [])
    /// KIẾM TRA CARD CÓ PHẢI CARD CỦA ADDRESS KHÔNG
    const checkCardAddress = profile?.address?.toLowerCase() == item?.owner?.toLowerCase() ? true : false
    return (

        // <div className={`boxCard ${card ? 'reponsive' : ''}`} hover={true} key={index} ref={tilt}>
        <div className={`boxCard reponsive cardHero`} hover={true} key={index} ref={tilt}>
            {/* <h2 className="name" ref={tilt}>ID: {item.dogeId}</h2> */}
            {/* <p className='id'>ID : 13</p> */}
            <div className='imgTop' style={{
                background: `${item?.dogeFirstDeposit?.background}`
            }}>

            </div>
            <img ref={tilt} src={`${port}${item?.dogeFirstDeposit?.image}`} alt="" className='product' />
            {/* 
            <h2 className="price" style={{
                textOverflow: "ellipsis"
            }} ref={tilt}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src={Money} height={35} />
                    <b className=' ml-1' >
                        $ {truncate(item?.dailyUsdOfDay / 1e18, 2)}
                    </b>
                </div>
            </h2> */}
            <div className='info row mt-1'>
                <div className="col-6">
                    <img src={Rank} className="imgInfoHero" height={35} />
                    <b className='fzs ml-1' >
                        {/* dogeDetail?.health */}
                        {item.ranker}
                    </b>
                </div>
                <div className="col-6  pl-1">
                    <img src={HealthWhite} className="imgInfoHero" height={35} />
                    <b className='fzs ml-1' >
                        {item?.health}
                    </b>
                </div>
                <div className="col-6">
                    <img src={Attack} className="imgInfoHero" height={35} />
                    <b className='fzs ml-1' >
                        {item?.attack}

                    </b>
                </div>
                <div className="col-6  pl-1">
                    <img src={Khien} className="imgInfoHero" height={35} />
                    <b className='fzs ml-1' >
                        {item?.defense}
                    </b>
                </div>

            </div>
            {/* <div className="priceToken">
                <div className='token'>
                    <img src={Ruby} width={30} height={30} alt="" />
                    <b style={{
                        color:"#f92fb6"
                    }}>{truncate(item.pending.pendingRuby/1e18,2)}</b>
                </div>
                <div className='usd'>
                    <p className="priceUSD">~ $ {truncate(item.pending.pendingRuby/1e18 * priceCoin.ruby,2)}</p>
                </div>
            </div> */}
            {/* item.dailyUsdOfDay */}
            <div className='ownerInput mt-1'>
                <b>
                    Owner: {strWallet(item.owner)}
                </b>
                <ContentCopyIcon onClick={handleCopy} className="ml-2" sx={{
                    cursor: 'pointer;'
                }} />
                <div ref={inputArea} style={{ display: "none" }}>
                    {item.owner}
                </div>
            </div>

            <div className='groupButton'>
                <button style={{
                    border:"none"
                }} className={` ${checkCardAddress ? 'btnSiring buttonBuy ' : 'buttonBuy btnDo'}`} style={{
                    background:"none"
                }} onClick={checkCardAddress ? harvest : () => attackHero(item.owner)} ref={tilt}>
                    <WithSpin isSpin={isSpin} >
                        {checkCardAddress ? 'Harvest' : 'Attack'}
                    </WithSpin>
                </button>
                <NavLink to={`/hero/${item?.owner}`} className='buttonDetail btnVang' ref={tilt} >Detail</NavLink>
            </div>
            {/* <div className="circle" ref={tilt}></div> */}
            {/* {image} */}
        </div>
        // item.dogeFirstDeposit


    );
}

export default CardHero;