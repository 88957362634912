import React from 'react';
import CardAuction from '../../Component/CardSiringAuction';
import Filters from '../../Component/Filters';
import HistorySiringAll from '../../Component/HistorySiringAll'
import banner from '../../asset/images/banner.jpg'
function SiringAuction(props) {
    return (
        <>
            <div>
                <img src={banner} alt="" width="100%" height="100%" />
            </div>
            <div className='main layerbg pb-3'>
                <Filters siring={true} />
                <CardAuction />
            </div>
            <HistorySiringAll />
        </>
    );
}

export default SiringAuction;