import React from 'react';
import { Pagination } from 'antd';
import './style.scss'
function PaginationCustom({ total, page, limit, funcPagination, color }) {
    // '--cssColor': ''
    let style 
    if(color){
        style =  {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "20px 0px",
            '--cssColor': 'white'
        }
    }else{
        style =  {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "20px 0px",
            '--cssColor': '#525250'
        }
    }
    return (
        <>
            <Pagination style={style} current={page} pageSize={limit} total={total} onChange={(pages, limit) => {
                funcPagination(limit, pages)
            }} />
        </>
    );
}

export default PaginationCustom;