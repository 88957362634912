import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import 'antd/dist/antd.css';
import React, { useState, useEffect } from 'react';
import { decimalAdjust } from '../../../commons';
import { whereListAllSellDrawAction,whereListAllSiringDrawAction } from '../../../Redux/userSlice'
import './style.scss';
import { useSelector, useDispatch } from 'react-redux'
import Health from '../../../asset/photoshop/health.png'
import Attack from '../../../asset/photoshop/attack.png'
import Money from '../../../asset/photoshop/power.png'
import Khien from '../../../asset/photoshop/defiend.png'
import fillter from '../../../asset/photoshop/funnel.png'
import { Divider } from 'antd';
export default function DrawerFilter({sell,siring}) {
    function valuetext(value) {
        return `${value}°C`;
    }
    const { whereListAllSell } = useSelector(state => state.userReducer)
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const [health, setHealth] = useState({
        point: [0, 11160],
        percent: [0, 100],
        min: 0,
        max: 11160
    })
    const [attack, setAttack] = useState({
        point: [0, 11160],
        percent: [0, 100],
        min: 0,
        max: 11160
    })
    const [defense, setDefense] = useState({
        point: [0, 11160],
        percent: [0, 100],
        min: 0,
        max: 11160
    })
    // estimatedEarnings
    const [estimatedEarnings, setEstimatedEarnings] = useState({
        point: [0, 11160],
        percent: [0, 100],
        min: 0,
        max: 11160
    })
    const [active, setActive] = useState(false)
    const dispatch = useDispatch()
    const toggleDrawer = (anchor, open) => (event) => {
        setActive(!active)
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {['Filter', 'Filter', 'Filter', 'Filter'].map((text, index) => (
                    <ListItem button key={text}>
                        {/* <ListItemIcon>
                            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                        </ListItemIcon> */}
                        <ListItemText primary={text} />

                    </ListItem>
                ))}
            </List>
            {/* <Divider />
            <List>
                {['All mail', 'Trash', 'Spam'].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemIcon>
                            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List> */}
        </Box>
    );
    function filter() {
        let defaultValue = `AND attack>=${attack.min} AND attack<=${attack.max} AND defense>=${defense.min} AND defense<=${defense.max} AND health>=${health.min} AND health<=${health.max} AND share>=${estimatedEarnings.min} AND share<=${estimatedEarnings.max}`
        // localStorage.setItem(`valueSreach`,`AND attack>=${attack.min} AND attack<=${attack.max} AND defense>=${defense.min} AND defense<=${defense.max} AND health>=${health.min} AND health<=${health.max} AND share>=${estimatedEarnings.min} AND share<=${estimatedEarnings.max}`)
        if(sell) dispatch(whereListAllSellDrawAction(defaultValue))
        else if(siring) dispatch(whereListAllSiringDrawAction(defaultValue))
    }
    useEffect(() => {
        filter()
    }, [attack, defense, estimatedEarnings, health])
    return (
        <>
            <ListItem className='col-6 fz col-md-6 col-lg-3' sx={{
                flexWrap: 'wrap'
            }}>
                <div className='titleFilter'>
                    <div className="title">
                        <img src={Health} height={35} />
                        <p>
                            Health
                        </p>
                    </div>
                    <p className='reset' onClick={() => {
                        setHealth({
                            point: [0, 11160],
                            percent: [0, 100],
                            min: 0,
                            max: 11160
                        })

                    }}>Reset</p>
                </div>
                <div className="slider">
                    <Slider
                        getAriaLabel={() => 'Minimum distance'}
                        value={health.percent}
                        onChange={(event, newValue, activeThumb) => {
                            const min = decimalAdjust('round', parseInt(health.point[1] * (newValue[0] / 100)), 1)
                            const max = decimalAdjust('round', parseInt(health.point[1] * (newValue[1] / 100)), 1)
                            setHealth({
                                percent: newValue,
                                point: health.point,
                                min,
                                max
                            })

                        }}

                        getAriaValueText={valuetext}
                        disableSwap
                    />
                </div>
                <div className="form mt-2">
                    <TextField id="outlined-basic" sx={{
                        maxWidth: "100px"
                    }}
                        className="fzInput"
                        error={health.errr}
                        label="Min"
                        onChange={((e) => {
                            if (!isNaN(e.target.value)) {
                                const valueMin = e.target.value
                                const min = valueMin
                                const max = health.max
                                const minPercent = valueMin / (health.point[1] / 100)
                                let newValue = [minPercent, health.percent[1]]
                                if (min >= health.point[0] && min <= health.point[1]) {
                                    setHealth({
                                        percent: newValue,
                                        point: health.point,
                                        min,
                                        max,
                                    })
                                }
                            }
                        })}
                        value={health.min}
                        variant="outlined" />
                    <span className='m-0'>-</span>
                    <TextField sx={{
                        maxWidth: "100px"
                    }} id="outlined-basic" label="Max" value={health.max}
                        className="fzInput"
                        onChange={((e) => {
                            if (!isNaN(e.target.value)) {
                                const valueMax = e.target.value
                                const min = health.min
                                const max = valueMax
                                const maxPercent = valueMax / (health.point[1] / 100)
                                let newValue = [health.percent[0], maxPercent]
                                if (max >= health.point[0] && max <= health.point[1]) {
                                    setHealth({
                                        percent: newValue,
                                        point: health.point,
                                        min,
                                        max,
                                    })

                                }
                            }
                        })}
                        variant="outlined" />
                </div>
            </ListItem>
            <ListItem className='col-6 fz col-md-6 col-lg-3' sx={{
                flexWrap: 'wrap'
            }}>
                <div className='titleFilter'>
                    <div className="title">
                        <img src={Attack} height={35} />
                        <p>
                            Attack
                        </p>
                    </div>
                    <p className='reset' onClick={() => {
                        setAttack({
                            point: [0, 11160],
                            percent: [0, 100],
                            min: 0,
                            max: 11160
                        })

                    }}>Reset</p>
                </div>
                <div className="slider">
                    <Slider
                        getAriaLabel={() => 'Minimum distance'}
                        value={attack.percent}
                        onChange={(event, newValue, activeThumb) => {
                            const min = decimalAdjust('round', parseInt(attack.point[1] * (newValue[0] / 100)), 1)
                            const max = decimalAdjust('round', parseInt(attack.point[1] * (newValue[1] / 100)), 1)
                            setAttack({
                                percent: newValue,
                                point: attack.point,
                                min,
                                max
                            })

                        }}

                        getAriaValueText={valuetext}
                        disableSwap
                    />
                </div>
                <div className="form mt-2">
                    <TextField id="outlined-basic" sx={{
                        maxWidth: "100px"
                    }}
                        error={attack.errr}
                        label="Min"
                        className="fzInput"
                        onChange={((e) => {
                            if (!isNaN(e.target.value)) {
                                const valueMin = e.target.value
                                const min = valueMin
                                const max = attack.max
                                const minPercent = valueMin / (attack.point[1] / 100)
                                let newValue = [minPercent, attack.percent[1]]
                                if (min >= attack.point[0] && min <= attack.point[1]) {
                                    setAttack({
                                        percent: newValue,
                                        point: attack.point,
                                        min,
                                        max,
                                    })

                                }
                            }
                        })}
                        value={attack.min}
                        variant="outlined" />
                    <span className='m-0'>-</span>
                    <TextField sx={{
                        maxWidth: "100px"
                    }}
                    className="fzInput"

                        onChange={((e) => {
                            if (!isNaN(e.target.value)) {
                                const valueMax = e.target.value
                                const min = attack.min
                                const max = valueMax
                                const maxPercent = valueMax / (attack.point[1] / 100)
                                let newValue = [attack.percent[0], maxPercent]
                                if (max >= attack.point[0] && max <= attack.point[1]) {
                                    setAttack({
                                        percent: newValue,
                                        point: attack.point,
                                        min,
                                        max,
                                    })

                                }
                            }
                        })}
                        id="outlined-basic" label="Max" value={attack.max} variant="outlined" />
                </div>
            </ListItem>
            <ListItem className='col-6 fz col-md-6 col-lg-3' sx={{
                flexWrap: 'wrap'
            }}>
                <div className='titleFilter'>
                    <div className="title">
                        <img src={Khien} height={35} />
                        <p>
                            Defense
                        </p>
                    </div>
                    <p className='reset' onClick={() => {
                        setDefense({
                            point: [0, 11160],
                            percent: [0, 100],
                            min: 0,
                            max: 11160
                        })
                    }}>Reset</p>
                </div>
                <div className="slider">
                    <Slider
                        getAriaLabel={() => 'Minimum distance'}
                        value={defense.percent}
                        onChange={(event, newValue, activeThumb) => {
                            const min = decimalAdjust('round', parseInt(defense.point[1] * (newValue[0] / 100)), 1)
                            const max = decimalAdjust('round', parseInt(defense.point[1] * (newValue[1] / 100)), 1)
                            setDefense({
                                percent: newValue,
                                point: defense.point,
                                min,
                                max
                            })

                        }}

                        getAriaValueText={valuetext}
                        disableSwap
                    />
                </div>
                <div className="form mt-2">
                    <TextField id="outlined-basic" sx={{
                        maxWidth: "100px"
                    }}
                    className="fzInput"

                        error={defense.errr}
                        label="Min"
                        onChange={((e) => {
                            if (!isNaN(e.target.value)) {
                                const valueMin = e.target.value
                                const min = valueMin
                                const max = defense.max
                                const minPercent = valueMin / (defense.point[1] / 100)
                                let newValue = [minPercent, defense.percent[1]]
                                if (min >= defense.point[0] && min <= defense.point[1]) {
                                    setDefense({
                                        percent: newValue,
                                        point: defense.point,
                                        min,
                                        max,
                                    })

                                }
                            }
                        })}
                        value={defense.min}
                        variant="outlined" />
                    <span className='m-0'>-</span>
                    <TextField sx={{
                        maxWidth: "100px"
                    }}
                    className="fzInput"

                        onChange={((e) => {
                            if (!isNaN(e.target.value)) {
                                const valueMax = e.target.value
                                const min = defense.min
                                const max = valueMax
                                const maxPercent = valueMax / (defense.point[1] / 100)
                                let newValue = [defense.percent[0], maxPercent]
                                if (max >= defense.point[0] && max <= defense.point[1]) {
                                    setDefense({
                                        percent: newValue,
                                        point: defense.point,
                                        min,
                                        max,
                                    })

                                }
                            }
                        })}
                        id="outlined-basic" label="Max" value={defense.max} variant="outlined" />
                </div>
            </ListItem>
            <ListItem className='col-6 fz col-md-6 col-lg-3' sx={{
                flexWrap: 'wrap'
            }}>
                <div className='titleFilter'>
                    <div className="title">
                        <img src={Money} height={35} />
                        <p >
                            Earnings
                        </p>
                    </div>
                    <p className='reset' onClick={() => {
                        setEstimatedEarnings({
                            point: [0, 11160],
                            percent: [0, 100],
                            min: 0,
                            max: 11160
                        })

                    }}>Reset</p>
                </div>
                <div className="slider">
                    <Slider
                        getAriaLabel={() => 'Minimum distance'}
                        value={estimatedEarnings.percent}
                        onChange={(event, newValue, activeThumb) => {
                            const min = decimalAdjust('round', parseInt(estimatedEarnings.point[1] * (newValue[0] / 100)), 1)
                            const max = decimalAdjust('round', parseInt(estimatedEarnings.point[1] * (newValue[1] / 100)), 1)
                            setEstimatedEarnings({
                                percent: newValue,
                                point: estimatedEarnings.point,
                                min,
                                max
                            })

                        }}

                        getAriaValueText={valuetext}
                        disableSwap
                    />
                </div>
                <div className="form mt-2">
                    <TextField id="outlined-basic" sx={{
                        maxWidth: "100px"
                    }}
                    className="fzInput"

                        error={estimatedEarnings.errr}
                        label="Min"
                        onChange={((e) => {
                            if (!isNaN(e.target.value)) {
                                const valueMin = e.target.value
                                const min = valueMin
                                const max = estimatedEarnings.max
                                const minPercent = valueMin / (estimatedEarnings.point[1] / 100)
                                let newValue = [minPercent, estimatedEarnings.percent[1]]
                                if (min >= estimatedEarnings.point[0] && min <= estimatedEarnings.point[1]) {
                                    setEstimatedEarnings({
                                        percent: newValue,
                                        point: estimatedEarnings.point,
                                        min,
                                        max,
                                    })

                                }
                            }
                        })}
                        value={estimatedEarnings.min}
                        variant="outlined" />
                    <span className='m-0'>-</span>
                    <TextField sx={{
                        maxWidth: "100px"
                    }}
                    className="fzInput"

                        onChange={((e) => {
                            if (!isNaN(e.target.value)) {
                                const valueMax = e.target.value
                                const min = estimatedEarnings.min
                                const max = valueMax
                                const maxPercent = valueMax / (estimatedEarnings.point[1] / 100)
                                let newValue = [estimatedEarnings.percent[0], maxPercent]
                                if (max >= estimatedEarnings.point[0] && max <= estimatedEarnings.point[1]) {
                                    setEstimatedEarnings({
                                        percent: newValue,
                                        point: estimatedEarnings.point,
                                        min,
                                        max,
                                    })

                                }
                            }
                        })}
                        id="outlined-basic" label="Max" value={estimatedEarnings.max} variant="outlined" />
                </div>
            </ListItem>

        </>
    );
}
