import React from 'react';
import { Navigate, useLocation, Outlet } from "react-router-dom"
import { useSelector } from 'react-redux'
import Header from '../../layout/MainLayout/Header';
import Footer from '../../layout/MainLayout/Footer';
import  './style.scss'
function MainTemplate({ children }) {
    // const { login } = useSelector(state => state.userReducer)
    // background: "#cfd1e1",
    return (
        <>
            <div style={{  minHeight:"100vh", width :"100%" , overflow:"hidden"}}>
                <Header />
                <div className='' style={{
                    paddingTop:"66px"
                }} >
                    <Outlet />
                </div>
                <Footer />
            </div>
        </>
    )
}

export default MainTemplate;