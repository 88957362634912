import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { funcWallet } from '../../commons/blockchain';
import { toastErrorMessage } from '../../commons/message';
import WithLoading from '../../HOC/WithLoading';
import WithSpin from '../../HOC/WithSpin';
import { getMyDogesAction } from '../../Redux/action/api/dogeReducer';
import { contractServices } from '../../Services/contract';
import CardMyDoge from '../Card/CardMyDoge';
import HistoryBuySellAddress from '../HistoryBuySellAddress';
import PaginationCustom from '../PaginationCustom';
import { Empty } from 'antd';
import socket from '../../Socket';
import HistorySiringAddress from '../HistorySiringAddress';
function MyDogeParent({ handleClickOpen, handleClickOpenBreed, onShow }) {
    const [isLoading, setIsLoading] = useState(true)
    const { myDoges } = useSelector(state => state.dogesApiReducer)
    const { approvedDeposit, approvedSiring, loadingFunc, profile } = useSelector(state => state.userReducer)
    const [isSpin, setIsSpin] = useState(false)
    const [limit, setLimit] = useState(12)
    const [page, setPage] = useState(1)
    const dispatch = useDispatch()
    const LoadingMyDoge = WithLoading(myDogesComponent)
    const [checkBox, setCheckBox] = useState({
        flag: false,
        doge: [],
    })
    function selectDogeDeposit() {
        setCheckBox({
            flag: true,
            doge: []
        })
    }
    function selectDoge(dogeItem) {
        let flag = false
        for (let doge of checkBox.doge) {
            if (doge.dogeId == dogeItem.dogeId) flag = true
        }
        if (!flag) checkBox.doge.push(dogeItem)
        else checkBox.doge = checkBox.doge.filter(item => item.dogeId !== dogeItem.dogeId)
        setCheckBox({
            doge: [...checkBox.doge],
            flag: true
        })
    }
    function cancelDogeDeposit() {
        setCheckBox({
            flag: false,
            doge: []
        })
    }
    async function deposit() {
        try {
            setIsSpin(true)
            // checkBox
            const dogeTokenList = []
            for (let doge of checkBox.doge) {
                dogeTokenList.push(doge.dogeId)
            }
            if (dogeTokenList.length <= 0) {
                setIsSpin(false)
                return toastErrorMessage(`You haven't selected any doge yet`)
            }
            const contract = await contractServices()
            //kiểm tra doges có đang mang thai hay không, nếu có thì transaction sẽ fail
            for (let i = 0; i < dogeTokenList.length; i++) {
                const pregnant = await contract.isPregnant(dogeTokenList[i])
                // console.log('pregnant:', pregnant)
                if (pregnant) {
                    console.log(`doge id = ${dogeTokenList[i]} dang mang thai, ko the deposit`)
                    toastErrorMessage(`Pregnant Doge ${dogeTokenList[i]} can't deposit`)
                    setIsSpin(false)
                    return
                }
            }

            //kiểm tra xem đã approve contract chưa
            const owner = contract.currentUserAddress
            const approved = await contract.checkApproveToDepositDoge(owner)
            //nếu chưa approve thì bắt đầu approve
            if (!approved) {
                await contract.approveToDepositDoge()
            }
            await contract.depositDoges(dogeTokenList)
            await dispatch(getMyDogesAction({ limit: 12, page: 1 }))
            setIsSpin(false)
            setCheckBox({
                flag: false,
                doge: []
            })
        } catch (error) {
            setIsSpin(false)
        }
    }
    function myDogesComponent() {
        if (myDoges?.array?.length <= 0) return <Empty />
        return (
            <>
                {myDoges?.array?.map((item, index) => {
                    return <CardMyDoge selectDoge={selectDoge} item={item} checkBox={checkBox} index={index} bread={true} sell={true} handleClickOpenBreed={handleClickOpenBreed} limit={limit} page={page} handleClickOpen={handleClickOpen} onShow={onShow} />
                })}
            </>
        )
    }
    async function approveDeposit() {
        try {
            setIsSpin(true)
            const contract = await contractServices()
            await contract.approveToDepositDoge()
            await funcWallet()
            setIsSpin(false)
            window.location.reload()
        } catch (error) {
            setIsSpin(false)
        }

    }
    function funcPagination(limit, page) {
        setLimit(limit)
        setPage(page)
    }
    useEffect(async () => {
  
        document.body.scrollTop = 0;
        window.scrollTo(0, 0);
        setIsLoading(true)
        await dispatch(getMyDogesAction({ limit, page }))
        setIsLoading(false)
    }, [limit, page,profile])
    return (

        <>
            {!checkBox.flag ?
                approvedDeposit ?
                    <button className='btnOutLine ml-2' disabled={loadingFunc} onClick={selectDogeDeposit}>
                        <WithSpin isSpin={loadingFunc}>
                            Select Doge Deposit
                        </WithSpin>
                    </button> :
                    <button className='btnOutLine ml-2' disabled={loadingFunc || isSpin} onClick={approveDeposit}>
                        <WithSpin isSpin={loadingFunc || isSpin}>
                            Approve Deposit
                        </WithSpin>
                    </button>
                :
                <>
                    <button className='btnOutLine ml-2' onClick={deposit}>
                        <WithSpin isSpin={isSpin}>
                            Deposit
                        </WithSpin>
                    </button>
                    <button className='btnOutLine ml-2' onClick={cancelDogeDeposit}>Cancel Deposit</button>
                </>
            }
            <div className='containerCard'>
                <LoadingMyDoge isLoading={isLoading} myDoges={myDoges} />
            </div>
            <PaginationCustom color="#fff" limit={limit} page={page} total={myDoges?.total} funcPagination={funcPagination} />
            
        </>
    );
}

export default MyDogeParent;