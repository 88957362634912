import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import hidenDoge from '../../../../asset/photoshop/hidenDoge.png'
import btnSiring250 from '../../../../asset/photoshop/btnSiring250.png'
import siring from '../../../../asset/photoshop/siring.png'
// import 
import { CardHeader, InputLabel, MenuItem, Select, Skeleton, TextField } from '@mui/material';
import Contract from '../../../../contract';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { toastErrorMessage, toastSuccessMessage } from '../../../../commons/message';
import { bigNumber, truncate } from '../../../../commons/blockchain';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSellingDoges, getAllSiringDoges } from '../../../../Redux/action/contract/dogeReducer';
import { getMyDogesAction } from '../../../../Redux/action/api/dogeReducer';
import Wod from '../../../../asset/images/dogepts.png'
import WodToken from '../../../../asset/images/wod.png'
import Ruby from '../../../../asset/images/ruby.png'
import './style.scss'
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import SelectMyDoge from '../../../SelectMyDoge';
import BN from 'bn.js'
import WithSpin from '../../../../HOC/WithSpin';
import { port } from '../../../../port';
import { contractServices } from '../../../../Services/contract';
import { smallNumber, toWei } from '../../../../contractAccount';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PopupSiring({ open, handleClickOpen, handleClose, doge, price, myBread, popupSiring }) {
    const [age, setAge] = React.useState(0);
    const [rubyBalance, setRubyBalance] = useState(0)
    const [wodBalance, setWodBalance] = useState(0)
    const [isSpin, setIsSpin] = useState(false)
    const { priceCoin } = useSelector(state => state.userReducer)
    const [isFee, setIsFee] = useState(false)
    const [openModel, setOpenModel] = React.useState(false);
    const handleOpenModel = () => setOpenModel(true);
    const handleCloseModel = () => setOpenModel(false);
    const [dogeSelect, setDogeSelect] = useState({})
    const [getAutoBirthFee, setGetAutoBirthFee] = useState(0)
    const [feeSiring, setFeeSiring] = useState({
        sireRubyFee: 0,
        sireWODFee: 0,
        matronRubyFee: 0,
        matronWODFee: 0,
        totalRubyFee: 0,
        totalWODFee: 0,
        totalPrice: 0
    })
    // const [price, setPrice] = useState(0)
    const dispatch = useDispatch()
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const closeModel = () => {
        setDogeSelect({})
        setFeeSiring({
            sireRubyFee: 0,
            sireWODFee: 0,
            matronRubyFee: 0,
            matronWODFee: 0,
            totalRubyFee: 0,
            totalWODFee: 0,
            totalPrice: 0
        })
        handleClose()
    }
    const handleSelect = async (dogeSelect) => {
        try {
            setDogeSelect(dogeSelect)
            setOpenModel(false)
            setIsFee(true)
            const contract = await contractServices()
            const sireInfo = await contract.getDoge(doge.dogeId)

            const sireCooldownIndex = sireInfo[2]
            const sireRubyFee = await contract.breedingRubyFee(sireCooldownIndex)
            const sireWODFee = await contract.breedingWODFee(sireCooldownIndex)

            const matronInfo = await contract.getDoge(dogeSelect.dogeId)
            const matronCooldownIndex = matronInfo[2]
            const matronRubyFee = await contract.breedingRubyFee(matronCooldownIndex)
            const matronWODFee = await contract.breedingWODFee(matronCooldownIndex)

            const totalRubyFee = parseFloat(sireRubyFee) + parseFloat(matronRubyFee)
            const totalWODFee = parseFloat(sireWODFee) + parseFloat(matronWODFee)

            const autoBirthFee = await contract.autoBirthFee()

            let totalPrice

            if (myBread) totalPrice = autoBirthFee
            else totalPrice = toWei(price.toString()) 

            setIsFee(false)
            setFeeSiring({ sireRubyFee, matronRubyFee, sireWODFee, matronWODFee, totalRubyFee, totalWODFee, totalPrice: totalPrice })
        } catch (error) {
            console.log(error, "errrorr")
        }

    }

    const myBreadFunc = async () => {
        try {
            setIsSpin(true)
            const contract = await contractServices()
            const matronId = doge.dogeId
            const sireId = dogeSelect.dogeId
            const matronReady = await contract.isReadyToBreed(matronId)
            const sireReady = await contract.isReadyToBreed(sireId)
            const canBreed = await contract.canBreedWith(matronId, sireId)

            if (!matronReady && !sireReady && !canBreed) {
                toastErrorMessage(`Your Doge is not ready for mating yet!`)
                setIsSpin(false)
            }
            //kiểm tra các yếu tố khác tương tự hàm buySiringAuction
            const autoBirthFee = await contract.autoBirthFee()
            await contract.breedWithAuto(matronId, sireId, autoBirthFee)
            await dispatch(getMyDogesAction({ limit: 12, page: 1 }))
            setIsSpin(false)
            toastSuccessMessage(`Successful mating`)
            handleClose()
            window.location.reload()
        } catch (error) {
            toastErrorMessage(`Transaction Error!`)
            setIsSpin(false)
        }
    }
    async function getPrice(doge) {
        let price = 0
        if (doge?.dogeId) {

        }
        return price
    }
    async function Siring() {
        try {
            setIsSpin(true)
            const dogeId = doge.dogeId
            const contract = await contractServices()

            const rubyBalance = await contract.getRubyBalance(contract.currentUserAddress)
            const wodBalance = await contract.getWODBalance(contract.currentUserAddress)
            if (rubyBalance <= feeSiring.totalRubyFee && wodBalance <= feeSiring.totalWODFee) {
                setIsSpin(false)
                return toastErrorMessage(`Your balance is not enough`)
            }
            await contract.buySiringAuction(doge.dogeId, dogeSelect.dogeId, feeSiring.totalPrice)
            setIsSpin(false)
            // dispatch(getAllSiringDoges())
            handleClose()
            toastSuccessMessage(`Siring a successful doge`)
            window.location.reload()

        } catch (error) {
            console.log(error);
            toastErrorMessage(`Transaction failed`)
            setIsSpin(false)
        }
    }
    useEffect(async () => {
        try {
            const contract = await contractServices()
            const ruby = await contract.getRubyBalance(contract.currentUserAddress)
            const wod = await contract.getWODBalance(contract.currentUserAddress)
            setRubyBalance(ruby / 1e18)
            setWodBalance(wod / 1e18)
            if (doge.dogeId && dogeSelect.dogeId && myBread) {
                // setIsFee(true)
                // const contract = await contractServices()
                // const autoBirthFee = await contract.autoBirthFee()
                // console.log(autoBirthFee, "autoBirthFeeautoBirthFee");
                // setGetAutoBirthFee(autoBirthFee / 1e18)
                // setIsFee(false)

            }
        } catch (error) {
            console.log(error);
            setIsFee(false)

        }

    }, [dogeSelect.dogeId])

    return (

        <Dialog
            open={open.flag}
            TransitionComponent={Transition}
            keepMounted
            className="minWidth siring"
            onClose={closeModel}
            aria-describedby="alert-dialog-slide-description"

        >
            <DialogTitle sx={{
                textAlign: "center"
            }}>{``}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" sx={{
                    textAlign: "center"
                }}>
                    <p>Do you want to hire this dog for breeding?</p>
                    {/* <InputLabel id="demo-simple-select-label">Starting Price (BNB)</InputLabel> */}

                    <div className='' style={{
                        display: "flex",
                        justifyContent: "space-around",
                        borderRadius: "5px",
                        background: `${doge?.background}`,
                        maxWidth: "600px",
                        margin: "auto"
                    }}>
                        <div>
                            <b className='m-0 mt-2' style={{
                                display: "block"
                            }}>#{doge?.dogeId}</b>
                            <img src={`${port}${doge?.image}`} className='imgSiring' alt="" />
                        </div>
                        <div className='' style={{
                            margin: "auto 0px"
                        }}>
                            <img src={siring} className="imgBread" alt="" />
                        </div>
                        {dogeSelect.dogeId ?
                            <>
                                <div className=''>
                                    <b className='m-0 mt-2' style={{
                                        display: "block"
                                    }}>#{dogeSelect.dogeId}</b>
                                    {/* <img src={`${port}${dogeSelect?.image}`} className='imgBuy' alt="" /> */}
                                    <img src={`${port}${dogeSelect?.image}`} className='imgSiring' style={{
                                    }} alt="" />
                                    <div className='mb-2' style={{
                                        cursor: "pointer",
                                        display: "block",
                                        border: "1px solit black",
                                        padding: "5px",
                                        borderRadius: "5px",
                                        background: "#6dcff6",
                                        width: "90%",
                                        marginBottom: "10px !important"
                                        // maxWidth: "200px"
                                    }} disabled={isSpin} onClick={handleOpenModel}>{window.innerWidth < 750 ? "Reselect" : "Reselect My Doge"}</div>
                                </div>
                            </>
                            // reposiveTotal

                            : <>
                                <div className='dogeSelectMobile' style={{
                                    // minWidth: "200px"
                                }}>
                                    <p className='m-0'>&nbsp;</p>
                                    <img src={hidenDoge} className='imgSiring' style={{
                                        maxWidth: "165px"
                                    }} alt="" />
                                    <div style={{
                                        maxWidth: "165px",
                                        display: "block",
                                        margin: "auto",
                                        cursor: "pointer",
                                        border: "1px solit black",
                                        padding: "5px",
                                        borderRadius: "5px",
                                        background: "#6dcff6",
                                        width: "90%",
                                        marginBottom: "10px"
                                    }} disabled={isSpin} onClick={handleOpenModel}>{window.innerWidth < 750 ? "Select" : "Select My Doge"}</div>
                                </div>
                            </>}
                    </div>
                    <div className='formFee'>
                        <div className='feeRuby'>
                            Required Ruby &nbsp;
                        </div>
                        <img src={Ruby} width={40} />
                        <div className='blanceFee'>

                            <p className=''>
                                <WithSpin color={"#00000099"} isSpin={isFee}>
                                    {truncate(feeSiring.totalRubyFee / 1e18, 6)}
                                </WithSpin></p>
                            /

                            <span className={`${rubyBalance < feeSiring.totalRubyFee / 1e18 ? `text-danger` : `text-success`}`}>
                                &nbsp;{truncate(rubyBalance, 2)}
                            </span>
                        </div>
                    </div>
                    <div className='formFee'>
                        <div className='feeRuby' style={{
                            color: "#353637"
                        }}>
                            Breeding fee &nbsp;
                        </div>
                        <img src={WodToken} width={40} />
                        <div className='blanceFee'>
                            <p className=''>
                                <WithSpin color={"#00000099"} isSpin={isFee}>
                                    {truncate(feeSiring.totalWODFee / 1e18, 6)}
                                </WithSpin></p>
                            /
                            <span className={`${wodBalance < feeSiring.totalWODFee / 1e18 ? `text-danger` : `text-success`}`}>
                                &nbsp;{truncate(wodBalance, 2)}
                            </span>
                        </div>
                    </div>

                    {myBread ? <></> :

                        <div className="text-center breedingFee" style={{
                            margin: "0px"
                        }}>
                            {/* <b>Total:
                                <WithSpin color={"#00000099"} isSpin={isFee}>
                                    {truncate(feeSiring.totalPrice / 1e18, 6)} BNB
                                    <span className='USD'> ($ {truncate(priceCoin.BNB * feeSiring.totalPrice / 1e18, 2)})</span>
                                </WithSpin>
                            </b> */}

                        </div>
                    }
                    <button className='m-auto siringBtn' disabled={!dogeSelect.dogeId || isSpin || rubyBalance < feeSiring.totalRubyFee / 1e18 || wodBalance < feeSiring.totalWODFee / 1e18} onClick={!myBread ? Siring : myBreadFunc}>
                        <WithSpin isSpin={isSpin} >
                            {!myBread ? "Siring" : "Bread"}
                        </WithSpin>
                    </button>
                    {/* <div className='total'>
                        <p>Fee RUBY Doge Siring</p>
                        <p>{truncate(feeSiring.sireRubyFee / 1e18, 6)} RUBY </p>
                    </div>
                    <div className='total'>
                        <p>Fee WOD Doge Siring</p>
                        <p>{truncate(feeSiring.sireWodFee / 1e18, 6)} WOD </p>
                    </div>
                    <div className='total'>
                        <p>Fee RUBY My Doge Siring</p>
                        <p>{truncate(feeSiring.matronRubyFee / 1e18, 6)} RUBY </p>
                    </div>
                    <div className='total'>
                        <p>Fee WOD My Doge Siring</p>
                        <p>{truncate(feeSiring.matronWodFee / 1e18, 6)} WOD </p>
                    </div> */}
                    <>
                        {/* <div className='total'>
                            <p>Fee RUBY Siring</p>
                            <p>
                                <WithSpin color={"#00000099"} isSpin={isFee}>
                                    {truncate(feeSiring.totalRubyFee / 1e18, 6)} <img src={Ruby} width={30} />
                                </WithSpin>
                            </p>
                        </div>
                        <div className='total'>
                            <p>Fee WOD Siring</p>
                            <p>
                                <WithSpin color={"#00000099"} isSpin={isFee}>
                                    {truncate(feeSiring.totalWODFee / 1e18, 6)} WOD
                                </WithSpin>
                            </p>
                        </div> */}


                    </>
                    {/* { ?
                        <div className='total'>
                            <p>Fee</p>
                            <p>
                                <WithSpin color={"#00000099"} isSpin={isFee}>
                                    {truncate(getAutoBirthFee, 6)} BNB
                                </WithSpin>
                            </p>
                        </div>
                        :
                    
                    } */}

                </DialogContentText>
            </DialogContent>
            <DialogActions>

                {dogeSelect.dogeId ? <>
                    {/* <button className='btn btn-info w-100' style={{
                        maxWidth: "200px"
                    }} disabled={isSpin} onClick={handleOpenModel}>Reselect My Doge</button> */}
                </> : <>
                </>}

                <Modal
                    open={openModel}
                    onClose={handleCloseModel}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <SelectMyDoge dogeBread={doge} handleSelect={handleSelect} popupSiring={popupSiring} breadMyDoge={myBread} />
                    {/* <Box sx={style}>
                        <div className='containerCard'>
                            <LoadingMyDoge isLoading={isLoading} myDoges={myDoges} />
                        </div>
                    </Box> */}
                </Modal>
                {/* <Button onClick={handleClose}>Cancel</Button> */}
            </DialogActions>
        </Dialog>
    );
}
