import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { InputNumber } from 'antd';
import React, { useEffect, useState } from 'react';
import Sreach from '../Sreach';
import DrawerFilter from './DrawerFilter';
import { useDispatch, useSelector } from 'react-redux'
import { whereListAllSellAction, whereListAllSiringAction } from '../../Redux/userSlice'
import './style.scss';
import fillter from '../../asset/photoshop/funnel.png'

import { truncate } from '../../commons/blockchain';
function Filters({ marketPlace, siring }) {

    const [age, setAge] = useState(0);
    // localStorage.getItem('valueSortSell')||
    const [valueSort, setValueSort] = useState(marketPlace ? `priceSell` : `priceSiring`)
    // localStorage.getItem('valueOderBySell')||
    const [valueOderBy, setValueOderBy] = useState('ASC')
    // localStorage.getItem('sellGen')||
    const [active, setActive] = useState(false)
    const [gen, setGen] = useState(0)
    const dispatch = useDispatch()
    const { listMartketPlace, whereListAllSell, whereListAllSiring, listSiring } = useSelector(state => state.userReducer)
    const handleChange = (event) => {
        let value = event.target.value
        if (marketPlace) {
            let where = whereListAllSell.filter.replace(` ${valueOderBy}`, ``)
            let str = where + ` ${value}`
            const option = `${whereListAllSell.drawFilter.length > 0 ? whereListAllSell.drawFilter : ``} ${whereListAllSell.filter} `
            const obj = {
                drawFilter: whereListAllSell.drawFilter,
                filter: whereListAllSell.filter,
                option: option,
            }
            localStorage.setItem('valueOderBySell', value)
            localStorage.setItem('optionFilter', JSON.stringify(obj))

            dispatch(whereListAllSellAction(str))
        } else if (siring) {

            let where = whereListAllSiring.filter.replace(` ${valueOderBy}`, ``)
            let str = where + ` ${value}`
            dispatch(whereListAllSiringAction(str))
        }
        setValueOderBy(value)

    };
    const toggleDrawer = (event) => {
        setActive(!active)
    }
    const handleChangeOderBy = (event) => {

        let value = event.target.value
        if (marketPlace) {

            let str = `ORDER BY ${value} ${valueOderBy} `
            const option = `${whereListAllSell.drawFilter.length > 0 ? whereListAllSell.drawFilter : ``} ${whereListAllSell.filter} `
            const obj = {
                drawFilter: whereListAllSell.drawFilter,
                filter: whereListAllSell.filter,
                option: option
            }

            localStorage.setItem('valueSortSell', value)
            localStorage.setItem('optionFilter', JSON.stringify(obj))

            dispatch(whereListAllSellAction(str))
        } else if (siring) {
            let str = `ORDER BY ${value} ${valueOderBy} `
            dispatch(whereListAllSiringAction(str))
        }
        setValueSort(value)

    };
    function onChangeInputNumber(value) {
        let str = ` AND generation=${value} ORDER BY ${valueSort} ${valueOderBy}`

        if (marketPlace) {
            const option = `${whereListAllSell.drawFilter.length > 0 ? whereListAllSell.drawFilter : ``} ${whereListAllSell.filter} `
            const obj = {
                drawFilter: whereListAllSell.drawFilter,
                filter: whereListAllSell.filter,
                option: option
            }
            localStorage.setItem('sellGen', value)
            localStorage.setItem('optionFilter', JSON.stringify(obj))
            dispatch(whereListAllSellAction(str))
        } else if (siring) {
            dispatch(whereListAllSiringAction(str))
        }
        setGen(value)

    }
    const elementTotal = () => {
        if (marketPlace) return truncate(listMartketPlace?.total, 0)
        else if (siring) return truncate(listSiring?.total, 0)

    }
    useEffect(() => {
        let str = `ORDER BY ${valueSort} ${valueOderBy}`
        if(marketPlace) dispatch(whereListAllSellAction(str))
        else if(siring) dispatch(whereListAllSiringAction(str))
        
        // marketPlace ? `priceSell` : `priceSiring`
    }, [])
    return (
        <>
            <div className="Toolbar-RowContainer Toolbar-RowContainer--second-row " style={{
                margin: "auto",
                justifyContent:"space-between"
            }}>
                <div className="">
                    <button type="button" onClick={toggleDrawer} className={`Toolbar-menu-button btn-filter ${active ? "activeFillter" : ""}`}>
                        <img src={fillter} width="30" hegiht="30" />
                        Filters
                    </button>
                </div>
                <div className='box-gens  my-3 '>
                    <span className="Toolbar-generation--label gen fzs " style={{border:"none"}}>Gen &nbsp;</span>
                    {/* <InputLabel id="demo-simple-select-label">All</InputLabel> */}
                    <InputNumber min={0} defaultValue={""} value={gen} placeholder="All" onChange={onChangeInputNumber} />
                </div>
                <Box sx={{ minWidth: 120 }} className='select oderPrice'>
                    <FormControl fullWidth>
                        {/* <InputLabel id="demo-simple-select-label ">Sort by</InputLabel> */}
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={valueSort}
                            className="fzs"
                            label="Sort by"
                            onChange={handleChangeOderBy}
                        >

                            <MenuItem className="fz" value={`birthTime`}>Birth Day</MenuItem>
                            <MenuItem className="fz" value={marketPlace ? `priceSell` : `priceSiring`}>Price</MenuItem>
                            <MenuItem className="fz" value={`generation`}>Gen</MenuItem>
                            <MenuItem className="fz" value={`cooldownIndex`}>Cooldown</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ minWidth: 120 }} className='select'>
                    <FormControl fullWidth>
                        {/* <InputLabel id="demo-simple-select-label">Order by</InputLabel> */}
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={valueOderBy}
                            label="Age"
                            className="fzs"
                            onChange={handleChange}
                        >
                            <MenuItem className="fz" value={`ASC`}>Low to high</MenuItem>
                            <MenuItem className="fz" value={`DESC`}>High to low</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <div className=' text-right totalFilter' style={{
                    paddingRight: "90px"
                }}>
                    <span className="Toolbar-generation--label  col-lg-2 col-12 my-3 fz" style={{ color: "#ffffff" }}> Total:{elementTotal()}</span>
                </div>

                {/* <div class="Toolbar-generation">
                <input class="Toolbar-generation-input Number" type="number" min="0" step="1" placeholder="All" value="" />
                <div class="Toolbar-generation--reset"><span class="Toolbar-generation--reset-button Toolbar-generation--reset-button--isDisabled" role="button">
                    Reset
                </span>
                </div>
            </div> */}
                {/* <Sreach /> */}

                {/* <div class="SearchBar">
                <div class="SearchBar-container">
                    <div class="SearchBar-inputContainer">
                        <div class="SearchBar-icon">
                            <svg class="IconV2 IconV2--position-default IconV2--display-inlineBlock" width="16" height="16" viewBox="0 0 16 16">
                                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="icon/search" class="IconSearch" stroke="#C4C3C0" stroke-width="1.5">
                                        <g id="icon-search-copy">
                                            <path d="M10.5709785,10.5709785 L10.571001,10.570956 C12.8096551,8.33249161 12.8096551,4.6669393 10.5708661,2.42890911 C8.3332504,0.190344899 4.66769809,0.190344899 2.42890911,2.42913385 C0.190344899,4.6667496 0.190344899,8.33230191 2.42913385,10.5710909 C4.6667496,12.8096551 8.33230191,12.8096551 10.5709785,10.5709785 Z" id="Fill-1"></path>
                                            <path d="M10.9111111,10.9111111 L13.7555556,13.7555556" id="Line" stroke-linecap="round"></path>/&gt;
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <input type="text" class="SearchBar-input" placeholder="Search" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" value="" />
                    </div>
                </div>
            </div> */}
            </div>
            {!active ?
                <>
                </> :
                <>
                    <div className="Toolbar-RowContainer Toolbar-RowContainer--second-row mt-3 m-auto row drawFilter" >
                        <DrawerFilter siring={siring} sell={marketPlace}/>
                    </div>
                </>
            }
        </>
    );
}

export default Filters;