import React from 'react';
import './style.scss'
function SpinLoading({center,color}) {
    return (
        <>
            <div className={`loading-dots${center ? "m-auto" : ""}`} style={color?{ '--color': color }:{ '--color': '#fff' }}>
                        <div className="bounce"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
          
        </>
    );
}

export default SpinLoading;