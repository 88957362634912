import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDogeDetail } from '../../Redux/action/api/dogeReducer';
import Wod from '../../asset/images/dogepts.png'
import iconBirhDay from '../../asset/photoshop/birthday.png'
import iconSword from '../../asset/photoshop/sword.png'
import iconGen from '../../asset/photoshop/gene.png'
import Health from '../../asset/photoshop/health.png'
import Attack from '../../asset/photoshop/attack.png'
import readyTime from '../../asset/photoshop/readyTime.png'
import Left from '../../asset/photoshop/left.png'
import Right from '../../asset/photoshop/right.png'
import Money from '../../asset/photoshop/power.png'
import Khien from '../../asset/photoshop/defiend.png'
import { funcWallet, strWallet, truncate } from '../../commons/blockchain';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import './style.scss'
import { toast } from 'react-toastify';
import { Col, Row } from 'antd';
import CardParent from '../../Component/CardParent';
import { port } from '../../port';
import PopupBuy from '../../Component/Card/CardItem/PopupBuy';
import PopupSiring from '../../Component/CardSiringAuction/CardItem/PopupSiring';
import { createApiBody } from '../../Services';
import LoadingDetail from '../../Component/LoadingDetail';
import { convertTimeToBirthDay } from '../../commons';
import { Statistic } from 'antd';
import WithSpin from '../../HOC/WithSpin';
import { contractServices } from '../../Services/contract';
import { toastErrorMessage, toastSuccessMessage } from '../../commons/message';
import { getMyDogesAction } from '../../Redux/action/contract/dogeReducer';
import PopupSell from '../../Component/Card/CardMyDoge/PopupSell';
import PopupTransfer from '../../Component/Card/CardMyDoge/PopupTransfer';
import logoBNB from '../../asset/images/bnb.png'
const { Countdown } = Statistic;
const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title}:</p>
        {content}
    </div>
);
function DetailDoge({ }) {
    const { dogeId } = useParams()
    const { dogeDetail, heroDetail } = useSelector(state => state.dogesApiReducer)
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const wallet = JSON.parse(localStorage.getItem('wallet'))
    const { priceCoin, loadingFunc, approvedSell, approvedSiring, profile } = useSelector(state => state.userReducer)
    const [copy, setCopy] = useState(false)
    const [isSpinCancel, setIsSpinCancel] = useState(false)
    const [isSpinDoge, setIsSpinDoge] = useState(false)
    const [dogeChildren, setDogeChildren] = useState([])
    const [limitChildren, setLimitChidren] = useState(0)
    const [myBread, setMyBread] = useState(true)
    const inputArea = React.useRef(null);
    const cooldowns = [
        '15 minutes',
        '30 minutes',
        '1 hours',
        '2 hours',
        '4 hours',
        '8 hours',
        '16 hours',
        '1 day',
        '2 days',
        '3 days',
        '4 days',
        '5 days',
        '6 days',
        '7 days'];
    function handleCopy(e) {
        navigator.permissions
            .query({ name: "clipboard-write" })
            .then((result) => {
                if (result.state === "granted" || result.state === "prompt") {
                    updateClipboard(inputArea.current?.innerText);
                }
            });
    }
    async function giveBirth(matronId) {
        try {
            setIsSpinDoge(true)
            //kiểm tra xem doge có đang mang thai và có thể sinh được chưa
            const contract = await contractServices()
            const doge = await contract.getDoge(matronId)
            const isGestating = doge[0]
            const isReady = doge[1]
            const nextActionAt = doge[3]
            if (isGestating) {
                if (isReady) {
                    await contract.giveBirth(matronId)
                    setIsSpinDoge(false)
                    await dispatch(getDogeDetail({ dogeId }))
                    toastSuccessMessage(`Successful birth`)
                }
                else {
                    toastErrorMessage(`Doge have not reached the time of laying, will give birth in block number: ${nextActionAt}`)
                    setIsSpinDoge(false)

                }
            } else {
                setIsSpinDoge(false)
                toastErrorMessage(`Dog is not pregnant`)
            }
        } catch (error) {
            setIsSpinDoge(false)
        }
    }
    function updateClipboard(newClip) {
        navigator.clipboard.writeText(newClip).then(
            () => {
                setCopy(true);
                toast.success('Copied!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            },
            () => {
                setCopy(false);
                // setOpen(false)

            }
        );
    }
    const [openSell, setOpenSell] = useState({
        flag: false,
        doge: {},
        price: 0
    })
    const [openBuy, setOpenBuy] = useState({
        flag: false,
        doge: {},
        price: 0
    })
    const [openTransfer, setOpenTransfer] = useState({
        flag: false,
        doge: {},
        price: 0
    })
    // handleClickOpenSell
    const handleClickOpenSell = (doge, siring) => {
        setOpenSell({
            flag: true,
            doge,
            siring: siring ? true : false
        });
    };
    const handleClickOpen = (doge, siring) => {
        setOpenBuy({
            flag: true,
            doge,
            siring: siring ? true : false
        });
    };
    const [openSiring, setOpenSiring] = useState({
        flag: false,
        doge: {},
        price: 0
    })
    const handleClickOpenTransfer = async (doge, price) => {
        setOpenTransfer({
            flag: true,
            doge,
            price: price
        });
    };
    const handleClickCloseTransfer = async (doge, price) => {
        setOpenTransfer({
            flag: false
        });
    };
    const handleClickOpenSiring = async (doge, price, myBread) => {
        setOpenSiring({
            flag: true,
            doge,
            price: price
        });
        setMyBread(myBread)
    };
    const handleClickCloseSiring = async (doge, price) => {
        setOpenSiring({
            flag: false
        });
    };
    const handleCloseBuy = () => {
        setOpenBuy({
            flag: false
        });
    };
    const handleClose = () => {
        setOpenSell({
            flag: false
        });
    };
    async function cancel(dogeId, siring) {
        try {

            setIsSpinCancel(true)
            const contract = await contractServices()
            if (siring) {
                await contract.cancelSiring(dogeId)
                // await dispatch(getMySiringDoges())
            }
            else {
                await contract.cancelSelling(dogeId)
                // await dispatch(myDogeDispatch())
            }
            setIsSpin(false)

            window.location.reload()
            toastSuccessMessage(`Cancel success`)
        } catch (error) {
            setIsSpin({ ...isSpin })
            console.log(error);
        }
    }
    useEffect(async () => {
        try {
            const dogeDispatch = await dispatch(getDogeDetail({ dogeId }))
            if (dogeDispatch?.payload?.dogeId) {
                let data = {}
                data.where = `matronId = ${dogeDispatch.payload.dogeId} OR sireId=${dogeDispatch.payload.dogeId}`
                const dataReturn = await createApiBody(data, 'api/doge/getDogeParent')
                setDogeChildren(dataReturn.data.data)
                // setIsSpin(false)
                setIsLoading(true)
                await funcWallet(dispatch)
            }
        } catch (error) {
            console.log(error);
        }
    }, [dogeId])



    // element
    const elementFunc = () => {
        if (dogeDetail?.siring == 1) {
            return (
                <>
                    <div className='formNow'>
                        <h5>Price</h5>
                        <div className='formDetail'>
                            <div className='price'>
                                <img src={logoBNB} width="30" height={30} alt="" />
                                <p className='priceBNB fzb'>   {truncate(dogeDetail?.price, 6)} BNB</p>
                                <span className='priceUSD fz'>~ $ {truncate(priceCoin.BNB * dogeDetail.price, 2)}</span>
                            </div>
                            <div className='button'>
                                <button className='btnSiring' onClick={() => handleClickOpenSiring(dogeDetail, dogeDetail.price, false)}>Siring Now</button>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                </>

            )
        } else if (dogeDetail?.sell == 1) {
            return (
                <>
                    <div className='formNow'>
                        <h5>Price</h5>
                        <div className='formDetail'>
                            <div className='price '>
                                <img src={logoBNB} width="30" height={30} alt="" />
                                <p className='priceBNB fzb'>   {truncate(dogeDetail?.price, 6)} BNB</p>
                                <span className='priceUSD fz'>~ $ {truncate(priceCoin.BNB * dogeDetail.price, 2)}</span>
                            </div>
                            <div className='button'>
                                <button className='btnDo' onClick={() => handleClickOpen(dogeDetail, dogeDetail?.price)}>Buy Now</button>
                            </div>
                        </div>
                    </div>
                    <hr></hr>

                </>
                // <div className="row my-3">
                //     <div className="funcDoge">
                //         <div className="boxDoge">
                //             <div className="box">
                //                 <div className="title">
                //                     Buy now price
                //                 </div>
                //                 <span className='price'>
                //                     {truncate(dogeDetail?.price, 6)} BNB
                //                 </span>
                //                 <br></br>
                //                 <span className='USD'>($ {truncate(priceCoin.BNB * dogeDetail.price, 2)})</span>
                //             </div>
                //             <div className="action">
                //                 <button className='btnSiring' onClick={() => handleClickOpen(dogeDetail, dogeDetail?.price)}>Buy Now</button>
                //             </div>
                //         </div>
                //     </div>
                // </div>
            )
        }
        return <></>
    }
    const elementParent = () => {
        if (dogeDetail?.matronId == 0 && dogeDetail?.sireId == 0) {
            return <></>
        }
        if (dogeDetail) {
            return <>
                <div className="row my-3">
                    <div className="col-12">
                        <h3>Parents</h3>
                        <div className="containerCard " style={{ justifyContent: "inherit" }}>
                            <CardParent dogeId={dogeDetail?.matronId} />
                            <CardParent dogeId={dogeDetail?.sireId} />
                        </div>
                    </div>
                </div>
            </>
        }
        return <></>
    }
    const elementCancel = () => {
        return (
            <>
                <div className="col-12 my-2 text-center">
                    <button className='btnDo ' disabled={isSpinCancel} onClick={() => dogeDetail.siring == 1 ? cancel(dogeDetail.dogeId, true) : cancel(dogeDetail.dogeId)} style={{ width: "30%" }}>
                        <WithSpin isSpin={isSpinCancel}>
                            Cancel {`${dogeDetail.siring == 1 ? `Siring` : `Sell`} `}
                        </WithSpin>
                    </button>
                </div>
            </>
        )
    }
    // /
    const [isSpin, setIsSpin] = useState({
        siring: false,
        sell: false,
        cancel: false
    })
    async function approved(siring) {
        try {
            if (siring) setIsSpin({ ...isSpin, siring: true })
            else setIsSpin({ ...isSpin, sell: true })
            const contract = await contractServices()
            if (siring) await contract.approveToSireDoge()
            else await contract.approveToSellDoge()
            setIsSpin({ siring: false, sell: false, cancel: false })
            toastSuccessMessage(`Approved success`)
            await dispatch(getMyDogesAction({ limit: 12, page: 1 }))
            await funcWallet(dispatch)
        } catch (error) {
            toastErrorMessage(`The transaction has been declined`)
            setIsSpin({ siring: false, sell: false, cancel: false })
        }

    }
    const elementFuncFunc = () => {

        return (
            <>
                {approvedSell ?
                    <button className={`buttonBuy btnDo fz`} onClick={() => handleClickOpenSell(dogeDetail)} >
                        Sell Now
                    </button>
                    :
                    <button className={`buttonBuy btnDo btnApprovedMobile fz`} disabled={true ? loadingFunc : isSpin.siring} onClick={() => approved()}>
                        <WithSpin isSpin={loadingFunc || isSpin.sell}>
                            Approved Sell
                        </WithSpin>
                    </button>
                }

            </>
        )
    }
    const elementSiring = () => {
        return (
            <>
                <>
                    {approvedSiring ?
                        <button className='btnSiring buttonBuy fz' disabled={loadingFunc} onClick={() => handleClickOpenSell(dogeDetail, true)} style={{ width: "30%" }}>

                            <WithSpin isSpin={loadingFunc}>
                                Siring
                            </WithSpin>
                        </button>
                        :
                        <button className='buttonBuy btnDo btnApprovedMobile fz' disabled={loadingFunc} onClick={() => approved(true)} style={{ width: "50%" }}>
                            <WithSpin isSpin={loadingFunc || isSpin.siring}>
                                Approved Siring
                            </WithSpin>
                        </button>}


                </>
                {/* handleClickOpen */}
            </>
        )
    }
    const elementChildren = () => {
        if (dogeChildren.length <= 0) {
            return <></>
        }
        return <>
            <div className="row my-1">
                <div className="col-12">
                    <h3>Children</h3>
                    <div style={{
                        float:"right"
                    }}>
                        {limitChildren > 0 ?
                            <img src={Left} alt="" width={35} height={35} onClick={() => {
                                setLimitChidren(limitChildren - 1)
                            }} style={{
                                cursor: "pointer"
                            }} /> :
                            <></>
                        }
                        {limitChildren + 3 <= dogeChildren.length - 1 ?
                            <img src={Right} alt="" disabled={true} width={35} height={35} onClick={() => {
                                setLimitChidren(limitChildren + 1)
                            }} style={{
                                cursor: "pointer"
                            }} /> :
                            <></>
                        }
                    </div>
                    <div className="containerCard " style={{ justifyContent: "inherit" }}>

                        {dogeChildren?.map((item, index) => {
                            if (index <= limitChildren + 2 && index >= limitChildren) return <CardParent dogeId={item.dogeId} />
                        })}


                    </div>
                </div>
            </div>
        </>
    }
    return (
        <>
            {!isLoading ? <LoadingDetail /> :
                <>
                    <div className='detail' style={{
                        marginBottom: "100px"
                    }}>
                        <div className="row">
                            <div className='detailImage col-12 col-md-6' >
                                <div className="bg" style={{
                                    background: `${dogeDetail.background}`
                                }}>
                                    <img src={`${port}${dogeDetail?.image}`} width={400} alt="" />
                                </div>
                            </div>
                            <div className='detailInfo col-12 col-md-6'>
                                <div className="container-fluid" style={{
                                    padding: "0px"
                                }}>
                                    <h3>Bio #{dogeDetail?.dogeId}</h3>
                                    <div className="detailInfo-info row">
                                        <div className='item col-4' style={{
                                            padding: "0px"
                                        }}>
                                            <img src={iconBirhDay} alt="" width={20} height={20} />
                                            <p>
                                                {convertTimeToBirthDay(dogeDetail?.birthTime)}
                                            </p>
                                        </div>
                                        <div className='item col-2'>
                                            <img src={iconGen} alt="" width={20} height={20} />
                                            <p>
                                                {dogeDetail?.generation}

                                            </p>
                                        </div>
                                        <div className="item col-6">
                                            <img src={iconSword} alt="" width={20} height={20} />
                                            <p>
                                                {cooldowns[dogeDetail?.cooldownIndex]}
                                            </p>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="detailInfo-info row">
                                        <div className='col-6 row'>
                                            <img src={Health} width={40} height={40} alt="" />
                                            <div className='atribute'>
                                                <span className='fz'>HEALTH</span>
                                                <b className='fzs'>{dogeDetail?.health}</b>
                                            </div>
                                        </div>
                                        <div className='col-6 row'>
                                            <img src={Khien} width={40} height={40} alt="" />
                                            <div className='atribute'>
                                                <span className='fz'>DEFENSE</span>
                                                <b className='fzs'>{dogeDetail?.defense}</b>
                                            </div>
                                        </div>
                                        <div className='col-6 row'>
                                            <img src={Attack} width={40} height={40} alt="" />
                                            <div className='atribute'>
                                                <span className='fz'>ATTACK</span>
                                                <b className='fzs'> {dogeDetail?.attack}</b>
                                            </div>
                                        </div>
                                        <div className='col-6 row'>
                                            <img src={Money} width={40} height={40} alt="" />
                                            <div className='atribute'>
                                                <span className='fz' style={{ whiteSpace: "nowrap" }}>EARNINGS</span>
                                                <b className='fzs'>${truncate(dogeDetail?.estimatedEarnings, 2)} / days</b>
                                            </div>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div>
                                        <h5 className='title'>Owner</h5>
                                        <div className='ownerInput'>
                                            <b className='fzs'>
                                                {/* Owner: */}
                                                {dogeDetail.owner}
                                            </b>
                                            <ContentCopyIcon onClick={handleCopy} className="ml-2" sx={{
                                                cursor: 'pointer;'
                                            }} />
                                            <div ref={inputArea} style={{ display: "none" }}>
                                                {dogeDetail.owner}
                                            </div>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div>
                                        {wallet?.address.toLowerCase() == dogeDetail?.owner.toLowerCase() && dogeDetail.deposit != 1 ?
                                            <>
                                                {/* <h3>Function</h3> */}

                                                {dogeDetail.isGestating == 1 || dogeDetail.timeIsGestating && dogeDetail.isReady != 1 ?
                                                    /// Check doge đang có thai hay không nếu có thai thì hiện nút cho đẻ
                                                    <>
                                                        <div className="row my-3 text-center">
                                                            <div className="col-12 row " style={{
                                                                justifyContent: "center"
                                                            }}>
                                                                {dogeDetail.timeIsGestating ?
                                                                    <div style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center"
                                                                    }}>
                                                                        <img src={readyTime} width={30} alt="" />
                                                                        <p style={{
                                                                            color: "#252524",
                                                                            fontWeight: "500",
                                                                            margin: "0px 5px",
                                                                            fontSize: "13px"
                                                                        }}>
                                                                            {`READY TO ${dogeDetail.isGestating == 1 ? 'RED PREPARATION' : 'RESTING'} IN`}
                                                                        </p>
                                                                    </div> : <></>
                                                                }


                                                                <button className={`btn${dogeDetail.timeIsGestating ? `Do` : `Ready`}`}
                                                                    onClick={() => giveBirth(dogeDetail.dogeId)} style={dogeDetail.timeIsGestating ? { cursor: "initial" } : {}} disabled={dogeDetail.timeIsGestating}>
                                                                    {/* Đang có thai */}

                                                                    <WithSpin isSpin={isSpinDoge}>
                                                                        {/* {item.isGestating == 1 ? <p className='text-white waiting'>Pregnant</p> : <p className='text-white waiting'>Resting</p>} */}
                                                                        {dogeDetail.timeIsGestating ?
                                                                            <>

                                                                                <Countdown value={new Date().getTime() + dogeDetail.timeIsGestating} format={` H : m : s `} onFinish={() => {
                                                                                    dispatch(getDogeDetail({ dogeId }))
                                                                                }} />
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    dogeDetail.isGestating ?
                                                                                        <>
                                                                                            Ready to give birth
                                                                                        </> :
                                                                                        <>
                                                                                        </>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </WithSpin>


                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                    /* {    NẾU KHÔNG CÓ THÌ HIỂN THỊ CÁC NÚT CHỨC NĂNG} */
                                                    : <>

                                                        {dogeDetail.siring == 1 || dogeDetail.sell == 1 ?

                                                            <>

                                                                {/* {elementCancel()} */}
                                                            </> :

                                                            <>
                                                                <div className='row my-5'>
                                                                    <div className="col-6 col-xl-3 col-md-6 my-2">
                                                                        {elementFuncFunc()}
                                                                    </div>
                                                                    <div className="col-6 col-xl-3 col-md-6 my-2">
                                                                        {elementSiring()}
                                                                    </div>
                                                                    <div className="col-6 col-xl-3 col-md-6 my-2">
                                                                        <button className='btnBread fz' disabled={loadingFunc} onClick={() => handleClickOpenSiring(dogeDetail, true, true)} style={{ width: "30%" }}>
                                                                            Bread
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-6 col-xl-3 col-md-6 my-2">
                                                                        <button className='btnTransfer fz' disabled={loadingFunc} onClick={() => handleClickOpenTransfer(dogeDetail, true, true)} style={{ width: "30%" }}>
                                                                            Transfer
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                }

                                            </>
                                            : <></>
                                        }
                                        {elementFunc()}

                                    </div>
                                    <div>
                                        {elementChildren()}
                                    </div>
                                    <div>
                                        {elementParent()}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                    <PopupSiring handleClose={handleClickCloseSiring} handleClickOpen={handleClickOpenSiring} doge={openSiring.doge} price={openSiring.price} open={openSiring} myBread={myBread} popupSiring={!myBread} />
                    <PopupBuy handleClose={handleCloseBuy} handleClickOpen={handleClickOpen} doge={openBuy.doge} price={openBuy.price} open={openBuy} />
                    <PopupSell handleClose={handleClose} handleClickOpen={handleClickOpenSell} open={openSell} />
                    <PopupTransfer handleClose={handleClickCloseTransfer} handleClickOpen={handleClickOpenTransfer} open={openTransfer} />
                </>
            }

        </>
    );
}

export default DetailDoge;