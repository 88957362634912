import { DialogTitle, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import Ruby from '../../../../asset/images/ruby.png'
import DogeClaim from '../../../../asset/photoshop/dogeClaim.png'
import Wod from '../../../../asset/images/wod.png'
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bigNumber, truncate } from '../../../../commons/blockchain';
import { toastErrorMessage, toastSuccessMessage } from '../../../../commons/message';
import WithSpin from '../../../../HOC/WithSpin';
import { port } from '../../../../port';
import { openPopupConnectAction } from '../../../../Redux/userSlice';
import { contractServices } from '../../../../Services/contract';
import './style.scss';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PopupReward({ open, handleClickOpen, handleClose }) {
    const [age, setAge] = React.useState(0);
    const [startPrice, setStartPrice] = useState(0)
    const [endPrice, setEndPrice] = useState(0)
    const [feePercent, setFeePercent] = useState(0)
    const { priceCoin } = useSelector(state => state.userReducer)
    const [err, setErr] = useState("")
    const [balanceRuby, setBalanceRuby] = useState(0)
    const [balanceWod, setBalanceWod] = useState(0)
    const [balanceDoge, setBalancDoge] = useState(0)
    const [isSpin, setIsSpin] = useState(false)
    const dispatch = useDispatch()
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    async function sellDoge() {
        try {
            const wallet = JSON.parse(localStorage.getItem('wallet'))
            if (!wallet) return dispatch(openPopupConnectAction(true))
            setIsSpin(true)
            const contract = await contractServices()
            const approved = await contract.checkApproveToSellDoge(contract.currentUserAddress)
            const doge = open.doge
            const dogeId = doge.dogeId
            if (startPrice <= 0) {
                setIsSpin(false)
                return toastErrorMessage(`Invalid price`)
            }


            if (open.siring) {
                ////

                if (!approved) {
                    //approve
                    await contract.approveToSireDoge()
                }
            }
            const startingPrice = bigNumber(`${startPrice}`) //1 BNB
            if (open.siring) await contract.createSiringAuction(dogeId, startingPrice)
            else await contract.sellDoge(dogeId, startingPrice)
            setIsSpin(false)
            toastSuccessMessage(`${open.siring ? `Successful Doge rental` : `Successfully sold Doge`}`)
            handleClose()
            // await dispatch(myDogeDispatch())
            // await dispatch(getMyDogesAction({ limit: 12, page: 1 }))
            window.location.reload()
            setStartPrice(0)
            setEndPrice(0)
            setAge(0)
        } catch (error) {
            console.log(error);
            toastErrorMessage(`The transaction has been declined`)
            setIsSpin(false)
        }
    }
    async function siringDoge() {

    }
    async function getBalance(contract) {
        const wodQuery = contract.getWODReward()
        const dogeQuery = contract.getDogeReward()
        const [wod, doge] = await Promise.all([wodQuery, dogeQuery])
        setBalancDoge(doge)
        setBalanceWod(wod / 1e18)
    }
    useEffect(async () => {
        const wallet = JSON.parse(localStorage.getItem('wallet'))
        if (wallet) {
            const contract = await contractServices()
            await getBalance(contract)
        }


    }, [])
    return (
        <div>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Slide in alert dialog
            </Button> */}
            <Dialog
                open={open.flag}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <h5 className='text-center mt-1' style={{
                            color:"#353535"
                        }}>REWARD</h5>
                        <div className='formSliver my-2' style={{
                            alignItems: "center"
                        }}>
                            <div className="priceGroup" style={{
                                display: "flex",
                                width: "280px"
                            }}>
                                <img src={Wod} alt="" width={40} height={40} />

                                <div>
                                    <b className="priceBNB" style={{
                                        color: "#353637"
                                    }}>
                                        {truncate(balanceWod, 2)}
                                    </b>
                                    <p className='priceUSD' style={{
                                        margin: "0px",
                                        fontStyle: "italic",
                                        fontSize: "13px"
                                    }}>~ $ {truncate(balanceWod * priceCoin.wod)}</p>
                                </div>
                            </div>
                            <button className="btnClaim btnClaimMobile" disabled={balanceWod <= 0 || isSpin} onClick={async () => {
                                try {
                                    setIsSpin(true)
                                    const contract = await contractServices()
                                    await contract.claimWOD()
                                    await getBalance(contract)
                                    setIsSpin(false)
                                    toastSuccessMessage('Claim success')
                                } catch (error) {
                                    setIsSpin(false)
                                    toastErrorMessage(`Transaction error`)
                                }
                            }}>
                                <WithSpin isSpin={isSpin}>
                                    Claim
                                </WithSpin>
                            </button>
                        </div>
                        <div className='formSliver my-2' style={{
                            alignItems: "center"
                        }}>
                            <div className="priceGroup" style={{
                                display: "flex",
                                width: "280px"
                            }}>
                                <img src={Ruby} alt="" width={40} height={40} />

                                <div>
                                    <b className="priceBNB" style={{
                                        color: "#353637"
                                    }}>
                                        {balanceRuby}
                                    </b>
                                    <p className='priceUSD' style={{
                                        margin: "0px",
                                        fontStyle: "italic",
                                        fontSize: "13px"
                                    }}>~ $ {balanceRuby * priceCoin.ruby}</p>
                                </div>
                            </div>
                            <button className="btnClaim btnClaimMobile" disabled={balanceRuby <= 0 || isSpin}>
                                <WithSpin isSpin={isSpin}>
                                    Claim
                                </WithSpin>
                            </button>
                        </div>
                        <div className='formSliver my-2' style={{
                            alignItems: "center"
                        }}>
                            <div className="priceGroup" style={{
                                display: "flex",
                                width: "280px",
                                alignItems: "flex-end"
                            }}>
                                <img src={DogeClaim} alt="" width={40} height={40} />
                                <b className="priceBNB" style={{
                                    color: "#353637"
                                }}>
                                    {balanceDoge}
                                </b>
                            </div>

                            <button className="btnClaim btnClaimMobile" disabled={balanceDoge <= 0 || isSpin} onClick={async () => {
                                try {
                                    setIsSpin(true)
                                    const contract = await contractServices()
                                    await contract.claimDoge()
                                    await getBalance(contract)
                                    setIsSpin(false)
                                    toastSuccessMessage('Claim success')
                                } catch (error) {
                                    setIsSpin(false)
                                    toastErrorMessage(`Transaction error`)
                                }
                            }}>
                                <WithSpin isSpin={isSpin}>
                                    Claim
                                </WithSpin>
                            </button>
                        </div>
                    </DialogContentText>
                </DialogContent>
                {/* <DialogActions>
                    <Button disabled={isSpin} className="btnBuyNow" sx={{
                        width: "284px",
                        color: "white",
                        outline: "none"
                    }} onClick={sellDoge}>
                        <WithSpin isSpin={isSpin} >
                            {`${open.siring ? "Siring" : "Sell"}`}
                        </WithSpin>
                    </Button>


                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions> */}
            </Dialog>
        </div>
    );
}
