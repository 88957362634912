import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHistoryAttackAll } from '../../Redux/action/api/dogeReducer';
import Table from '../Table';
import { strWallet } from '../../commons/blockchain'
import TableCustom from './TableHistoryAttackAll';
import PaginationCustom from '../PaginationCustom';
import { dogeWarInstance } from '../../contractAccount';
import StatisticalHistoryAttackAll from './StatisticalHistoryAttackAll';
function HistoryAttackAll({ checkBox, statistical, current, valueTab }) {
    const dispatch = useDispatch()
    const { historyAttackAll } = useSelector(state => state.dogesApiReducer)
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const arrayTitle = [

        {
            title: "Attacker",
            key: "attacker"
        },
        {
            title: "Defender",
            key: "defender"
        },
        {
            title: "Status",
            key: "status"
        },
        {
            title: "Reward",
            key: "reward"
        },
        {
            title: "At",
            key: "created_at"
        },
    ]
    const arrayTitleTop = [

        {
            title: "Top",
        },
        {
            title: "Address",
        },
        {
            title: "Reward",
            key: "reward"
        },

    ]
    const arraySrouce = []
    // if (historyAttackAll.array.length > 0) {
    //     for (let item of historyAttackAll?.array) {
    //         let obj = item
    //         let strStatus = 'Win'
    //         let classDiv = 'success'
    //         if (item?.status == 0) {
    //             classDiv = 'warning'
    //             strStatus = 'Draw'
    //         }
    //         else {
    //             strStatus = 'Lose'
    //             classDiv = 'danger'
    //         }
    //         obj.statusStr = <>
    //             <p className={`text-${classDiv}`}>{strStatus}</p>
    //         </>
    //         obj.attacker = strWallet(item?.attacker)
    //         obj.defender = strWallet(item?.defender)
    //         arraySrouce.push(obj)
    //     }
    // }
    function funcPagination(limit, page) {
        setLimit(limit)
        setPage(page)
    }
    async function returnDataHistory() {
        if (statistical && valueTab >= 1) {
            let start, end
            /// thống kê theo last
            if (checkBox.yesterday) {
                let yesterdayTime = new Date(new Date().setDate(new Date().getDate() - 1));
                start = yesterdayTime.setHours(0, 0, 0, 0);
                end = yesterdayTime.setHours(23, 59, 59, 999);
            } else if (checkBox.lastWeek) {
                var dt = new Date(); // current date of week
                var currentWeekDay = dt.getDay() + 7;
                var lessDays = currentWeekDay == 0 ? 6 : currentWeekDay - 1;
                var wkStart = new Date(new Date(dt).setDate(dt.getDate() - lessDays));
                var wkEnd = new Date(new Date(wkStart).setDate(wkStart.getDate() + 6));
                start = wkStart.setHours(0, 0, 0, 0);
                end = wkEnd.setHours(23, 59, 59, 999);
            } else if (checkBox.lastMonth) {
                var dateNow = new Date();
                start = new Date(dateNow.getFullYear(), dateNow.getMonth() - 1, 1).getTime();
                end = new Date(dateNow.getFullYear(), dateNow.getMonth(), 0).getTime();
            }
            /// thống kê theo hiện tại
            else if (checkBox.today) {
                let yesterdayTime = new Date(new Date().setDate(new Date().getDate()));
                start = yesterdayTime.setHours(0, 0, 0, 0);
                end = yesterdayTime.setHours(23, 59, 59, 999);
            } else if (checkBox.week) {

                var dt = new Date(); // current date of week
                var currentWeekDay = dt.getDay() ;
                var lessDays = currentWeekDay == 0 ? 6 : currentWeekDay - 1;
                var wkStart = new Date(new Date(dt).setDate(dt.getDate() - lessDays));
                var wkEnd = new Date(new Date(wkStart).setDate(wkStart.getDate() + 6));
                start = wkStart.setHours(0, 0, 0, 0);
                end = wkEnd.setHours(23, 59, 59, 999);
            } else if (checkBox.month) {
                var dateNow = new Date();
                start = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1).getTime();
                end = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0).getTime();
            }
            let where = `${start}<=created_at*1000 AND created_at*1000<= ${end}`
            await dispatch(getHistoryAttackAll({ limit, page, statistical, where }))
        } else {
            let wallet = JSON.parse(localStorage.getItem('wallet'))
            if (checkBox.allAttack) await dispatch(getHistoryAttackAll({ limit, page }))
            else if (checkBox.ownerAttack) {
                let where = `attacker='${wallet.address}' OR defender='${wallet.address}'`
                await dispatch(getHistoryAttackAll({ limit, page, where }))
            }
        }
    }

    useEffect(() => {
        let emitter = dogeWarInstance.events.AttackHero({}).on('data', async event => {
            setLimit(limit + 1)
        })
            .on('changed', changed => console.log(changed))
            .on('error', err => console.log('error', err.message, err.stack))
            .on('connected', str => {
            })
        return () => {
            emitter.removeAllListeners('data')
        }
    })
    useEffect(async () => {
        await returnDataHistory()

    }, [limit, page, checkBox])
    return (
        <>
            {/* NẾU LÀ THỐNG KÊ THÌ KHÔNG CẦN PHÂN TRANG CHỈ HIỂN THỊ TOP 10 */}
            {statistical ?
                <>
                    <StatisticalHistoryAttackAll arrayTitle={arrayTitleTop} arrayData={historyAttackAll?.array} title={`Top Recently Attack`} />
                </> :
                <>
                    <TableCustom arrayTitle={arrayTitle} arrayData={historyAttackAll?.array} title={`Recently Attack`} />
                    <PaginationCustom total={historyAttackAll?.total} limit={limit} page={page} funcPagination={funcPagination} />
                </>
            }
        </>
    );
}

export default HistoryAttackAll;