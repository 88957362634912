import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDogeDetail, getHeroDetail } from '../../Redux/action/api/dogeReducer';
import Wod from '../../asset/images/wod.png'
import Ruby from '../../asset/images/ruby.png'
import Health from '../../asset/photoshop/health.png'
import Attack from '../../asset/photoshop/attack.png'
import Rank from '../../asset/photoshop/rank.png'
import readyTime from '../../asset/photoshop/readyTime.png'
import Left from '../../asset/photoshop/left.png'
import Right from '../../asset/photoshop/right.png'
import Money from '../../asset/photoshop/power.png'
import Khien from '../../asset/photoshop/defiend.png'
import MoneyWhite from '../../asset/images/money.png'
import logoBNB from '../../asset/images/bnb.png'
import { strWallet, truncate } from '../../commons/blockchain';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import './style.scss'
import { toast } from 'react-toastify';
import { Col, Row } from 'antd';
import CardParent from '../../Component/CardParent';
import { port } from '../../port';
import PopupBuy from '../../Component/Card/CardItem/PopupBuy';
import PopupSiring from '../../Component/CardSiringAuction/CardItem/PopupSiring';
import { createApiBody } from '../../Services';
import LoadingDetail from '../../Component/LoadingDetail';
import { convertTimeToBirthDay } from '../../commons';
import { Statistic } from 'antd';
import PaginationCustom from '../../Component/PaginationCustom';
import HistoryAttackHero from '../../Component/HistoryAttackHero';
import { contractServices } from '../../Services/contract';
import { smallNumber } from '../../contractAccount';
import { toastErrorMessage, toastSuccessMessage } from '../../commons/message';
import WithSpin from '../../HOC/WithSpin';

const { Countdown } = Statistic;
const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title}:</p>
        {content}
    </div>
);

function DetailHero({ }) {
    const { owner } = useParams()
    const { heroDetail } = useSelector(state => state.dogesApiReducer)
    const { profile } = useSelector(state => state.userReducer)
    const [isLoading, setIsLoading] = useState(false)
    const [isSpin, setIsSpin] = useState(false)
    let defaultLimit = 3
    // if (window.innerWidth <= 900 && window.innerWidth >629) {
    //     defaultLimit = 3
    // } else if (window.innerWidth <= 1300 && window.innerWidth >767) {
    //     defaultLimit = 2
    // } else if (window.innerWidth <= 1700 && window.innerWidth >767) {
    //     defaultLimit = 1
    // }
    const [limit, setLimit] = useState(defaultLimit)
    const [page, setPage] = useState(1)
    const dispatch = useDispatch()
    const { priceCoin } = useSelector(state => state.userReducer)
    const [copy, setCopy] = useState(false)
    const [dogeChildren, setDogeChildren] = useState([])
    const inputArea = React.useRef(null);
    const cooldowns = [
        '15 minutes',
        '30 minutes',
        '1 hours',
        '2 hours',
        '4 hours',
        '8 hours',
        '16 hours',
        '1 day',
        '2 days',
        '3 days',
        '4 days',
        '5 days',
        '6 days',
        '7 days'];
    async function harvest() {
        try {
            setIsSpin(true)
            const contract = await contractServices()
            const pendingReward = await contract.getPendingReward(contract.currentUserAddress)
            const pendingRuby = smallNumber(pendingReward[0])
            const pendingWOD = smallNumber(pendingReward[1])
            await contract.harvest()
            window.location.reload()
            toastSuccessMessage(`Successful harvest`)
            setIsSpin(false)
        } catch (error) {
            setIsSpin(false)
            toastErrorMessage('Transaction Error')
        }
    }
    function handleCopy(e) {
        navigator.permissions
            .query({ name: "clipboard-write" })
            .then((result) => {
                if (result.state === "granted" || result.state === "prompt") {
                    updateClipboard(inputArea.current?.innerText);
                }
            });
    }
    async function attackHero(defenderAddress) {
        //attack a Doge
        try {
            setIsSpin(true)

            const contract = await contractServices()
            const attackerAddress = contract.currentUserAddress

            // address của player muốn attack, lấy danh sách các address hiện tại bằng getPlayerList()

            const canAttack = await contract.areInPointsRange(attackerAddress, defenderAddress)
            if (canAttack) {
                //có thể tấn công
                await contract.attackHero(defenderAddress)
                // dispatch(getHistoryAttackAll({ limit: 12, page: 1 }))
                dispatch(getHeroDetail({ owner, limit, page }))
                toastSuccessMessage('Successful attack')
                // window.location.reload()
                setIsSpin(false)

            } else {
                toastErrorMessage("Unable to attack")
            }
        } catch (error) {
            console.log(error)
            toastErrorMessage("Transaction Error!")
            setIsSpin(false)

        }

    }
    function updateClipboard(newClip) {
        navigator.clipboard.writeText(newClip).then(
            () => {
                setCopy(true);
                toast.success('Copied!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            },
            () => {
                setCopy(false);
                // setOpen(false)

            }
        );
    }
    const [openSell, setOpenSell] = useState({
        flag: false,
        doge: {},
        price: 0
    })
    const handleClickOpen = async (doge, price) => {
        setOpenSell({
            flag: true,
            doge,
            price: price
        });
    };

    const handleClose = () => {
        setOpenSell({
            flag: false
        });
    };
    function funcPagination(limit, page) {
        setLimit(limit)
        setPage(page)
    }
    useEffect(async () => {
        try {
            const heroDispatch = await dispatch(getHeroDetail({ owner, limit, page }))
            if (heroDispatch?.payload?.owner) {
                let data = {}
                // setIsSpin(false)
                setIsLoading(true)
            }
        } catch (error) {
            console.log(error);
        }
    }, [limit, page, owner])



    // element
    const elementFunc = () => {
        if (heroDetail?.siring == 1) {
            return (
                <div className="row my-3">
                    <div className="funcDoge">
                        <div className="boxDoge">
                            <div className="box">
                                <div className="title">
                                    Breed now price
                                </div>
                                <span className='price'>
                                    {truncate(heroDetail?.price, 6)} BNB
                                    <br></br>
                                    <span className='USD'>($ {truncate(priceCoin.BNB * heroDetail.price, 2)})</span>
                                </span>
                            </div>
                            <div className="action">
                                <button className='btn btn-success' onClick={() => handleClickOpen(heroDetail, heroDetail?.price / 1e18)}>Bread Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (heroDetail?.sell == 1) {
            return (
                <div className="row my-3">
                    <div className="funcDoge">
                        <div className="boxDoge">
                            <div className="box">
                                <div className="title">
                                    Buy now price
                                </div>
                                <span className='price'>
                                    {truncate(heroDetail?.price / 1e18, 6)} BNB
                                </span>
                                <br></br>
                                <span className='USD'>($ {truncate(priceCoin.BNB * heroDetail.price, 2)})</span>
                            </div>
                            <div className="action">
                                <button className='btn btn-success' onClick={() => handleClickOpen(heroDetail, heroDetail?.price / 1e18)}>Buy Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return <></>
    }
    const elementParent = () => {
        return <>
            <div className="row my-3">
                <div className="col-12">
                    <h3>Doge Deposit</h3>
                    <div className="containerCard " style={{ justifyContent: "inherit" }}>
                        {heroDetail?.dogeDeposit?.array?.map((item, index) => {
                            return <CardParent dogeId={item?.dogeId} />
                        })}
                    </div>
                    <PaginationCustom total={heroDetail?.dogeDeposit?.total} limit={limit} funcPagination={funcPagination} page={page} />
                </div>
            </div>
        </>
    }
    const elementChildren = () => {
        if (dogeChildren.length <= 0) {
            return <></>
        }
        return <>
            <div className="row my-3">
                <div className="col-12">
                    <h3>Children</h3>
                    <div className="containerCard " style={{ justifyContent: "inherit" }}>
                        {dogeChildren?.map((item, index) => {
                            return <CardParent dogeId={item.dogeId} />
                        })}
                    </div>

                </div>
            </div>
        </>
    }
    return (
        <>
            {!isLoading ? <LoadingDetail /> :
                <>
                    <div className='detail'>
                        <div className="row">
                            <div className='detailImage col-12 col-md-6' >
                                <div className="bg" style={{
                                    background: `${heroDetail?.dogeFirstDeposit?.background}`
                                }}>
                                    <img src={`${port}${heroDetail?.dogeFirstDeposit?.image}`} width={400} alt="" />
                                </div>
                            </div>
                            <div className='detailInfo col-12 col-md-6'>
                                <div className="container-fluid">

                                    <div className="detailInfo-info row">
                                        <div className='col-6 row'>
                                            <img src={Rank} width={50} height={50} alt="" />
                                            <div className='atribute'>
                                                <span>RANK</span>
                                                <b>{heroDetail?.ranker}</b>
                                            </div>
                                        </div>
                                        <div className='col-6 row'>
                                            <img src={Khien} width={50} height={50} alt="" />
                                            <div className='atribute'>
                                                <span>DEFENSE</span>
                                                <b>{heroDetail?.defense}</b>
                                            </div>
                                        </div>
                                        <div className='col-6 row'>
                                            <img src={Attack} width={50} height={50} alt="" />
                                            <div className='atribute'>
                                                <span>ATTACK</span>
                                                <b>{heroDetail?.attack}</b>
                                            </div>
                                        </div>
                                        <div className='col-6 row'>
                                            <img src={Health} width={50} height={50} alt="" />
                                            <div className='atribute'>
                                                <span>HEALTH</span>
                                                <b>{heroDetail?.health}</b>
                                            </div>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div>
                                        <h5 className='title'>Owner</h5>
                                        <div className='ownerInput'>
                                            <b className='fzs'>
                                                {/* Owner: */}
                                                {heroDetail.owner}
                                                {/* {window.innerWidth<=600?strWallet(heroDetail.owner):heroDetail.owner} */}
                                            </b>
                                            <ContentCopyIcon onClick={handleCopy} className="ml-2" sx={{
                                                cursor: 'pointer;'
                                            }} />
                                            <div ref={inputArea} style={{ display: "none" }}>
                                                {heroDetail.owner}
                                            </div>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="row">
                                        <div className="col-12 col-lg-6 text-center mt-3">
                                            <p style={{ fontSize: "30px", margin: "0px" }}>Pending Wod
                                            </p>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <img src={Wod} height={35} />
                                                <div className='ml-1 text-left'>
                                                    <b className='m-0'>{truncate(heroDetail?.pending?.pendingWOD / 1e18, 2)}</b>
                                                    <br></br>
                                                    <span className='USD'>$ {truncate(heroDetail?.pending?.pendingWOD / 1e18 * priceCoin.wod)}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 text-center  mt-3">
                                            <p style={{ fontSize: "30px", margin: "0px" }}>Pending Ruby
                                            </p>

                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <img src={Ruby} height={35} />
                                                <div className='ml-1 text-left'>
                                                    <b className='m-0'>{truncate(heroDetail?.pending?.pendingRuby / 1e18, 2)}</b>
                                                    <br></br>
                                                    <span className='USD'>$ {truncate(heroDetail?.pending?.pendingRuby / 1e18 * priceCoin.ruby)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    {profile?.address?.toLowerCase() == heroDetail?.owner?.toLowerCase() ?
                                        <div className="col-12 text-center  mt-3">
                                            <button className='btnHavest' style={{ background: "#4bd56a" }}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <img src={MoneyWhite} height={35} />
                                                    <div className='ml-4 mr-5 text-left ' onClick={harvest}>
                                                        <WithSpin isSpin={isSpin}>
                                                            <b className='m-0' style={{
                                                                fontSize: "25px"
                                                            }}>Harvest</b>
                                                            <br></br>
                                                            <span className=''>Reward ${truncate(heroDetail?.pending?.pendingRuby / 1e18 * priceCoin.ruby + heroDetail?.pending?.pendingWOD / 1e18 * priceCoin.wod)}</span>
                                                        </WithSpin>
                                                    </div>
                                                </div>
                                            </button>
                                        </div> :
                                        <div className="col-12 text-center  mt-3">
                                            <button className='btnAttack' >
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <img src={Attack} height={35} />
                                                    <div className='ml-4 mr-5 text-left ' onClick={() => attackHero(heroDetail?.owner)}>
                                                        <WithSpin isSpin={isSpin}>
                                                            <b className='m-0' style={{
                                                                fontSize: "25px"
                                                            }}>Attack</b>
                                                            <br></br>
                                                            <span className=''>Reward ${truncate(heroDetail?.pending?.pendingRuby / 1e18 * priceCoin.ruby)}</span>
                                                        </WithSpin>

                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    }
                                    <div>
                                        {elementChildren()}
                                    </div>
                                    <div>
                                        {elementParent()}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='main pb-3'
                        style={{
                            width: "90%",
                            margin: "auto"
                        }}
                    >
                        <HistoryAttackHero owner={owner} />
                    </div>
                    <PopupBuy handleClose={handleClose} handleClickOpen={handleClickOpen} doge={openSell.doge} price={openSell.price} open={openSell} />
                    <PopupSiring handleClose={handleClose} handleClickOpen={handleClickOpen} doge={openSell.doge} price={openSell.price} open={openSell} />

                </>
            }

        </>
    );
}

export default DetailHero;