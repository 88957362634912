import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import './style.scss';
import LogoutIcon from '@mui/icons-material/Logout';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PreviewIcon from '@mui/icons-material/Preview';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { Alert, Snackbar } from '@mui/material';
import { getWODBalance } from '../../Services/contract/WODToken'
import { useEthers } from "@usedapp/core";
import Web3 from 'web3'
import view from '../../asset/photoshop/view.png'
import change from '../../asset/photoshop/change.png'
import exit from '../../asset/photoshop/exit.png'
import Web3Modal from "web3modal";
import { useDispatch, useSelector } from 'react-redux';
import { getBalanceThunk, profileAction } from '../../Redux/userSlice';
import Ruby from '../../asset/images/ruby.png'
import Wod from '../../asset/images/wod.png'
import Contract from '../../contract';
import { truncate } from '../../commons/blockchain';
import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { contractServices } from '../../Services/contract';
import { useState } from 'react';
import { useRef } from 'react';
import WithLoading from '../../HOC/WithLoading';
import WithSpin from '../../HOC/WithSpin';
import { toastSuccessMessage } from '../../commons/message';
import { smallNumber } from '../../contractAccount';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    bgcolor: '#fff',
    border: "1px solid rgba(0,0,0,.2);",
    boxShadow: 24,
    p: 4,
    maxWidth: "500px",
    borderRadius: "0.3rem",
    flex: '1 1 auto',
    padding: '1rem'
};

export default function ProfileWallet({ address, addressFull, handleChange, openPopup, handleOpenPopup, handleClosePopup }) {
    const [open, setOpen] = useState(false);
    const { profile ,priceCoin} = useSelector(state => state.userReducer)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [copy, setCopy] = useState(false)
    const [ruby, setRuby] = useState(0)
    const [wod, setWod] = useState(0)
    const [isSpin, setIsSpin] = useState(false)
    const inputArea = useRef(null);
    const [openS, setOpenS] = useState(false);
    // const { activate, library, connector, active, deactivate } = useWeb3React()
    const dispatch = useDispatch()
    const { account, activateBrowserWallet, deactivate } = useEthers();
    const handleClickPopup = () => {
        setOpenS(true);
        // setTimeout(() => {
        //     setOpenS(false)
        // }, 1000)
    };

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    function handleCopy(e) {
        toastSuccessMessage(`Copied!`)
        navigator.permissions
            .query({ name: "clipboard-write" })
            .then((result) => {
                if (result.state === "granted" || result.state === "prompt") {
                    updateClipboard(inputArea.current?.innerText);
                }
            });
    }
    function updateClipboard(newClip) {
        navigator.clipboard.writeText(newClip).then(
            () => {
                handleClickPopup()
                setCopy(true);
                setOpen(false)
            },
            () => {
                setCopy(false);
                // setOpen(false)

            }
        );
    }
    const providerOptions = {};
    const web3Modal = new Web3Modal({
        cacheProvider: false, // optional
        disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
    });

    async function disconnect() {
        await deactivate()
        localStorage.removeItem("wallet")
        dispatch(profileAction({}))
    }
    useEffect(async () => {
        setIsSpin(true)
        const contract = await contractServices()
        const rubyQuery = contract.getRubyBalance(contract.currentUserAddress)
        const wodQuery = contract.getWODBalance(contract.currentUserAddress)
        const [ruby, wod] = await Promise.all([rubyQuery, wodQuery])
        setIsSpin(false)
        
        setRuby(smallNumber(ruby))
        setWod(smallNumber(wod))
    }, [])
    return (
        <>
            {/* <Snackbar open={openS} autoHideDuration={1000} onClose={handleClose}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    Copied!
                </Alert>
            </Snackbar> */}

            <a style={{
                color: "#f84c6c"
            }} onClick={handleOpen}>{address}</a>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="box">

                    <Typography id="modal-modal-title" onClick={handleCopy} sx={{
                        // textAlign: "center"
                        textAlign: "center",

                    }} variant="h6" component="h2">
                        <div>
                            {address}
                            <ContentCopyIcon className="ml-2" sx={{
                                cursor: 'pointer;'
                            }} />
                            <div ref={inputArea} style={{ display: "none" }}>

                                {addressFull}
                            </div>
                        </div>
                    </Typography>
                    <div className='formBalance'>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            width:"100%"
                        }}>
                            <img src={Ruby} height={30} />
                            &nbsp;
                            <WithSpin isSpin={isSpin}>
                                {truncate(ruby, 2)}
                            </WithSpin>
                            <p className='usdPriceYellow'>
                               ~ $&nbsp;{truncate(ruby*priceCoin.ruby, 2)}
                            </p>
                        </div>

                    </div>
                    <div className='formBalance'>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            width:"100%"
                        }}>
                            <img src={Wod} height={30} />
                            &nbsp;
                            <WithSpin isSpin={isSpin}>
                                {truncate(wod, 2)}
                            </WithSpin>
                            <p className='usdPriceYellow'>
                               ~ $&nbsp;{truncate(wod*priceCoin.wod, 2)}
                            </p>
                        </div>
                    </div>
                    <Typography id="modal-modal-description" sx={{
                        display:"flex",
                        justifyContent:"space-between"
                    }} className="connect-wallet-wrap my-4" component="div">
                        {/* <Metamask/>

                        <Binance/> */}
                        <a href={`https://www.bscscan.com/address/${addressFull}`} target="_blank" style={{ color: "#212529" }} className="buttonDetail btnVang connect-wallet-item " component="div">
                            {/* <img src={bnb} width={40} height={40} alt="" /> */}
                            <img src={view} alt="" />
                            <div>View</div>
                        </a>
                        <Typography className="connect-wallet-item btnSiring buttonBuy" onClick={() => {
                            handleClose()
                            handleChange(true)
                            handleOpenPopup()
                        }} component="div">
                            {/* <img src={bnb} width={40} height={40} alt="" /> */}
                            {/* <SwapHorizIcon /> */}
                            <img src={change} alt="" />
                            <div style={{
                                color:"rgb(33, 37, 41)"
                            }}>Change</div>
                        </Typography>
                        <Typography className="connect-wallet-item buttonBuy btnDo" onClick={disconnect} component="div">
                            {/* <img src={bnb} width={40} height={40} alt="" /> */}
                            {/* <LogoutIcon /> */}
                            <img src={exit} alt="" />
                            <div style={{
                                color:"rgb(33, 37, 41)"
                            }}>Logout</div>
                        </Typography>
                    </Typography>
                </Box>
            </Modal>
        </>
    );
}
