import * as React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Skeleton from '@mui/material/Skeleton';

function LoadingDetail(props) {
    const array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    const { loading = true } = props;
    return (
        <>
            <div className="containerCard ">
                <Card sx={{ width: "100%", m: 2 }}>
                    <CardHeader
                        sx={{ display: "block" }}
                        avatar={
                            <Skeleton animation="wave" variant="circular" sx={{
                                textAlign: "center",
                                margin: "auto"
                            }} width={250} height={250} />
                        }
                        action={
                            null
                        }

                    />
                    {/* <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" /> */}
                </Card>
            </div>
            <div className="container detailDoge">
                <div className='row my-3'>
                    <div className="col-12 col-lg-6">
                        <Skeleton sx={{ height: 10, width: 200 }} animation="wave" variant="rectangular" />
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className='float-right'>
                            <Skeleton sx={{ height: 10, width: 200 }} animation="wave" variant="rectangular" />
                            <Skeleton sx={{ height: 10, width: 200, marginTop: "10px" }} animation="wave" variant="rectangular" />
                        </div>
                    </div>
                </div>
                <div className='row my-3'>
                    <Skeleton sx={{ height: 100, width: "100%" }} animation="wave" variant="rectangular" />
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <Skeleton sx={{ height: 10, width: 200 }} animation="wave" variant="rectangular" />
                        <div className="containerCard " style={{ justifyContent: "inherit" }}>
                            <Skeleton sx={{ height: 200, width: 175, margin: "10px",borderRadius:"30px" }} animation="wave" variant="rectangular" />
                            <Skeleton sx={{ height: 200, width: 175, margin: "10px" ,borderRadius:"30px"}} animation="wave" variant="rectangular" />
                            <Skeleton sx={{ height: 200, width: 175, margin: "10px" ,borderRadius:"30px"}} animation="wave" variant="rectangular" />
                            <Skeleton sx={{ height: 200, width: 175, margin: "10px" ,borderRadius:"30px"}} animation="wave" variant="rectangular" />
                        </div>
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <Skeleton sx={{ height: 10, width: 200 }} animation="wave" variant="rectangular" />
                        <div className="containerCard " style={{ justifyContent: "inherit" }}>
                            <Skeleton sx={{ height: 200, width: 175, margin: "10px",borderRadius:"30px" }} animation="wave" variant="rectangular" />
                            <Skeleton sx={{ height: 200, width: 175, margin: "10px" ,borderRadius:"30px"}} animation="wave" variant="rectangular" />
                            <Skeleton sx={{ height: 200, width: 175, margin: "10px" ,borderRadius:"30px"}} animation="wave" variant="rectangular" />
                            <Skeleton sx={{ height: 200, width: 175, margin: "10px" ,borderRadius:"30px"}} animation="wave" variant="rectangular" />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default LoadingDetail;