export const convertTimeToString = (doge) => {
    doge.birthTime = new Date(doge.birthTime * 1000)
    let day = doge.birthTime.getDate();
    let month = doge.birthTime.getMonth() + 1;
    let year = doge.birthTime.getFullYear();
    var hours = doge.birthTime.getHours();
    var minutes = doge.birthTime.getMinutes();
    var seconds = doge.birthTime.getSeconds();
    doge.birthTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
}
 export const decimalAdjust = (type, value, exp) =>{
    // If the exp is undefined or zero...
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN;
    }
    // If the value is negative...
    if (value < 0) {
      return -decimalAdjust(type, -value, exp);
    }
    // Shift
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
  }
export const convertTimeToBirthDay = (birthTime) => {
    let time = new Date(birthTime * 1000)
    var date = time
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    var d = date.getDate();
    m = (m < 10) ? '0' + m : m;
    d = (d < 10) ? '0' + d : d;
    return [y, m, d].join('-');
}
