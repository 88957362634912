import { Empty } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CardHero from '../../Component/Card/CardHero';
import FiltersAttack from '../../Component/FiltersAttack';
import HistoryAttackDefense from '../../Component/HistoryAttackAll';
import PaginationCustom from '../../Component/PaginationCustom';
import WithLoading from '../../HOC/WithLoading';
import { getAllHeros } from '../../Redux/action/api/dogeReducer';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import banner from '../../asset/images/banner.jpg'
import StatisticalAttack from '../../Component/StatisticalAttack'
import CurrentAttack from '../../Component/CurrentAttack';
import RecentlyAttack from '../../Component/RecentlyAttack';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function HavestArea(props) {
    const dispatch = useDispatch()
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [limit, setLimit] = useState(12)
    const [page, setPage] = useState(1)
    const [checkBox, setCheckBox] = useState(false)
    const [checkBoxMyHero, setCheckBoxMyHero] = useState(false)
    const { allHeros } = useSelector(state => state.dogesApiReducer)
    const { whereListHero } = useSelector(state => state.userReducer)

    function funcPagination(limit, page) {
        setLimit(limit)
        setPage(page)
    }
    const elementHero = () => {

    }
    const [isLoading, setIsLoading] = useState(true)
    // useEffect(async () => {
    //     setInterval(async () => {
    //         const wallet = JSON.parse(localStorage.getItem('wallet'))
    //         if (checkBox && wallet) {
    //             await dispatch(getAllHeros({ limit, page, owner: wallet.address }))
    //         } else {
    //             await dispatch(getAllHeros({ limit, page }))
    //         }
    //     }, 20000)
    // }, [])
    useEffect(async () => {
        window.scrollTo(0, 0);
        setIsLoading(true)
        const wallet = JSON.parse(localStorage.getItem('wallet'))
        if (checkBox && wallet) {
            await dispatch(getAllHeros({ limit, page, owner: wallet.address, option: whereListHero }))
        } else if (checkBoxMyHero && wallet) {
            await dispatch(getAllHeros({ limit, page, where: `owner='${wallet.address}'` }))
        } else {
            await dispatch(getAllHeros({ limit, page, option: whereListHero }))
        }

        setIsLoading(false)
    }, [limit, page, checkBox, whereListHero, checkBoxMyHero])
    const LoadingHero = WithLoading(LoadingHeroComponent)
    function LoadingHeroComponent() {
        return (
            <>
                {allHeros?.array?.length <= 0 ? <Empty /> : allHeros?.array?.map((item, index) => {
                    return <CardHero item={item} />
                    // if (!checkBox) return <CardHero item={item} />
                    // if (item.attackableHeros) return <CardHero item={item} />
                })}
            </>
        )
    }
    return (
        <>
            <div>
                <img src={banner} alt="" width="100%" height="100%" />
            </div>
            <div className='main layerbg pb-3'
            >
                <FiltersAttack checkBox={checkBox} checkBoxMyHero={checkBoxMyHero} setCheckBox={setCheckBox} limit={limit} page={page} setCheckBoxMyHero={setCheckBoxMyHero} />
                <div className='containerCard'>
                    <LoadingHero isLoading={isLoading} />
                </div>
                <PaginationCustom limit={limit} page={page} total={allHeros?.total} color="#fff" funcPagination={funcPagination} />
            </div>
            <div className='main pb-3'
                style={{
                    width: "90%",
                    margin: "auto"
                }}
            >
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} className="treee" aria-label="basic tabs example">
                            <Tab label="Recently Attack" {...a11yProps(0)} />
                            <Tab label="Last Top 10" {...a11yProps(1)} />
                            <Tab label="Current Top 10" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        {value == 0 ?
                            <StatisticalAttack valueTab={value} />
                            :
                            ""
                        }
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {value == 1 ?
                            <RecentlyAttack valueTab={value} />
                            :
                            ""
                        }

                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        {value == 2 ?
                            <CurrentAttack valueTab={value} />
                            :
                            ""
                        }
                    </TabPanel>
                </Box>
            </div>


        </>
    );
}

export default HavestArea;