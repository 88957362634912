import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { InputLabel, MenuItem, Select, TextField } from '@mui/material';
import './style.scss'
import Contract from '../../../../contract';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { toastErrorMessage, toastSuccessMessage } from '../../../../commons/message';
import { bigNumber, truncate } from '../../../../commons/blockchain';
import { useDispatch } from 'react-redux';
import { getMyDogesAction } from '../../../../Redux/action/api/dogeReducer';
import { myDogeDispatch } from '../../../../Redux/dogeSlice';
import WithSpin from '../../../../HOC/WithSpin';
import { getMyDogesActionFunc } from '../../../../Redux/action/api/dogeReducer';
import { contractServices } from '../../../../Services/contract';
import { useEffect } from 'react';
import { openPopupConnectAction } from '../../../../Redux/userSlice';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PopupTransfer({ open, handleClickOpen, handleClose }) {
    const [age, setAge] = React.useState(0);
    const [startPrice, setStartPrice] = useState(``)
    const [endPrice, setEndPrice] = useState(0)
    const [feePercent, setFeePercent] = useState(0)
    const [err,setErr] = useState("")
    const [isSpin, setIsSpin] = useState(false)
    const dispatch = useDispatch()
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    async function transfer() {
        try {
            const wallet = JSON.parse(localStorage.getItem('wallet'))
            if (!wallet) return dispatch(openPopupConnectAction(true))
            setIsSpin(true)
            const contract = await contractServices()
            const approved = await contract.transferFrom(contract.currentUserAddress,startPrice,open?.doge?.dogeId)
            setIsSpin(false)
            toastSuccessMessage(`Transfer doge success`)
            handleClose()
            // await dispatch(myDogeDispatch())
            // await dispatch(getMyDogesAction({ limit: 12, page: 1 }))
            window.location.reload()
            setStartPrice(``)
            setEndPrice(0)
            setAge(0)
        } catch (error) {
            console.log(error);
            toastErrorMessage(`Invalid address`)
            setIsSpin(false)
        }
    }
    useEffect(async () => {
        const wallet = JSON.parse(localStorage.getItem('wallet'))
        if (wallet) {
            const contract = await contractServices()
            const feeLimit = await contract.ownerCut()
            setFeePercent(feeLimit / 100)
        }


    }, [])
    return (
        <div>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Slide in alert dialog
            </Button> */}
            <Dialog
                open={open.flag}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{`Do you want to transfer doge #${open?.doge?.dogeId}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {/* <InputLabel id="demo-simple-select-label">Starting Price (BNB)</InputLabel> */}
                        <TextField type="text" id="outlined-basic" value={startPrice} label="Address" className="my-3" fullWidth={100} variant="outlined" onChange={(e) => {
                            let value = e.target.value
                            setStartPrice(value)
                        }} />
                   
                 

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isSpin} onClick={transfer} className="btnBuyNow" sx={{
                        width:"284px",
                        color:"white",
                        outline:"none"
                    }} >
                        <WithSpin isSpin={isSpin}>
                            Transfer
                        </WithSpin>
                    </Button>


                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
