import React from 'react';
import { NavLink } from 'react-router-dom';
import { strWallet, truncate } from '../../../commons/blockchain';
import Ruby from '../../../asset/images/ruby.png'
import Top1 from '../../../asset/photoshop/top1.jpg'
import Top1Mobi from '../../../asset/photoshop/top1Mobi.png'
import Top2 from '../../../asset/photoshop/top2.jpg'
import Top2Mobi from '../../../asset/photoshop/top2Mobi.png'
import Top3 from '../../../asset/photoshop/top3.jpg'
import Top3Mobi from '../../../asset/photoshop/top3Mobi.png'
import { Empty } from 'antd';
import { useSelector } from 'react-redux';
function StatisticalHistoryAttackAll({ arrayTitle, arrayData, title }) {
    const elementTitle = arrayTitle?.map((itemTitle, index) => { return <th className='tdCenter' key={index}>{itemTitle.title}</th> })
    const { priceCoin, profile } = useSelector(state => state.userReducer)
   
    const priceRuby = priceCoin.ruby
    const elementData = arrayData?.map((item, index) => {

        let src = false
        if (index + 1 == 1 && window.innerWidth > 450) {
            src = Top1
        } else if (index + 1 == 2 && window.innerWidth > 450) {
            src = Top2
        } else if (index + 1 == 3 && window.innerWidth > 450) {
            src = Top3
        } else if (index + 1 == 1 && window.innerWidth <= 450) {
            src = Top1Mobi
        } else if (index + 1 == 2 && window.innerWidth <= 450) {
            src = Top2Mobi
        } else if (index + 1 == 3 && window.innerWidth <= 450) {
            src = Top3Mobi
        }
        if (index < 10) {
            return <tr>
                <td className='tdCenter tdAttack' style={src ? {
                    display: "flex",
                    justifyContent: "center"
                } : {}}>
                    <p className={src ? 'm-0 topAttack' : 'm-0'} style={src ?
                        {
                            backgroundImage: `url(${src})`,
                            width: "39px",
                            height: "38px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                        } : {}
                    }>
                        {index + 1}
                    </p>
                </td>
                <td className='tdCenter tdAttack'>
                    <NavLink to={`/hero/${item.attacker}`}>{strWallet(item.attacker)}</NavLink>
                </td>
                <td className='tdCenter tdAttack'>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <img src={Ruby} height={35} />
                        <div className='ml-1 text-left'>
                            <b className='m-0'>       {truncate(item.reward, 0)}</b>
                            <br></br>
                            <span className='USD'>$ {truncate(item.reward * priceRuby, 2)}</span>
                        </div>
                    </div>
                </td>
            </tr>
        }
    })
    return (
        <div className="card">
            <div className="card-header">
                <h3
                    className="card-title fzTitle"
                    style={{ color: "black !important" }}
                >
                    {title}
                </h3>
            </div>
            {/* /.card-header */}
            <div className="card-body table-responsive p-0">
                <table className="table table-hover text-nowrap">
                    <thead>
                        <tr>{elementTitle}</tr>
                    </thead>

                    <tbody>

                        {elementData}
                    </tbody>
                </table>
                {arrayData?.length <= 0 ?
                    <>
                        <Empty />
                    </> :
                    <></>
                }

            </div>
        </div>
    );
}

export default StatisticalHistoryAttackAll;