import React from 'react';

function Dashborad(props) {
    return (
        <div>
            asdasd
        </div>
    );
}

export default Dashborad;