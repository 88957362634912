import { Empty } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WithLoading from '../../HOC/WithLoading';
import { getAllSellingDoges } from '../../Redux/action/api/dogeReducer';
import { whereListAllSellAction, whereListAllSellDrawAction } from '../../Redux/userSlice';
import PaginationCustom from '../PaginationCustom';
import CardItem from './CardItem';
import PopupBuy from './CardItem/PopupBuy';
import './style.scss';
// import avatarDoge12 from '../../asset/doge_images/Untitled_Artwork-12.png'
function Card(props) {
    const tilt = useRef(null);
    const [limit, setLimit] = useState(12)
    const [page, setPage] = useState(1)
    const [isLoading, setIsLoading] = useState(true)
    const dispatch = useDispatch()
    const { listMartketPlace, whereListAllSell } = useSelector(state => state.userReducer)

    const ListWithLoading = WithLoading(ListCard);
    const [openSell, setOpenSell] = useState({
        flag: false,
        doge: {},
        price: 0
    })
    const handleClickOpen = async (doge, price) => {
        setOpenSell({
            flag: true,
            doge,
            price: price
        });
    };

    const handleClose = () => {
        setOpenSell({
            flag: false
        });
    };
    function funcPagination(limit, page) {
        setLimit(limit)
        setPage(page)
    }
    useEffect(async () => {
        // const arrayDoge = [{ address: "Adasd" }, { address: "Adasd" }, { address: "Adasd" }, { address: "Adasd" }, { address: "Adasd" }, { address: "Adasd" }, { address: "Adasd" }, { address: "Adasd" }]
        // for await (let doge of arrayDoge){
        //     doge.image = await mergeImages([
        //         { src: avatarDoge1, x: 0, y: 0 },
        //         { src: avatarDoge2, x: 0, y: 0 },
        //         { src: avatarDoge3, x: 0, y: 0 },
        //         { src: avatarDoge4, x: 0, y: 0 },
        //         { src: avatarDoge5, x: 0, y: 0 },
        //         { src: avatarDoge6, x: 0, y: 0 },
        //         { src: avatarDoge7, x: 0, y: 0 },
        //         { src: avatarDoge8, x: 0, y: 0 },
        //         { src: avatarDoge9, x: 0, y: 0 },
        //         { src: avatarDoge10, x: 0, y: 0 },
        //         { src: avatarDoge11, x: 0, y: 0 },
        //     ])
        // }
        // dispatch(marketPlaceAction(arrayDoge))
        // window.scrollTo(0, 0);
        setIsLoading(true)
        const option = `${whereListAllSell.drawFilter.length > 0 ? whereListAllSell.drawFilter : ``} ${whereListAllSell.filter} `
        // const optionFilter = JSON.stringify(localStorage.getItem('optionFilter'))
        // console.log(whereListAllSell.filter,"fififif")
        await dispatch(getAllSellingDoges({ limit, page, option: option }))

        setIsLoading(false)


    }, [limit, page, whereListAllSell])
    function ListCard({ listMartketPlace }) {
        if (listMartketPlace.length <= 0) return <Empty />
        return (
            listMartketPlace?.map((item, index) => {
                return (
                    <CardItem item={item} handleClickOpen={handleClickOpen} />
                )
            })
        )
    }
    return (
        <>
            <div className='containerCard'>
                {/* <LoadingCard /> */}
                <ListWithLoading isLoading={isLoading} listMartketPlace={listMartketPlace?.array} />
                <PopupBuy handleClose={handleClose} handleClickOpen={handleClickOpen} doge={openSell.doge} price={openSell.price} open={openSell} />
            </div>
            <PaginationCustom funcPagination={funcPagination} page={page} limit={limit} total={listMartketPlace?.total} color="white" />
        </>

    );
}
export default Card;