import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import bnb from '../../../asset/images/logobnb.png'
import { changeNetwork } from '../../../commons/blockchain';
import { messageError } from '../../../commons/message';
import { connectWallet } from '../../../Redux/action/userReducer';
let currentAccount = null;
function Binance({handleClose}) {
    const dispatch = useDispatch()
    async function hanldeOnlick(e) {
        try {
            let BinanceChain = window.BinanceChain
            e.preventDefault()
            if (!BinanceChain) {
                window.alert('Please install Binance Chain Wallet.');
                return;
            }
            await changeNetwork(BinanceChain)

            const accounts = await BinanceChain.request({ method: 'eth_accounts' })
            if (accounts.length === 0) {
                messageError('Please connect to Binance Chain Wallet.')
            } else if (accounts[0] !== currentAccount) {
                currentAccount = accounts[0];
            }
            window.location.reload()
            localStorage.setItem('wallet',JSON.stringify({ address : currentAccount,wallet:"binance" }))
            setTimeout(() => {
                localStorage.removeItem('wallet')
            }, 60000 * 300)
            dispatch(connectWallet({ address: currentAccount,wallet:"binance" }))
            handleClose()
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <>
            <div className="connect-wallet-item" onClick={hanldeOnlick} component="div">
                <img src={bnb} width={40} height={40} alt="" />
                <div>Binance Chain</div>
            </div>
        </>
    );
}

export default Binance;