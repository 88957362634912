import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';

import store from './app/store';
import {
  BrowserRouter as Router
} from 'react-router-dom'
import Web3 from 'web3'
import {
  Web3ReactProvider
} from '@web3-react/core'
function getLibrary(provider) {
  return new Web3(provider)
}
ReactDOM.render(
  <React.StrictMode>

    <Web3ReactProvider getLibrary={getLibrary} >
      <Provider store={store}>
        <Router>
         
        
        
          {/* Same as */}
          <App />
        </Router>
      </Provider>
    </Web3ReactProvider>
  </React.StrictMode>

  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
