import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import BNB from '../../../../asset/images/bnb.png'
import { InputLabel, MenuItem, Select, TextField } from '@mui/material';
import './style.scss'
import Contract from '../../../../contract';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { toastErrorMessage, toastSuccessMessage } from '../../../../commons/message';
import { bigNumber, truncate } from '../../../../commons/blockchain';
import { useDispatch, useSelector } from 'react-redux';
import { getMyDogesAction } from '../../../../Redux/action/api/dogeReducer';
import { myDogeDispatch } from '../../../../Redux/dogeSlice';
import WithSpin from '../../../../HOC/WithSpin';
import { getMyDogesActionFunc } from '../../../../Redux/action/api/dogeReducer';
import { contractServices } from '../../../../Services/contract';
import { useEffect } from 'react';
import { openPopupConnectAction } from '../../../../Redux/userSlice';
import { port } from '../../../../port';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PopupSell({ open, handleClickOpen, handleClose }) {
    const [age, setAge] = React.useState(0);
    const [startPrice, setStartPrice] = useState(0)
    const [endPrice, setEndPrice] = useState(0)
    const [feePercent, setFeePercent] = useState(0)
    const { priceCoin } = useSelector(state => state.userReducer)
    const [err, setErr] = useState("")
    const [isSpin, setIsSpin] = useState(false)
    const dispatch = useDispatch()
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    async function sellDoge() {
        try {
            const wallet = JSON.parse(localStorage.getItem('wallet'))
            if (!wallet) return dispatch(openPopupConnectAction(true))
            setIsSpin(true)
            const contract = await contractServices()
            const approved = await contract.checkApproveToSellDoge(contract.currentUserAddress)
            const doge = open.doge
            const dogeId = doge.dogeId
            if (startPrice <= 0) {
                setIsSpin(false)
                return toastErrorMessage(`Invalid price`)
            }


            if (open.siring) {
                ////

                if (!approved) {
                    //approve
                    await contract.approveToSireDoge()
                }
            }
            const startingPrice = bigNumber(`${startPrice}`) //1 BNB
            if (open.siring) await contract.createSiringAuction(dogeId, startingPrice)
            else await contract.sellDoge(dogeId, startingPrice)
            setIsSpin(false)
            toastSuccessMessage(`${open.siring ? `Successful Doge rental` : `Successfully sold Doge`}`)
            handleClose()
            // await dispatch(myDogeDispatch())
            // await dispatch(getMyDogesAction({ limit: 12, page: 1 }))
            window.location.reload()
            setStartPrice(0)
            setEndPrice(0)
            setAge(0)
        } catch (error) {
            console.log(error);
            toastErrorMessage(`The transaction has been declined`)
            setIsSpin(false)
        }
    }
    async function siringDoge() {

    }
    useEffect(async () => {
        const wallet = JSON.parse(localStorage.getItem('wallet'))
        if (wallet) {
            const contract = await contractServices()
            const feeLimit = await contract.ownerCut()
            setFeePercent(feeLimit / 100)
        }


    }, [])
    return (
        <div>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Slide in alert dialog
            </Button> */}
            <Dialog
                open={open.flag}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                {/* <DialogTitle>{`Do you want to ${open.siring ? "siring" : "sell"} doge #${open?.doge?.dogeId}`}</DialogTitle> */}
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div style={{
                            background: `${open?.doge?.background}`,
                            width: "100%",
                            height: "260px",
                            borderRadius: "5px",
                            margin:"auto"
                        }}>
                            <img src={`${port}${open?.doge?.image}`} className='imgBuy top25'  alt="" />
                        </div>
                        {/* <p>Here's a summary of your purchase:</p> */}
                        {/* <InputLabel id="demo-simple-select-label">Starting Price (BNB)</InputLabel> */}
                        <p className='text-center my-2'>
                            {/* Doge&nbsp; */}
                            <b>{`Do you want to ${open.siring ? "siring" : "sell"} doge #${open?.doge?.dogeId}`}</b>
                        </p>
                        {/* <div className="formSliver">
                            <div className="textPrice" style={{
                                color: "#fac228"
                            }}>
                                Price
                            </div>
                            <div className='priceGroupUSD'>
                                <b class="priceBNB">
                                    <img src={BNB} height={20} />&nbsp;{truncate(open?.doge?.price, 6)}
                                </b>
                                <p className='USDPrice'>~&nbsp;$ {truncate(open?.doge?.price * priceCoin.BNB, 2)}</p>
                            </div>
                        </div> */}
                        {/* ///////////////////// */}
                        {/* <InputLabel id="demo-simple-select-label">Starting Price (BNB)</InputLabel> */}
                        <TextField type="text" id="outlined-basic" value={startPrice} label="Price (BNB)" className="my-3" fullWidth={100} variant="outlined" onChange={(e) => {
                            let value = e.target.value
                            if (value < 0.0001 && value != 0.) return setErr(`You cannot enter less than 0.0001`)
                            if (!isNaN(value)) {
                                setStartPrice(value)
                                setErr("")
                            }
                        }} />
                        {err ? <>
                            <p className='text-danger'>{err}</p>
                        </> : <></>}
                        <div className='boxFee '>
                            <p style={{
                                margin:"0px"
                            }}>
                                Fee ({feePercent}%)
                            </p>
                            <b style={{
                                margin:"0px"
                            }}>
                                {truncate(startPrice * (feePercent / 100), 12)} BNB
                            </b>
                        </div>
                        <hr></hr>
                        <div className='boxFee'>
                            <p style={{
                                margin:"0px"
                            }}> You get</p>
                            <b style={{
                                margin:"0px"
                            }}> {truncate(startPrice - startPrice * (feePercent / 100), 12)} BNB</b>

                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isSpin} className="btnBuyNow" sx={{
                        width:"284px",
                        color:"white",
                        outline:"none"
                    }} onClick={sellDoge}>
                        <WithSpin isSpin={isSpin} >
                            {`${open.siring ? "Siring" : "Sell"}`}
                        </WithSpin>
                    </Button>


                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
