// import HDWalletProvider from '@truffle/hdwallet-provider'
import Web3 from 'web3'
import BN from 'bn.js'
import DogeWar from './contracts/DogeWar.json'
import DogeWarProxy from './contracts/DogeWarProxy.json'
import Ruby from './contracts/RubyToken.json'
import RubyProxy from './contracts/RubyTokenProxy.json'
import WODToken from './contracts/WODToken.json'
import DogeHero from './contracts/DogeHero.json'
import DogeHeroProxy from './contracts/DogeHeroProxy.json'
import GeneScience from './contracts/GeneScience.json'
import SaleAuction from './contracts/SaleClockAuction.json'
import SaleAuctionProxy from './contracts/SaleClockAuctionProxy.json'
import SiringAuction from './contracts/SiringClockAuction.json'
import SiringAuctionProxy from './contracts/SiringClockAuctionProxy.json'
import AirdropProxy from './contracts/AirdropProxy.json'
import Airdrop from './contracts/WorldOfDogeAirdrop.json'
import { portNetwork } from './port'
export default class Contract {
  async loadMetaMask() {
    if (typeof window.BinanceChain !== 'undefined') {
      // return window.BinanceChain
    }
    if (typeof window.ethereum !== 'undefined') {
      //login metamask
      //const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
      //const account = accounts[0]
      return window.ethereum
    } else return null
  }
  async loadContract(provider) {
    this.AttackDogeEvent = null
    this.DepositDogesEvent = null
    this.WithdrawDogesEvent = null
    this.accounts = []
    if (!provider) await this._createWebInstance(window.ethereum)
    else await this._createWebInstance(provider)
    await this._createContractInstance()
  }

  _getCurrentNetwork() {
    let netword = portNetwork =="MAINNET"? '56' :'97'
    return netword
    if (process.env.REACT_APP_NETWORK === 'ganache') {
      return '5777'
    } else if (process.env.REACT_APP_NETWORK === 'ganache2') {
      return '1634392273290'
    } else if (process.env.REACT_APP_NETWORK === 'extdev') {
      return '9545242630824' //extdev network id
    }
    else if (process.env.REACT_APP_NETWORK === 'rinkeby') {
      return '4'
    } else if (process.env.REACT_APP_NETWORK === 'local') {
      return '13654820909954'
    } else if (process.env.REACT_APP_NETWORK === 'bsctest') {
      return '97'
    } else if (process.env.REACT_APP_NETWORK === 'bsc') {
      return '56'
    }
  }

  async getOwnerDogeHero(tokenId) {
    return await this.dogeHeroInstance.methods.ownerOf(tokenId).call()
  }
  async _createWebInstance(provider) {

    if (process.env.REACT_APP_NETWORK !== 'loom') {

      let metamaskProvider = provider
      //this.web3rinkeny = new Web3(provider)
      ///// comment
      const metamaskAccounts = await metamaskProvider.request({ method: 'eth_requestAccounts' })
      this.accounts = metamaskAccounts
      this.currentUserAddress = metamaskAccounts[0]
      this.web3 = new Web3(metamaskProvider)
    }
  }

  async _createContractInstance() {
    const networkId = this._getCurrentNetwork()
    this.currentNetwork = DogeWarProxy?.networks[networkId]
    if (!this.currentNetwork) {
      throw Error('Contract not deployed on networkId:', networkId)
    }
    this.airdrop = new this.web3.eth.Contract(Airdrop.abi, AirdropProxy.networks[networkId].address)
    this.dogeWarInstance = new this.web3.eth.Contract(DogeWar.abi, DogeWarProxy.networks[networkId].address, { from: this.currentUserAddress })
    this.dogeHeroInstance = new this.web3.eth.Contract(DogeHero.abi, DogeHeroProxy.networks[networkId].address, { from: this.currentUserAddress })
    this.ruby = new this.web3.eth.Contract(Ruby.abi, RubyProxy.networks[networkId].address, { from: this.currentUserAddress })
    this.wodToken = new this.web3.eth.Contract(WODToken.abi, WODToken.networks[networkId].address, { from: this.currentUserAddress })
    this.geneScience = new this.web3.eth.Contract(GeneScience.abi, GeneScience.networks[networkId].address, { from: this.currentUserAddress })
    this.saleAuction = new this.web3.eth.Contract(SaleAuction.abi, SaleAuctionProxy.networks[networkId].address, { from: this.currentUserAddress })
    this.siringAuction = new this.web3.eth.Contract(SiringAuction.abi, SiringAuctionProxy.networks[networkId].address, { from: this.currentUserAddress })

    /* this.dogeWarInstance.events.AttackHero({ filter: { attacker:this.currentUserAddress}}, (err, event) => {
      if(err) console.error('Error Attack Hero', err)
      else{
        if(this.AttackDogeEvent){          
          this.AttackDogeEvent(event.returnValues)
          //console.log('event FoundVillage, dogelandId:', this.dogelandId)
        } 
      }
    })

    this.dogeWarInstance.events.DepositDoges({ filter: { owner:this.currentUserAddress}}, (err, event) => {
      if(err) console.error('Error Deposit Doges', err)
      else{
        if(this.DepositDogesEvent){          
          this.DepositDogesEvent(event.returnValues)
          //console.log('event FoundVillage, dogelandId:', this.dogelandId)
        } 
      }
    })

    this.dogeWarInstance.events.WithdrawDoges({ filter: { owner:this.currentUserAddress}}, (err, event) => {
      if(err) console.error('Error Deposit Doges', err)
      else{
        if(this.WithdrawDogesEvent){          
          this.WithdrawDogesEvent(event.returnValues)
          //console.log('event FoundVillage, dogelandId:', this.dogelandId)
        } 
      }
    }) */

    /* const currentBlock = await this.web3.eth.getBlockNumber()
    console.log(currentBlock) */
    /* const eventsWatch = this.dogeWarInstance.events.allEvents();
    eventsWatch.watch((err, res) => {
      if (err) return;
      console.log("Event:", res.event, res.args);
    }); */
    /* this.dogeWarInstance.getPastEvents('allEvents',
    { fromBlock: 0, toBlock: 'latest' })
    .then(events => console.log("events:",events))
    .catch((err) => console.error("err",err)); */


    /* const startBlock = 15522911
    const endBlock = 15523911

    for(let i = startBlock; i < endBlock; i += 5000){
      const _startBlock = i;
      const _endBlock = Math.min(endBlock, i + 4999);
      const events = await this.dogeWarInstance.getPastEvents('AttackDoge', 
      { fromBlock: _startBlock, toBlock: _endBlock })
      console.log(`events:`, events)
    } */


    // .then(events => console.log("events:",events))
    // .catch((err) => console.error("err",err));

  }
  random(min, max) {//random from min and max (both included)
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  createTrait(min, max) {
    let traits = []
    for (let i = 0; i < 48; i++) {
      const trait = this.random(min, max)
      traits.push(trait)
    }
    return traits
  }
  //dogewar function





  async getHeroToken(owner) {
    const doges = await this.dogeWarInstance.methods.getDoges(owner).call()
    if (doges.length > 0) {

      for (let i = 0; i < doges.length; i++) {
      }
    }
    return doges
  }
  async getHeroToken2(owner) {
    const balance = await this.dogeHeroInstance.methods.balanceOf(owner).call()
    const bal = parseInt(balance)
    const tokens = []
    if (bal > 0) {
      for (let i = 0; i < bal; i++) {
        const token = await this.dogeHeroInstance.methods.tokenOfOwnerByIndex(owner, i).call()
        tokens.push(token)
      }
    }
    return tokens
  }
  async mintHero(to) {
    let traits = this.createTrait(0, 15)
    const gene = await this.geneScience.methods.encode(traits).call()
    await this.dogeHeroInstance.methods.createPromoDoge(gene, to).send()
  }
  async harvest() {
    await this.dogeWarInstance.methods.harvestReward().send()
  }

  async getPendingReward(owner) {
    return await this.dogeWarInstance.methods.pendingReward().call({ from: owner })
  }

  async mintHeroes(amount, to) {
    /* for(let i=0; i < amount; i++){
        let traits = this.createTrait(0, 15)
        const dadGene = await this.geneScience.methods.encode(traits).call()
        await this.dogeHeroInstance.methods.createPromoDoge(dadGene, to).send()
        console.log(`created ${i+1} token for ${to}`)
      }  */
  }
  /* async getInstanceAddress(){
    const dogeWarInstanceAddress = this.dogeWarInstance._address
    const dogeHeroInstanceAddress = this.dogeHeroInstance._address
    return (dogeWarInstanceAddress, dogeHeroInstanceAddress)
  } */
  async approveToSellDoge() {
    return await this.dogeHeroInstance.methods.setApprovalForAll(this.saleAuction._address, true).send()
  }
  async checkApproveToSellDoge(owner) {
    return await this.dogeHeroInstance.methods.isApprovedForAll(owner, this.saleAuction._address).call()
  }
  async sellDoge(dogeId, price) {
    return await this.dogeHeroInstance.methods.createSaleAuction(dogeId, price).send()
  }
  async transferFrom(owner, addressTo, tokenId) {
    return await this.dogeHeroInstance.methods.transferFrom(owner, addressTo, tokenId).send()
  }
  async ownerCut() {
    return await this.saleAuction.methods.ownerCut().call()
  }
  async getWODReward(){
    return await this.airdrop.methods.wod(this.currentUserAddress).call()
  }
  async getDogeReward(){
    return await this.airdrop.methods.doges(this.currentUserAddress).call()
  }
  async claimWOD(){
    return await this.airdrop.methods.claimWOD().send({from: this.currentUserAddress})
  }
  async claimDoge(){
    return await this.airdrop.methods.claimDoge().send({from: this.currentUserAddress})
  }
  async getSellingDoges(owner) {
    const balance = await this.saleAuction.methods.balanceOf(owner).call()
    const doges = []
    if (parseInt(balance) > 0) {
      for (let i = 0; i < parseInt(balance); i++) {
        const tokenId = await this.saleAuction.methods.tokenOfOwnerByIndex(owner, i).call()
        doges.push(tokenId)
      }
    }
    return doges
  }
  async getSellingDogeInfo(tokenId) {
    return await this.saleAuction.methods.getAuction(tokenId).call()
  }
  async getCurrentSellingPrice(tokenId) {
    return await this.saleAuction.methods.getCurrentPrice(tokenId).call()
  }
  async cancelSelling(tokenId) {
    return await this.saleAuction.methods.cancelAuction(tokenId).send()
  }
  async buyDoge(dogeId, price) {
    return await this.saleAuction.methods.bid(dogeId).send({ value: price })
  }
  async getAllSellingDoges() {
    //get balance of saleAuction
    const balance = await this.dogeHeroInstance.methods.balanceOf(this.saleAuction._address).call()
    const bal = parseInt(balance)
    const doges = []
    if (bal > 0) {
      //get dogeId
      for (let i = 0; i < bal; i++) {
        const doge = await this.dogeHeroInstance.methods.tokenOfOwnerByIndex(this.saleAuction._address, i).call()
        doges.push(doge)
      }
    }
    return doges
  }

  //siring functions
  async approveToSireDoge() {
    return await this.dogeHeroInstance.methods.setApprovalForAll(this.siringAuction._address, true).send()
  }
  async checkApproveToSireDoge(owner) {
    return await this.dogeHeroInstance.methods.isApprovedForAll(owner, this.siringAuction._address).call()
  }
  async createSiringAuction(dogeId, price) {
    return await this.dogeHeroInstance.methods.createSiringAuction(dogeId, price).send()
  }

  async getSiringDoges(owner) {
    const balance = await this.siringAuction.methods.balanceOf(owner).call()
    const doges = []
    if (parseInt(balance) > 0) {
      for (let i = 0; i < parseInt(balance); i++) {
        const tokenId = await this.siringAuction.methods.tokenOfOwnerByIndex(owner, i).call()
        doges.push(tokenId)
      }
    }
    return doges
  }
  async getSiringDogeInfo(tokenId) {
    return await this.siringAuction.methods.getAuction(tokenId).call()
  }
  async getCurrentSiringPrice(tokenId) {
    return await this.siringAuction.methods.getCurrentPrice(tokenId).call()
  }
  async cancelSiring(tokenId) {
    return await this.siringAuction.methods.cancelAuction(tokenId).send()
  }
  async buySiringAuction(sireId, matronId, price) {
    return await this.dogeHeroInstance.methods.bidOnSiringAuction(sireId, matronId).send({ value: price })
  }
  async getAllSiringDoges() {
    //get balance of saleAuction
    const balance = await this.dogeHeroInstance.methods.balanceOf(this.siringAuction._address).call()
    const bal = parseInt(balance)
    const doges = []
    if (bal > 0) {
      //get dogeId
      for (let i = 0; i < bal; i++) {
        const doge = await this.dogeHeroInstance.methods.tokenOfOwnerByIndex(this.siringAuction._address, i).call()
        doges.push(doge)
      }
    }
    return doges
  }
  /**
   * @dev phí Ruby cho mỗi lần phối giống
   * @param cooldownIndex mỗi doge có 1 chỉ số cooldownIndex (từ 0 đến 13) , lấy chỉ số này bằng cách getDoge
   * @returns một phần tử trong mảng bên dưới
   * rubyFees = [
        bigNumber(1600),
        bigNumber(2400),
        bigNumber(4000),
        bigNumber(6400),
        bigNumber(10400),
        bigNumber(16800),
        bigNumber(27200),
        bigNumber(44000),
        bigNumber(71200),
        bigNumber(115200),
        bigNumber(186400),
        bigNumber(301600),
        bigNumber(488000),
        bigNumber(789600)
    ]
   */
  async breedingRubyFee(cooldownIndex) {
    return await this.dogeHeroInstance.methods.rubyFees(cooldownIndex).call()
  }

  /**
   * phí WOD cho mỗi lần phối giống
   * @param cooldownIndex mỗi doge có 1 chỉ số cooldownIndex (từ 0 đến 13) , lấy chỉ số này bằng cách getDoge
   * @returns một phần tử trong mảng bên dưới
   * wodFees = [
        bigNumber(50),
        bigNumber(50),
        bigNumber(50),
        bigNumber(50),
        bigNumber(50),
        bigNumber(50),
        bigNumber(50),
        bigNumber(50),
        bigNumber(50),
        bigNumber(50),
        bigNumber(50),
        bigNumber(50),
        bigNumber(50),
        bigNumber(50)
    ]
   */
  async breedingWODFee(cooldownIndex) {
    return await this.dogeHeroInstance.methods.wodFees(cooldownIndex).call()
  }
  /**
   * sau khi phối giống, chó sẽ mất 1 khoảng thời gian nghỉ ngơi rồi mới phối giống tiếp
   * @param {*} cooldownIndex có giá trị từ 0 đến 13
   * @returns thời gian tính bằng giây
   */
  async cooldowns(cooldownIndex) {
    return await this.dogeHeroInstance.methods.cooldowns(cooldownIndex).call()
  }
  //autoBirthFee: phí BNB mà bất kì ai gọi hàm giveBirth sẽ nhận được
  //giveBirth: doge đang mang thai và đã đủ thời gian để sinh, bất kì ai gọi hàm này tạo ra doge mới
  //người gọi hàm này sẽ nhận được BNB tương ứng số BNB mà người đó trả để thực hiện transaction
  async autoBirthFee() {
    //hiện tại autoBirthFee = 0.002 BNB
    return await this.dogeHeroInstance.methods.autoBirthFee().call()
  }
  async giveBirth(matronId) {
    return await this.dogeHeroInstance.methods.giveBirth(matronId).send()
  }
  async getDoge(dogeId) {
    return await this.dogeHeroInstance.methods.getDoge(dogeId).call()
  }
  async getRubyBalance(owner) {
    return await this.ruby.methods.balanceOf(owner).call()
  }
  async getWODBalance(owner) {
    return await this.wodToken.methods.balanceOf(owner).call()
  }
  //breedWithAuto: cho 2 chó của mình phối giống
  //điều kiện: phải sở hữu cả 2 con
  //BNB balance phải đủ để trả autoBirthFee
  //Balance Ruby và WOD phải đủ để trả phí sinh sản
  async breedWithAuto(matronId, sireId, fee) {
    return await this.dogeHeroInstance.methods.breedWithAuto(matronId, sireId).send({ value: fee })
  }

  //isPregnant: kiểm tra xem chó có đang mang thai hay không
  async isPregnant(dogeId) {
    return await this.dogeHeroInstance.methods.isPregnant(dogeId).call()
  }
  //isReadyToBreed: kiểm tra xem chó đã sẵn sàng để phối giống hay chưa
  async isReadyToBreed(dogeId) {
    return await this.dogeHeroInstance.methods.isReadyToBreed(dogeId).call()
  }

  //canBreedWith: kiểm tra xem 2 con chó có thể phối giống để sinh sản hay không
  //nếu không cùng huyết thống thì có thể phối với nhau
  //cách xác định cùng huyết thống: với cha mẹ, với anh em (cùng cha mẹ, cùng cha hoặc cùng mẹ)
  async canBreedWith(matronId, sireId) {
    return await this.dogeHeroInstance.methods.canBreedWith(matronId, sireId).call()
  }

  //tạo token WOD
  async mintWOD(account, amount) {
    return await this.wodToken.methods.mintTest(account, amount).send()
  }

  async mintRuby(account, amount) {
    return await this.ruby.methods.mintTest(account, amount).send()
  }

  //deposit các token doge để thu hoạch Ruby và WOD miễn phí
  async depositDoges(dogeIds) {
    return await this.dogeWarInstance.methods.depositDoges(dogeIds).send()
  }

  //withdraw các token doge
  async withdrawDoges(dogeIds) {
    return await this.dogeWarInstance.methods.withdrawDoges(dogeIds).send()
  }
  //estimate gas khi withdraw doge
  async estimateWithdrawDoges(dogeIds) {
    return await this.dogeWarInstance.methods.withdrawDoges(dogeIds).estimateGas()
  }
  //withdraw tất cả token doge
  async withdrawAllDoges() {
    return await this.dogeWarInstance.methods.withdrawAllDoges().send()
  }
  async estimateWithdrawAllDoges() {
    return await this.dogeWarInstance.methods.withdrawAllDoges().estimateGas()
  }
  //approve dogeWar contract có quyền transfer doge từ chủ sang dogeWar contract
  async approveToDepositDoge() {
    return await this.dogeHeroInstance.methods.setApprovalForAll(this.dogeWarInstance._address, true).send()
  }
  async checkApproveToDepositDoge(owner) {
    return await this.dogeHeroInstance.methods.isApprovedForAll(owner, this.dogeWarInstance._address).call()
  }
  //tấn công người khác để cướp Ruby 
  //defenderAddress có kiểu address
  async attackHero(defenderAddress) {
    return await this.dogeWarInstance.methods.attackHero(defenderAddress).send({ from: this.currentUserAddress })
  }
  //kiểm tra xem attacker có thể tấn công defender được hay không
  async areInPointsRange(attacker, defender) {
    return await this.dogeWarInstance.methods.areInPointsRange(attacker, defender).call()
  }
  //lấy tất cả các doge token đã deposit
  //owner: kiểu address
  async getDoges(owner) {
    return await this.dogeWarInstance.methods.getDoges(owner).call()
  }

  //số lượng người chơi đang có trong dogeWar contract
  async getTotalHeroOwner() {
    return await this.dogeWarInstance.methods.getTotalHeroOwner().call()
  }
  //lấy danh sách tất cả người chơi đang có trong dogeWar, mỗi người chơi là 1 address
  //return: address[]
  async getHeroOwners() {
    return await this.dogeWarInstance.methods.getHeroOwners().call()
  }
  //lấy thông tin của hero
  //return: uint[5]  
  async getHeroInfo(owner) {
    return await this.dogeWarInstance.methods.heroInfo(owner).call()
  }
  async totalShare() {
    return await this.dogeWarInstance.methods.totalShare().call()
  }
  //số lượng Ruby được mint mỗi block
  async rubyPerBlock() {
    return await this.dogeWarInstance.methods.rubyPerBlock().call()
  }
  //số lương WOD được mint mỗi block
  async wodPerBlock() {
    return await this.dogeWarInstance.methods.wodPerBlock().call()
  }

  async createRandomGene() {
    let traits = this.createTrait(0, 15)
    return await this.geneScience.methods.encode(traits).call()
  }
  //admin function
  async createGen0Auction(gene) {
    return await this.dogeHeroInstance.methods.createGen0Auction(gene).send()
  }
  async createGen0AuctionBatch(genes) {
    return await this.dogeHeroInstance.methods.createGen0AuctionBatch(genes).send()
  }
  async getOwner() {
    return await this.saleAuction.methods.owner().call()
  }
  //event listen   
  attackDogeEventListener(fn) {
    this.AttackDogeEvent = fn
  }
  depositDogesEventListener(fn) {
    this.DepositDogesEvent = fn
  }
  withdrawDogesEventListener(fn) {
    this.WithdrawDogesEvent = fn
  }
}