import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Card from '../../Component/Card';
import Filters from '../../Component/Filters';
import HistoryBuySellAll from '../../Component/HistoryBuySellAll';
import { priceCoinBNBAction } from '../../Redux/userSlice';
import './style.scss'
import banner from '../../asset/images/banner.jpg'
// import socket from '../../Socket';
function MarketPlace(props) {
    const dispatch = useDispatch()
    // useEffect(()=>{
    //     socket.on('priceBNB', (res) => {
    //         dispatch(priceCoinBNBAction(res))
    //       })
    //       return () => {
    //         socket.off("priceBNB");
    //       }
    // },[])
    return (
        <>
            <div>
                <img src={banner} alt="" width="100%" height="100%" />
            </div>
            <div className='main layerbg pb-3'>
                <Filters marketPlace={true} />
                <Card />
            </div>
            <HistoryBuySellAll />
        </>
    );
}

export default MarketPlace;