import { Empty } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import WithLoading from '../../HOC/WithLoading';
import { getMySiringDoges } from '../../Redux/action/api/dogeReducer';
import CardMyDoge from '../Card/CardMyDoge';
import PaginationCustom from '../PaginationCustom';

function MyDogeSiring({handleClickOpen,onShow}) {
    const [isLoading, setIsLoading] = useState(true)
    // myDogesSiring
    const dispatch = useDispatch()
    const { myDogesSiring } = useSelector(state => state.dogesApiReducer)
    const [limit,setLimit] = useState(12)
    const [page,setPage] = useState(1)
    const [checkBox, setCheckBox] = useState({
        flag: false,
        doge: [],
    })
    const LoadingmyDogesSiring = WithLoading(myDogesSiringComponent)
    function funcPagination(limti,page){
        setLimit(limti)
        setPage(page)
    }
    useEffect(async()=>{
        
        setIsLoading(true)
        window.scrollTo(0, 0);
        await dispatch(getMySiringDoges({limit,page}))
        setIsLoading(false)
    },[limit,page])
    function myDogesSiringComponent() {
        if (myDogesSiring?.array?.length <= 0) return <Empty/>
        return (
            <>
                {myDogesSiring?.array?.map((item, index) => {
                    return <CardMyDoge item={item} index={index} handleClickOpen={handleClickOpen} checkBox={checkBox.flag} onShow={onShow} siring={true} />
                })}
            </>
        )
    }
    return (
        <>
            <div className='containerCard'>
                <LoadingmyDogesSiring isLoading={isLoading} myDogesSiring={myDogesSiring?.array} />
            </div>
            <PaginationCustom color="#fff" total={myDogesSiring.total} limit={limit} page={page} funcPagination={funcPagination}/>
        </>
    );
}

export default MyDogeSiring;