import { Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { funcWallet } from '../../commons/blockchain';
import { toastErrorMessage, toastSuccessMessage } from '../../commons/message';
import WithLoading from '../../HOC/WithLoading';
import WithSpin from '../../HOC/WithSpin';
import { getMyDogesAction, getMyDogesDeposit } from '../../Redux/action/api/dogeReducer';
import { contractServices } from '../../Services/contract';
import CardMyDoge from '../Card/CardMyDoge';
import PaginationCustom from '../PaginationCustom';

function MyDogeDeposit({ handleClickOpen, handleClickOpenBreed, onShow }) {
    const [isLoading, setIsLoading] = useState(true)
    const { myDoges, myDogesDeposit } = useSelector(state => state.dogesApiReducer)
    const { approvedDeposit, approvedSiring, loadingFunc, profile } = useSelector(state => state.userReducer)
    const [isSpin, setIsSpin] = useState(false)
    const [limit, setLimit] = useState(12)
    const [page, setPage] = useState(1)
    const dispatch = useDispatch()
    const LoadingMyDoge = WithLoading(myDogesComponent)
    const [checkBox, setCheckBox] = useState({
        flag: false,
        doge: [],
    })
    function selectDogeDeposit() {
        setCheckBox({
            flag: true,
            doge: []
        })
    }
    function selectDoge(dogeItem) {
        let flag = false
        for (let doge of checkBox.doge) {
            if (doge.dogeId == dogeItem.dogeId) flag = true
        }
        if (!flag) checkBox.doge.push(dogeItem)
        else checkBox.doge = checkBox.doge.filter(item => item.dogeId !== dogeItem.dogeId)
        setCheckBox({
            doge: [...checkBox.doge],
            flag: true
        })
    }
    function cancelDogeDeposit() {
        setCheckBox({
            flag: false,
            doge: []
        })
    }
    async function withdrawDoges() {
        try {
            setIsSpin(true)
            const dogeTokenList = []
            for (let doge of checkBox.doge) {
                dogeTokenList.push(doge.dogeId)
            }
            if (dogeTokenList.length <= 0) {
                setIsSpin(false)
                return toastErrorMessage(`You haven't selected any doge yet`)
            }
            const contract = await contractServices()
            //transaction có thể fail nếu withdraw quá nhiều token doge cùng 1 lúc
            const estimateGas = await contract.estimateWithdrawDoges(dogeTokenList)
            //nếu estimateGas lớn hơn gas tối đa cho phép của 1 transaction trên BSC thì cảnh báo chọn ít doge hơn
            await contract.withdrawDoges(dogeTokenList)
            dispatch(getMyDogesDeposit({ limit: 12, page: 1 }))
            toastSuccessMessage(`Successfully withdraw doge`)
            setIsSpin(false)
            setCheckBox({
                flag: false,
                doge: []
            })
        } catch (error) {
            setCheckBox({
                flag: false,
                doge: []
            })
            setIsSpin(false)
            toastErrorMessage(`Transaction withdraw error`)
        }
    }
    async function deposit() {
        try {
            setIsSpin(true)
            // checkBox
            const dogeTokenList = []
            for (let doge of checkBox.doge) {
                dogeTokenList.push(doge.dogeId)
            }
            const contract = await contractServices()
            //kiểm tra doges có đang mang thai hay không, nếu có thì transaction sẽ fail
            for (let i = 0; i < dogeTokenList.length; i++) {
                const pregnant = await contract.isPregnant(dogeTokenList[i])
                if (pregnant) {
                    toastErrorMessage(`Pregnant Doge ${dogeTokenList[i]} can't deposit`)
                    setIsSpin(false)
                    return
                }
            }

            //kiểm tra xem đã approve contract chưa
            const owner = contract.currentUserAddress
            const approved = await contract.checkApproveToDepositDoge(owner)
            //nếu chưa approve thì bắt đầu approve
            if (!approved) {
                await contract.approveToDepositDoge()
            }
            await contract.depositDoges(dogeTokenList)
            await dispatch(getMyDogesAction({ limit: 12, page: 1 }))

            setIsSpin(false)
            setCheckBox({
                flag: false,
                doge: []
            })
        } catch (error) {
            setIsSpin(false)
        }
    }
    function myDogesComponent() {
        if (myDogesDeposit?.array?.length <= 0) return <Empty />
        return (
            <>
                {myDogesDeposit?.array?.map((item, index) => {
                    return <CardMyDoge selectDoge={selectDoge} item={item} checkBox={checkBox} index={index} bread={true} sell={true} handleClickOpenBreed={handleClickOpenBreed} handleClickOpen={handleClickOpen} onShow={onShow} deposit={true} />
                })}
            </>
        )
    }
    async function approveDeposit() {
        try {
            setIsSpin(true)
            const contract = await contractServices()
            await contract.approveToDepositDoge()
            await funcWallet()
            setIsSpin(false)
        } catch (error) {
            setIsSpin(false)
        }

    }
    function funcPagination(limit, page) {
        setLimit(limit)
        setPage(page)
    }
    useEffect(async () => {
        await dispatch(getMyDogesDeposit({ limit, page }))
        window.scrollTo(0, 0);
        setIsLoading(false)
    }, [limit, page])
    return (

        <>
            {!checkBox.flag ?
                approvedDeposit ?
                    <button className='btnOutLine ml-2' onClick={selectDogeDeposit}>
                        <WithSpin isSpin={loadingFunc}>
                            Withdraw Doge
                        </WithSpin>
                    </button> :
                    <button className=' btnOutLine ml-2' onClick={approveDeposit}>
                        <WithSpin isSpin={loadingFunc || isSpin}>
                            Approve Withdraw
                        </WithSpin>
                    </button>
                :
                <>
                    <button className='btnOutLine ml-2' onClick={withdrawDoges}>
                        <WithSpin isSpin={isSpin}>
                            Withdraw
                        </WithSpin>
                    </button>
                    <button className='btnOutLine ml-2' onClick={cancelDogeDeposit}>Cancel Withdraw</button>
                </>
            }
            <div className='containerCard'>
                <LoadingMyDoge isLoading={isLoading} myDoges={myDogesDeposit} />
            </div>
            <PaginationCustom limit={limit} page={page} color="#fff" total={myDogesDeposit?.total} funcPagination={funcPagination} />

        </>
    );
}

export default MyDogeDeposit;