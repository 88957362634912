import React, { useState } from 'react';
import './style.scss'
import 'antd/dist/antd.css';
import { Drawer, List, Avatar, Divider, Col, Row } from 'antd';
import { useSelector } from 'react-redux';
const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title}:</p>
        {content}
    </div>
);
function DetailItem({ onClose, visible, indexDoge }) {
    const { myDoges } = useSelector(state => state?.dogesReducer)

    let doge
    if (myDoges?.array) doge = myDoges?.array[indexDoge]
    let isGestating, isReady, cooldownIndex, nextActionAt, siringWithId, birthTime, matronId, sireId, generation, genes
    if (doge) {
        isGestating = doge.isGestating
        isReady = doge.isReady
        cooldownIndex = doge.cooldownIndex
        nextActionAt = doge.nextActionAt
        siringWithId = doge.siringWithId
        birthTime = doge.birthTime
        matronId = doge.matronId
        sireId = doge.sireId
        generation = doge.generation
        genes = doge.genes
    }

    return (
        <Drawer
            width={'50%'}
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible}
        >
            <p className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
                Doge Detail
            </p>
            <p className="site-description-item-profile-p">Information</p>
            <Row>
                <Col span={12}>
                    <DescriptionItem title="Pregnant" content={isGestating ? `Yes` : `No`} />
                </Col>
                <Col span={12}>
                    <DescriptionItem title="Ready to get pregnant" content={isReady ? `Yes` : `No`} />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <DescriptionItem title="Birthday" content={doge?.birthTime} />
                </Col>
                {/* <Col span={12}>
              <DescriptionItem title="Mother doge ID"  content={doge?.matronId} />
            </Col> */}
            </Row>
            <Row>
                <Col span={12}>
                    <DescriptionItem title="Father doge ID" content={doge?.sireId} />
                </Col>
                <Col span={12}>
                    <DescriptionItem title="Mother doge ID" content={doge?.matronId} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DescriptionItem
                        title="Generation"
                        content={doge?.generation}
                    />
                </Col>
            </Row>
            {/* <Divider />
            <p className="site-description-item-profile-p">Parameter</p>
            <Row>
                <Col span={12}>
                    <DescriptionItem title="Position" content="Programmer" />
                </Col>
                <Col span={12}>
                    <DescriptionItem title="Responsibilities" content="Coding" />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <DescriptionItem title="Department" content="XTech" />
                </Col>
                <Col span={12}>
                    <DescriptionItem title="Supervisor" content={<a>Lin</a>} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DescriptionItem
                        title="Skills"
                        content="C / C + +, data structures, software engineering, operating systems, computer networks, databases, compiler theory, computer architecture, Microcomputer Principle and Interface Technology, Computer English, Java, ASP, etc."
                    />
                </Col>
            </Row>
            <Divider />
            <p className="site-description-item-profile-p">Contacts</p>
            <Row>
                <Col span={12}>
                    <DescriptionItem title="Email" content="AntDesign@example.com" />
                </Col>
                <Col span={12}>
                    <DescriptionItem title="Phone Number" content="+86 181 0000 0000" />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DescriptionItem
                        title="Github"
                        content={
                            <a href="http://github.com/ant-design/ant-design/">
                                github.com/ant-design/ant-design/
                            </a>
                        }
                    />
                </Col>
            </Row> */}
        </Drawer>
    );
}

export default DetailItem;