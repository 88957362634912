import Fortmatic from 'fortmatic';
import Web3 from 'web3';
import DogeWar from './contracts/DogeWar.json'
import DogeWarProxy from './contracts/DogeWarProxy.json'
import Ruby from './contracts/RubyToken.json'
import RubyProxy from './contracts/RubyTokenProxy.json'
import WODToken from './contracts/WODToken.json'
import DogeHero from './contracts/DogeHero.json'
import DogeHeroProxy from './contracts/DogeHeroProxy.json'
import GeneScience from './contracts/GeneScience.json'
import SaleAuction from './contracts/SaleClockAuction.json'
import SaleAuctionProxy from './contracts/SaleClockAuctionProxy.json'
import SiringAuction from './contracts/SiringClockAuction.json'
import SiringAuctionProxy from './contracts/SiringClockAuctionProxy.json'
import PancakePair from './contracts/PancakePair.json'
import { portNetwork } from './port';



// wss://speedy-nodes-nyc.moralis.io/6e06ba12c95d358fb54b812f/bsc/testnet/ws
// wss://crimson-crimson-wind.bsc.quiknode.pro/eeac2a2c4facff426cdd86851190365719e2455a/
// wss://bsc.getblock.io/testnet/?api_key=3fbe7b1e-fe87-4c0e-9902-789a8da1e2c7
const RPC = portNetwork =="MAINNET"?`wss://bsc-ws-node.nariox.org:443`: `wss://speedy-nodes-nyc.moralis.io/6e06ba12c95d358fb54b812f/bsc/testnet/ws`
const web3 = new Web3(new Web3.providers.WebsocketProvider(RPC))
export const pairAddressBNB_BUSD = '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16'
export const pairAddressRUBY = '0xDC8295a4Eb0bBcc9867711a23BA2CAdDBBe33871'
export const pairAddressWOD = '0x46C7FBB970c3853ca1dBc135DE3F39E68B699774'
const web3BSC= new Web3('https://bsc-dataseed1.binance.org:443');
// const wallet = JSON.parse(localStorage.getItem('wallet'))
// let provider
// if (wallet) {
//     if (wallet.wallet == "metamask") provider = window.ethereum
//     else provider = window.BinanceChain
// }
// const web3 = new Web3(window.ethereum)
const networkId = portNetwork =="MAINNET"? '56' :'97'


export const smallNumber = (amount) => {
    return web3.utils.fromWei(amount)
}
export const toWei = (amount) =>{
    return web3.utils.toWei(amount)
}
export const BNB_BUSDContract = new web3BSC.eth.Contract(PancakePair, pairAddressBNB_BUSD)
export const dogeWarInstance = new web3.eth.Contract(DogeWar.abi, DogeWarProxy.networks[networkId]?.address)
export const dogeHeroInstance = new web3.eth.Contract(DogeHero.abi, DogeHeroProxy.networks[networkId]?.address)
export const ruby = new web3.eth.Contract(Ruby.abi, RubyProxy.networks[networkId]?.address)
export const wodToken = new web3.eth.Contract(WODToken.abi, WODToken.networks[networkId]?.address)
export const geneScience = new web3.eth.Contract(GeneScience.abi, GeneScience.networks[networkId]?.address)
export const saleAuction = new web3.eth.Contract(SaleAuction.abi, SaleAuctionProxy.networks[networkId]?.address)
export const siringAuction = new web3.eth.Contract(SiringAuction.abi, SiringAuctionProxy.networks[networkId]?.address)
