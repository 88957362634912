import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHistoryAttackAll, getHistoryAttackHero } from '../../Redux/action/api/dogeReducer';
import Table from '../Table';
import { strWallet } from '../../commons/blockchain'
import TableCustom from './TableHistoryAttackAll';
import PaginationCustom from '../PaginationCustom';
import { dogeWarInstance } from '../../contractAccount';
import { Checkbox } from '@mui/material';
function HistoryAttackHero({ owner }) {
    const dispatch = useDispatch()
    const { historyAttackHero } = useSelector(state => state.dogesApiReducer)
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [checkBox, setCheckBox] = useState({
        beingAttack: false,
        iAttack: false,
        allAttack: true
    })
    const arrayTitle = [

        {
            title: "Attacker",
            key: "attacker"
        },
        {
            title: "Defender",
            key: "defender"
        },
        {
            title: "Status",
            key: "status"
        },
        {
            title: "Reward",
            key: "reward"
        },
        {
            title: "Date/Time",
            key: "created_at"
        },
    ]
    const arraySrouce = []
    function changeCheckBox(beingAttack, iAttack, allAttack) {
        setCheckBox({
            beingAttack, iAttack, allAttack
        })
    }
    // if (historyAttackHero.array.length > 0) {
    //     for (let item of historyAttackHero?.array) {
    //         let obj = item
    //         let strStatus = 'Win'
    //         let classDiv = 'success'
    //         if (item?.status == 0) {
    //             classDiv = 'warning'
    //             strStatus = 'Draw'
    //         }
    //         else {
    //             strStatus = 'Lose'
    //             classDiv = 'danger'
    //         }
    //         obj.statusStr = <>
    //             <p className={`text-${classDiv}`}>{strStatus}</p>
    //         </>
    //         obj.attacker = strWallet(item?.attacker)
    //         obj.defender = strWallet(item?.defender)
    //         arraySrouce.push(obj)
    //     }
    // }
    useEffect(() => {
        let emitter = dogeWarInstance.events.AttackHero({})
            .on('data', async event => {
                setLimit(limit + 1)
                // await dispatch(getHistoryAttackHero({ limit, page, where: `attacker='${owner}' OR defender='${owner}'` }))
            })
            .on('changed', changed => console.log(changed))
            .on('error', err => console.log('error', err.message, err.stack))
            .on('connected', str => console.log(str, "Attack Hero "))
        return () => {
            emitter.removeAllListeners('data')
        }
    })
    function funcPagination(limit, page) {
        setLimit(limit)
        setPage(page)
    }
    useEffect(async () => {
        let where = ``
        if (checkBox.beingAttack) {
            where = `defender='${owner}'`
        } else if (checkBox.iAttack) {
            where = `attacker='${owner}'`
        } else if (checkBox.allAttack) {
            where = `attacker='${owner}' OR defender='${owner}'`
        }
        await dispatch(getHistoryAttackHero({ limit, page, where }))
    }, [limit, page, checkBox])
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    return (
        <>
            <div className='my-3'>
                <span className="Toolbar-generation--label  ">
                    <Checkbox {...label} checked={checkBox.beingAttack} color="success" onChange={(value) => {

                        changeCheckBox(true, false, false)
                    }


                    } />
                    <span className='fz'>Being attacked</span>
                </span>
                <span className="Toolbar-generation--label  ">
                    <Checkbox {...label} checked={checkBox.iAttack} color="success" onChange={(value) => {
                        changeCheckBox(false, true, false)
                    }


                    } />
                    <span className='fz'>I attack</span>
                </span>
                <span className="Toolbar-generation--label  ">
                    <Checkbox {...label} checked={checkBox.allAttack} color="success" onChange={(value) => {
                        changeCheckBox(false, false, true)
                    }
                    } />
                    <span className='fz'>All attack</span>
                </span>
            </div>
            <TableCustom arrayTitle={arrayTitle} arrayData={historyAttackHero?.array} title={`Attack / Defense History`} />
            <PaginationCustom total={historyAttackHero?.total} limit={limit} page={page} funcPagination={funcPagination} />
        </>
    );
}

export default HistoryAttackHero;