import { createAsyncThunk } from "@reduxjs/toolkit"
import { convertTimeToString } from "../../../commons"
import Contract from "../../../contract"
import { createApiBody } from "../../../Services"
import { contractServices } from "../../../Services/contract"
import { getListSiringDogeInfo, getSellingDogeInfoFunc } from "../funcAction"


const contract = new Contract()

export const createDoge = async () => {
    await contract.mintHero(contract.currentUserAddress)
}
const getHeroDetailFunc = async (data) => {
    try {
        const dataReturn = await createApiBody(data, 'api/hero/getHeroDetail')
        return dataReturn.data.data
    } catch (error) {
        console.log(error, "getAllSellingDogesFunc");
    }
}
const getDogeDetailFunc = async (data) => {
    try {
        data.where = `dogeId = ${data.dogeId}`
        const dataReturn = await createApiBody(data, 'api/doge/getDogeDetail')
        return dataReturn.data.data
    } catch (error) {
        console.log(error, "getAllSellingDogesFunc");
    }
}
const getAllHeroFunc = async (data) => {
    try {
        const dataReturn = await createApiBody(data, 'api/hero/getHeros')
        return dataReturn.data.data
    } catch (error) {
        console.log(error, "getAllSellingDogesFunc");
    }

}
const getHistoryAttackHeroFunc = async (data) => {
    try {
        const dataReturn = await createApiBody(data, 'api/hero/history')
        return dataReturn.data.data
    } catch (error) {
        console.log(error, "getAllSellingDogesFunc");
    }

}
const getHistorySiringAddressFunc = async (data) => {
    try {
    
        const wallet = JSON.parse(localStorage.getItem('wallet'))
        if (wallet) {
            data.where = `winner='${wallet.address}' OR owner='${wallet.address}'`
                const dataReturn = await createApiBody(data, 'api/doge/historySiring')
                return dataReturn.data.data
        }
    } catch (error) {
        console.log(error, "getHistoryBuySellAddressFunc");
    }

}
const getHistoryBuySellAddressFunc = async (data) => {
    try {
    
        const wallet = JSON.parse(localStorage.getItem('wallet'))
        if (wallet) {
            data.where = `winner='${wallet.address}' OR owner='${wallet.address}'`
                const dataReturn = await createApiBody(data, 'api/doge/historyBuySell')
                return dataReturn.data.data
        }
    } catch (error) {
        console.log(error, "getHistoryBuySellAddressFunc");
    }

}
const getHistoryBuySellAllFunc = async (data) => {
  
    data.where = ``
    const dataReturn = await createApiBody(data, 'api/doge/historyBuySell')
    return dataReturn.data.data


}
const getHistorySiringAllFunc = async (data) => {
  
    data.where = ``
    const dataReturn = await createApiBody(data, 'api/doge/historySiring')
    return dataReturn.data.data


}
const getHistoryAttackAllFunc = async (data) => {
    try {
        // data.where = ``
        const dataReturn = await createApiBody(data, 'api/hero/history')
        return dataReturn.data.data
    } catch (error) {
        console.log(error, "getAllSellingDogesFunc");
    }

}
const getMySiringDogesFunc = async (data) => {
    try {
        const wallet = JSON.parse(localStorage.getItem('wallet'))
        if (wallet) {
            data.where = `siring=1 AND owner='${wallet.address}'`
            const dataReturn = await createApiBody(data, 'api/doge/getDoge')
            return dataReturn.data.data
        }
    } catch (error) {
        console.log(error, "getAllSellingDogesFunc");
    }

}

const getAllSellingDogesFunc = async (data) => {
    try {
        data.where = `sell=1 `
        if (data.option) data.where = `sell=1 ` + data.option

        data.sell = true
        const dataReturn = await createApiBody(data, 'api/doge/getDoge')
        return dataReturn.data.data
        const wallet = JSON.parse(localStorage.getItem('wallet'))
        if (wallet) {

            const contract = await contractServices()

        }
    } catch (error) {
        console.log(error, "getAllSellingDogesFunc");
        return {
            array: [],
            total: 0
        }
    }
}
const getAllSiringDogesFunc = async (data) => {
    try {
        const wallet = JSON.parse(localStorage.getItem('wallet'))
        data.where = `siring=1`
        data.siring = true
        if (data.option) data.where = `siring=1 ` + data.option

        const dataReturn = await createApiBody(data, 'api/doge/getDoge')
        return dataReturn.data.data
    } catch (error) {
        console.log(error, "error siring")

    }

}
const giveBirth = async (matronId, contract) => {
    //kiểm tra xem doge có đang mang thai và có thể sinh được chưa
    const doge = await contract.getDoge(matronId)
    const isGestating = doge[0]
    const isReady = doge[1]
    // const cooldownIndex = doge[2]
    const nextActionAt = doge[3]
    if (isGestating) {
        if (isReady) {
            // await contract.giveBirth(matronId)
        }
        else {
            //mỗi block trên BSC khoảng 3 giây

            //ước lượng thời gian để hiển thị thời điểm chó sẽ đẻ cho người dùng
            // console.log('chó chưa đến thời điểm đẻ, sẽ đẻ vào block số:', nextActionAt)
        }
    } else {
    }
}

const getSellingDogeInfo = async (dogeId, contract) => {
    const dogeInfo = await contract.getSellingDogeInfo(dogeId)
    const seller = dogeInfo[0]
    const startingPrice = dogeInfo[1]
    const endingPrice = dogeInfo[2]
    const duration = dogeInfo[3]
    const startedAt = dogeInfo[4]
    const owner = await contract.getOwnerDogeHero(dogeId)
    return {
        dogeId,
        seller,
        startingPrice,
        endingPrice,
        duration,
        owner,
        startedAt
    }
}
export const getMySellingDogesFunc = async (data) => {
    try {
        const wallet = JSON.parse(localStorage.getItem('wallet'))
        if (wallet) {
            data.where = `owner='${wallet.address}' AND sell=1`
            const dataReturn = await createApiBody(data, 'api/doge/getDoge')
            return dataReturn.data.data
        }
    } catch (error) {
        console.log(error, "getMySellingDogesFunc");
    }
}
const getDogeFunc = async (contract, myDoge, arrayReturn) => {
    const doge = await contract.getDoge(myDoge)
    const isGestating = doge[0]
    const isReady = doge[1]
    const nextActionAt = doge[3]
    doge.dogeId = myDoge
    convertTimeToString(doge)
    doge.owner = await contract.getOwnerDogeHero(myDoge)
    arrayReturn.push(doge)
    if (isGestating) {
        if (isReady) {
            //có thể gọi hàm giveBirth để sinh ra chó con
            await giveBirth(myDoge, contract)
        } else {
            //hiển thị thời gian còn lại đến lúc có thể đẻ, tính bằng ngày/ giờ/ phút/ giây
        }
    }
}

const getListInfoDoge = async (myDoges, contract) => {
    const dataReturn = []
    const arrayPromise = []
    for (let myDoge of myDoges) {
        arrayPromise.push(getDogeFunc(contract, myDoge, dataReturn))
    }
    await Promise.all(arrayPromise)
    return dataReturn
}
export const getMyDogesDepositActionFunc = async (data) => {
    try {
        const wallet = JSON.parse(localStorage.getItem('wallet'))
        if (wallet) {
            data.owner = wallet.address

            const dataReturn = await createApiBody(data, 'api/hero/myDogeDeposit')
            return dataReturn.data.data
        }
    } catch (error) {
    }
}
export const getMyDogesActionFunc = async (data) => {
    try {
        const wallet = JSON.parse(localStorage.getItem('wallet'))
        if (wallet) {
            data.getBlock = true
            data.where = `owner='${wallet.address}' AND siring=0 AND sell=0 AND deposit=0`
            const dataReturn = await createApiBody(data, 'api/doge/getDoge')
            return {
                myDoges: dataReturn.data.data,
            }
        }
    } catch (error) {
    }
}
export const getMyDogesSelectActionFunc = async (data) => {
    try {
        const wallet = JSON.parse(localStorage.getItem('wallet'))
        if (wallet) {
            data.getBlock = true
            data.select = true
            // item.isGestating == 1 || item.timeIsGestating || dogeBread?.dogeId == item.dogeId || !item.canBreed
            data.where = `owner='${wallet.address}' AND siring=0 AND sell=0 AND deposit=0 AND isGestating=0 AND dogeId != ${data.dogeBread.dogeId}`
            const dataReturn = await createApiBody(data, 'api/doge/getDoge')
            return {
                myDoges: dataReturn.data.data,
            }
        }
    } catch (error) {
        console.log(error,"getMyDogesSelectActionFunc");
    }
}
export const getMyDogesSelectAction = createAsyncThunk('user/getMyDogesSelectAction', async (data) => {
    try {
        const dataReturn = await getMyDogesSelectActionFunc(data)


        return dataReturn
    } catch (error) {
        console.log(error, "getMyDogesAction")
    }
})
export const getMyDogesAction = createAsyncThunk('user/getMyDoges', async (data) => {
    try {
        const dataReturn = await getMyDogesActionFunc(data)
        return dataReturn
    } catch (error) {
        console.log(error, "getMyDogesAction")
    }
})
export const getHeroDetail = createAsyncThunk('user/getHeroDetail', async (datas) => {
    try {
        const data = await getHeroDetailFunc(datas)
        return data
    } catch (error) {
        console.log(error, "getDogeDetail")
        return {}

    }
})
export const getDogeDetail = createAsyncThunk('user/getDogeDetail', async (datas) => {
    try {
        const data = await getDogeDetailFunc(datas)
        return data
    } catch (error) {
        console.log(error, "getDogeDetail")
        return {}

    }
})
export const getMySellingDoges = createAsyncThunk('user/getMySellingDoges', async (datas) => {
    try {
        const data = await getMySellingDogesFunc(datas)
        return data
    } catch (error) {
        console.log(error, "getMySellingDoges")
        return {}

    }
})
export const getAllSellingDoges = createAsyncThunk('user/getMySellingDoges', async (datas) => {
    try {

        const data = await getAllSellingDogesFunc(datas)
        return data
    } catch (error) {
        console.log(error, "getMySellingDoges")
        return {
            total: 0,
            array: []
        }
    }
})
export const getAllSiringDoges = createAsyncThunk('user/getAllSiringDoges', async (datas) => {
    try {
        const data = await getAllSiringDogesFunc(datas)

        return data
    } catch (error) {
        console.log(error, "getMySellingDoges")

    }
})


export const getAllHeros = createAsyncThunk('user/getAllHeros', async (datas) => {
    try {
        const data = await getAllHeroFunc(datas)

        return data
    } catch (error) {
        console.log(error, "getMySiringDogesFunc")

    }
})
// getMyDogesDepositActionFunc
export const getMyDogesDeposit = createAsyncThunk('user/getMyDogesDeposit', async (datas) => {
    try {
        const data = await getMyDogesDepositActionFunc(datas)

        return data
    } catch (error) {
        console.log(error, "getMySiringDogesFunc")

    }
})
export const getHistoryAttackHero = createAsyncThunk('user/getHistoryAttackHero', async (datas) => {
    try {
        const data = await getHistoryAttackHeroFunc(datas)

        return data
    } catch (error) {
        console.log(error, "getMySiringDogesFunc")

    }
})
export const getHistorySiringAddress = createAsyncThunk('user/getHistorySiringAddress', async (datas) => {
    try {
        const data = await getHistorySiringAddressFunc(datas)

        return data
    } catch (error) {
        console.log(error, "getMySiringDogesFunc")

    }
})
export const getHistoryBuySellAddress = createAsyncThunk('user/getHistoryBuySellAddress', async (datas) => {
    try {
        const data = await getHistoryBuySellAddressFunc(datas)

        return data
    } catch (error) {
        console.log(error, "getMySiringDogesFunc")

    }
})
export const getHistorySiringAll = createAsyncThunk('user/getHistorySiringAll', async (datas) => {
    try {
        
        const data = await getHistorySiringAllFunc(datas)

        return data
    } catch (error) {
        console.log(error, "getMySiringDogesFunc")

    }
})
export const getHistoryBuySellAll = createAsyncThunk('user/getHistoryBuySellAll', async (datas) => {
    try {
        
        const data = await getHistoryBuySellAllFunc(datas)

        return data
    } catch (error) {
        console.log(error, "getMySiringDogesFunc")

    }
})
export const getHistoryAttackAll = createAsyncThunk('user/getHistoryAttackAll', async (datas) => {
    try {
        const data = await getHistoryAttackAllFunc(datas)

        return data
    } catch (error) {
        console.log(error, "getMySiringDogesFunc")

    }
})
export const getMySiringDoges = createAsyncThunk('user/getMySiringDoges', async (datas) => {
    try {
        const data = await getMySiringDogesFunc(datas)

        return data
    } catch (error) {
        console.log(error, "getMySiringDogesFunc")

    }
})
