import { configureStore } from "@reduxjs/toolkit";
import userReducer from '../Redux/userSlice'
import dogesReducer from '../Redux/dogeSlice'
import dogesApiReducer from '../Redux/dogeApiSlice'
const rootReducer = {
    userReducer,
    dogesReducer,
    dogesApiReducer,
}

const store = configureStore({
   reducer: rootReducer,
})

export default store