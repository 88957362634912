// import React from 'react';
// import SpinLoading from '../Component/SpinLoading';
// function WithSpin({Component}) {
//     return function WithLoadingComponent({ isSpin, ...props }) {
//         if (!isSpin) return (<Component {...props} />);
//         return (<SpinLoading/>);
//     }
// }
// export default WithSpin;
import React from 'react';
import SpinLoading from '../Component/SpinLoading';

function WithSpin({ children,isSpin,center,color }) {
    if(isSpin) return (<SpinLoading center={center} color={color}/>);
    return (
        <>
            {children}
        </>
    );
}

export default WithSpin;