import { useEthers } from "@usedapp/core";
import { useEffect } from 'react';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { eventBlockchain, eventListenWallet, getPriceBNB, isConnectBinance, isConnectMetamask } from './commons/blockchain';
import { pairAddressBNB_BUSD, pairAddressRUBY, pairAddressWOD } from './contractAccount';
import { priceCoinAction, priceCoinBNBAction, profileAction } from './Redux/userSlice';
import RoutesMain from './routes/index';
 
function App() {
  // const { activate, account, library, connector, active, deactivate } = useWeb3React()
  const { account } = useEthers();
  const dispatch = useDispatch()
  const { priceCoin } = useSelector(state => state.userReducer)

  useEffect(async () => {

    // const approvedSellResQuery = contract.checkApproveToSellDoge(contract.currentUserAddress)
    // const approvedSiringResQuery = contract.checkApproveToSireDoge(contract.currentUserAddress)
    // const [approvedSellRes, approvedSiringRes] = await Promise.all([approvedSellResQuery, approvedSiringResQuery])
    // setApprovedSell(approvedSellRes)
    // setApprovedSiring(approvedSiringRes)


  }, [])
  useEffect(async () => {
    try {
      const res = await getPriceBNB(pairAddressBNB_BUSD)
      const ruby = await getPriceBNB(pairAddressRUBY) 
      const wod =  await getPriceBNB(pairAddressWOD) 
      const data={wod,ruby}
      dispatch(priceCoinBNBAction(res))
      dispatch(priceCoinAction(data))
      // setInterval(async () => {
      //   const res = await getPriceBNB(pairAddressBNB_BUSD)
      //   dispatch(priceCoinBNBAction(res))
      // }, 300000)
      const wallet = JSON.parse(localStorage.getItem('wallet'))
      if (wallet) {
        eventListenWallet(dispatch, wallet)
        if (wallet.wallet == "metamask") {
          // await changeNetwork(window.ethereum)
          const isMetamask = await isConnectMetamask(wallet)
          if (isMetamask) {
            dispatch(profileAction(isMetamask))
          }
        } else if (wallet.wallet == "binance") {
          // await changeNetwork(window.BinanceChain)
          const isBinance = await isConnectBinance()
          if (isBinance) {
            dispatch(profileAction(isBinance))
          }
        }
        eventBlockchain(wallet, priceCoin)
        ///////////////////////////////////
        // const resAction = await dispatch(getBalanceAction())
        // await test()
        // const currentAction = unwrapResult(resAction)
      }

    } catch (error) {
    }
  }, [])

  return (
    <>
      <RoutesMain />
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
              // position="top-right"
            // autoClose={2000}
            // hideProgressBar={false}
            // newestOnTop={false}
            // closeOnClick
            // rtl={false}
            // pauseOnFocusLoss
            // draggable
          />
          
    </>
  );
}

export default App;
