import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { InputNumber } from 'antd';
import React, { useState } from 'react';
import Sreach from '../Sreach';
import DrawerFilter from './DrawerFilter';
import { useDispatch, useSelector } from 'react-redux'
import { openPopupConnectAction, whereListAllSellAction, whereListAllSiringAction, whereListHeroAction } from '../../Redux/userSlice'
// import './style.scss';
import { truncate } from '../../commons/blockchain';
import { Checkbox } from '@mui/material';
import { filterHerosAllAction } from '../../Redux/dogeApiSlice';
import './styles.scss'
function FiltersAttack({ marketPlace, siring, checkBox, setCheckBox, checkBoxMyHero, setCheckBoxMyHero }) {

    const [age, setAge] = useState(0);
    const [valueSort, setValueSort] = useState('ranker')
    const [valueOderBy, setValueOderBy] = useState('ASC')
    const [gen, setGen] = useState(0)
    const dispatch = useDispatch()
    const { listMartketPlace, whereListAllSell, whereListAllSiring, listSiring } = useSelector(state => state.userReducer)
    const { allHeros } = useSelector(state => state.dogesApiReducer)
    const handleChange = (event) => {

        // dispatch(filterHerosAllAction())
        let value = event.target.value
        filter(valueSort, value)
        setValueOderBy(value)

    };
    function filter(order, by) {
        // const arrayFilter = {
        //     array: [...allHeros.array],
        //     total: allHeros.total
        // }
        // if (by == "ASC") {
        //     arrayFilter.array.sort((a, b) => {
        //         return a[order] - b[order]
        //     })
        // } else {
        //     arrayFilter.array.sort((a, b) => {
        //         return b[order] - a[order]
        //     })
        // }
        // dispatch(filterHerosAllAction(arrayFilter))
        let str = `ORDER BY ${order} ${by}`
        dispatch(whereListHeroAction(str))

    }
    const handleChangeOderBy = (event) => {
        let value = event.target.value
        filter(value, valueOderBy)
        setValueSort(value)
        // if (marketPlace) {
        //     let str = `ORDER BY ${value} ${valueOderBy} `
        //     dispatch(whereListAllSellAction(str))
        // } else if (siring) {
        //     let str = `ORDER BY ${value} ${valueOderBy} `
        //     dispatch(whereListAllSiringAction(str))
        // }


    };
    function onChangeInputNumber(value) {
        let str = ` AND generation=${value} ORDER BY ${valueSort} ${valueOderBy}`

        if (marketPlace) {
            dispatch(whereListAllSellAction(str))
        } else if (siring) {
            dispatch(whereListAllSiringAction(str))
        }
        setGen(value)

    }
    const elementTotal = () => {
        if (marketPlace) return truncate(listMartketPlace.total, 0)
        else if (siring) return truncate(listSiring.total, 0)

    }
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    return (
        <>

            <div className="Toolbar-RowContainer Toolbar-RowContainer--second-row my-3 row" style={{
                width: "90%",
                margin: "auto"
            }}>
                <div className="row fzs filterAttackPage" style={{ color: "white" }}>
                    <span className="Toolbar-generation--label whiteCheckBox fz" style={{
                        display: "flex",
                        justifyItems: "center",
                        alignContent: "center"
                    }}>
                        <Checkbox {...label} checked={checkBox} onChange={(value) => {
                            const wallet = JSON.parse(localStorage.getItem('wallet'))
                            if (!wallet) return dispatch(openPopupConnectAction(true))
                            setCheckBox(!checkBox)
                            setCheckBoxMyHero(false)
                        }
                        } />
                        <span className='fz' style={{
                            whiteSpace: "nowrap",
                            margin: "auto"
                        }}>Attackable Heros</span>
                    </span>
                </div>
                <div className="fzs filterAttackPage" style={{ color: "white" }}>
                    <span className="Toolbar-generation--label col-12 whiteCheckBox fz pdRight col-lg-12">
                        <Checkbox {...label} checked={checkBoxMyHero} onChange={(value) => {
                            const wallet = JSON.parse(localStorage.getItem('wallet'))
                            if (!wallet) return dispatch(openPopupConnectAction(true))
                            setCheckBoxMyHero(!checkBoxMyHero)
                            setCheckBox(false)
                        }


                        } />
                        <span>My Hero</span>
                    </span>
                </div>
                <Box sx={{ minWidth: 120 }} className=' mt-1 select fzs oderPrice'>
                    <FormControl fullWidth >
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className='fzs'
                            value={valueSort}
                            label="Sort by"
                            onChange={handleChangeOderBy}
                        >

                            <MenuItem className="fz" value={`ranker`}>Rank</MenuItem>
                            <MenuItem className="fz" value={`health`}>Health</MenuItem>
                            <MenuItem className="fz" value={`attack`}>Attack</MenuItem>
                            <MenuItem className="fz" value={`defense`}>Defense</MenuItem>
                            {/* <MenuItem value={`dailyUsdOfDay`}>Reward</MenuItem> */}
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ minWidth: 120 }} className=' mt-1 select ml-3'>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={valueOderBy}
                            label="Age"
                            className="fzs"
                            onChange={handleChange}
                        >
                            <MenuItem className="fz" value={`ASC`}>Low to high</MenuItem>
                            <MenuItem className="fz" value={`DESC`}>High to low</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <div className="fzs totalCenter" style={{ color: "white", marginLeft: "auto" }}>

                    <span className="Toolbar-generation--label  text-right fzs " style={{
                        whiteSpace: "nowrap"
                    }}>Total: <b>{allHeros?.total}</b></span>
                </div>

            </div>

        </>
    );
}

export default FiltersAttack;