import React, { useState } from 'react';
import { Checkbox } from '@mui/material';
import HistoryAttackAll from '../HistoryAttackAll';
function RecentlyAttack( {valueTab}) {
    const [checkBox, setCheckBox] = useState({

        yesterday: true,
        lastWeek: false,
        lastMonth: false
    })
    function changeCheckBox(yesterday, lastWeek, lastMonth) {
        setCheckBox({
            yesterday, lastWeek, lastMonth
        })
    }
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    return (
        <>
            <div className='my-3 row'>
                <span className="Toolbar-generation--label col-3 col-lg-2 ">
                    <Checkbox {...label} checked={checkBox.yesterday} color="success" onChange={(value) => {

                        changeCheckBox(true, false, false)
                    }
                    } />
                    <span className='fz'>Yesterday</span>
                </span>
                <span className="Toolbar-generation--label col-3 col-lg-2 ">
                    <Checkbox {...label} checked={checkBox.lastWeek} color="success" onChange={(value) => {
                        changeCheckBox(false, true, false)
                    }
                    } />
                    <span className='fz'>Last week</span>
                </span>
                <span className="Toolbar-generation--label col-3 col-lg-2 ">
                    <Checkbox {...label} checked={checkBox.lastMonth} color="success" onChange={(value) => {
                        changeCheckBox(false, false, true)
                    }
                    } />
                    <span className='fz'>Last month</span>
                </span>
            </div>
            <HistoryAttackAll checkBox={checkBox} valueTab={valueTab} statistical={true}/>
        </>
    );
}

export default RecentlyAttack;