import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { truncate } from '../../../../commons/blockchain';
import { toastErrorMessage, toastSuccessMessage } from '../../../../commons/message';
import WithSpin from '../../../../HOC/WithSpin';
import { port } from '../../../../port';
import { getAllSellingDoges } from '../../../../Redux/action/api/dogeReducer';
import { openPopupConnectAction } from '../../../../Redux/userSlice';
import { contractServices } from '../../../../Services/contract';
import BNB from '../../../../asset/images/bnb.png'
import './style.scss';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PopupBuy({ open, handleClickOpen, handleClose, doge, price }) {
    const [age, setAge] = React.useState(0);
    const [isSpin, setIsSpin] = useState(false)
    const [startPrice, setStartPrice] = useState(0)
    const [endPrice, setEndPrice] = useState(0)
    // const [price, setPrice] = useState(0)
    const { priceCoin } = useSelector(state => state.userReducer)
    const dispatch = useDispatch()
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    async function getPrice(doge) {
        let price = 0
        if (doge?.dogeId) {

        }
        return price
    }
    async function buyDoge() {
        try {
            const wallet = JSON.parse(localStorage.getItem('wallet'))
            if (!wallet) return dispatch(openPopupConnectAction(true))
            setIsSpin(true)
            const dogeId = doge.dogeId
            const contract = await contractServices()

            const currentPrice = await contract.getCurrentSellingPrice(dogeId)
            await contract.buyDoge(dogeId, currentPrice)
            setIsSpin(false)
            handleClose()
            toastSuccessMessage(`Buy a successful doge`)
            window.location.reload()
            dispatch(getAllSellingDoges({ limit: 12, page: 1 }))
        } catch (error) {
            console.log(error)
            toastErrorMessage(`Doge purchase failed`)
            setIsSpin(false)
        }
    }

    //// element ///


    //////////////

    return (

        <Dialog
            open={open.flag}
            TransitionComponent={Transition}
            keepMounted
            className="minWidth"
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"

        >
            {/* <DialogTitle sx={{
                textAlign: "center"
            }}>{`Buy Now`}</DialogTitle> */}
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" sx={{
                    textAlign: "center"
                }}>
                    <div style={{
                        background: `${doge?.background}`,
                        width: "280px",
                        height: "260px",
                        borderRadius: "5px",
                        maxWidth:"100%"
                    }}>
                        <img src={`${port}${doge?.image}`} className='imgBuy' style={{

                        }} alt="" />
                    </div>
                    {/* <p>Here's a summary of your purchase:</p> */}
                    {/* <InputLabel id="demo-simple-select-label">Starting Price (BNB)</InputLabel> */}
                    <p className='text-center my-2'>
                        Doge&nbsp;
                        <b>#{doge?.dogeId}</b>
                    </p>
                    <div className="formSliver">
                        <div className="textPrice" style={{
                            color: "#fac228"
                        }}>
                            Price
                        </div>
                        <div className='priceGroupUSD'>
                            <b class="priceBNB">
                                <img src={BNB} height={20} />&nbsp;{truncate(doge?.price, 6)}
                            </b>
                            <p className='USDPrice'>~&nbsp;$ {truncate(doge?.price * priceCoin.BNB, 2)}</p>
                        </div>
                    </div>
                    {/* <div className='total'>
                        <p>Total</p>
                        <p>{truncate(doge?.price, 6)} BNB </p>
                    </div> */}
                </DialogContentText>
            </DialogContent>
            <DialogActions>

                <button className='btnBuyNow text-center' disabled={isSpin} onClick={buyDoge}>
                    <WithSpin isSpin={isSpin}>
                        Buy now
                    </WithSpin>
                </button>

                {/* <Button onClick={handleClose}>Cancel</Button> */}
            </DialogActions>
        </Dialog>
    );
}
