import React, { useEffect, useRef, useState } from 'react';
import Title from 'react-vanilla-tilt'
import VanillaTilt from 'vanilla-tilt';
import Wod from '../../../asset/images/dogepts.png';
import { strWallet, truncate } from '../../../commons/blockchain';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Contract from '../../../contract';
import { toastSuccessMessage } from '../../../commons/message'
import { port } from '../../../port';
import { NavLink } from 'react-router-dom';
import logoBNB from '../../../asset/images/bnb.png'
import { useSelector } from 'react-redux';
import { smallNumber } from '../../../contractAccount';
function CardMyDoge({ item, index, onShow, handleClickOpen, sell, siring }) {
    const tilt = useRef(null);
    const [card, setCard] = useState(false)
    const [copy, setCopy] = useState(false)
    const inputArea = React.useRef(null);
    const [price, setPrice] = useState(0)
    const { priceCoin } = useSelector(state => state.userReducer)

    function handleCopy(e) {
        navigator.permissions
            .query({ name: "clipboard-write" })
            .then((result) => {
                if (result.state === "granted" || result.state === "prompt") {
                    updateClipboard(inputArea.current?.innerText);
                }
            });
    }
    function updateClipboard(newClip) {
        navigator.clipboard.writeText(newClip).then(
            () => {
                setCopy(true);
                toast.success('Copied!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            },
            () => {
                setCopy(false);
                // setOpen(false)

            }
        );
    }
    useEffect(async () => {
        const options = { speed: 400, max: 25 }
        // tilt.hover()
        const width = window.innerWidth
        if (width < 800) setCard(true)
        // else VanillaTilt.init(tilt.current, options);

    }, [])
    return (

        // <div className={`boxCard ${card ? 'reponsive' : ''}`} hover={true} key={index} ref={tilt}>
        <div className={`boxCard reponsive`} hover={true} style={{

        }} key={index} ref={tilt}>
            <div className='imgTop' style={{
                background: `${item.background}`
            }}>
            </div>

            {/* <h2 className="name" ref={tilt}>ID: {item.dogeId}</h2> */}
            <div className='groupIdAndPrice'>
                <p className='id'>#{item.dogeId}</p>
                <div className='priceGroup'>
                    <h6 className="priceBNB" style={{
                        textOverflow: "ellipsis"
                    }} ref={tilt}>
                        <img src={logoBNB} width={20} height={20} alt="" />
                        &nbsp;
                        <p>
                        {truncate(item.price, 6)} BNB

                        </p>
                    </h6>
                    <p className='priceUSD'> ~ $ {truncate(priceCoin.BNB * item.price, 2)}</p>
                </div>
            </div>
            <img ref={tilt} src={`${port}${item.image}`} alt="" className='product' />

            <div className='ownerInput'>
                <b>
                    Owner: {strWallet(item.owner)}
                </b>
                <ContentCopyIcon onClick={handleCopy} className="ml-2" sx={{
                    cursor: 'pointer;'
                }} />
                <div ref={inputArea} style={{ display: "none" }}>
                    {item.owner}
                </div>
            </div>
            <div className='groupButton'>
                <button className='btnSiring buttonBuy' style={{background:"none"}} onClick={() => handleClickOpen(item, item.price)} ref={tilt}>Siring</button>
                <NavLink to={`/doge/${item.dogeId}`} className='buttonDetail btnVang' ref={tilt} >Detail</NavLink>
            </div>
            {/* {image} */}
        </div>


    );
}

export default CardMyDoge;