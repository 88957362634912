

const getSellingDogeInfo = async (dogeId, contract) => {
    const dogeInfo = await contract.getSellingDogeInfo(dogeId)

    const seller = dogeInfo[0]
    const startingPrice = dogeInfo[1]
    const endingPrice = dogeInfo[2]
    const duration = dogeInfo[3]
    const startedAt = dogeInfo[4]
    const owner = await contract.getOwnerDogeHero(dogeId)
    
    return {
        dogeId,
        seller,
        startingPrice,
        endingPrice,
        duration,
        owner,
        startedAt
    }
}

export const getSellingDogeInfoFunc = async (doges, contract) => {
    const arrayPromise = []

    for (let doge of doges) {
        const dogeSell = getSellingDogeInfo(doge, contract)
        arrayPromise.push(dogeSell)
    }
    const data = await Promise.all(arrayPromise)
    return data
}
const getSiringDogeInfo = async (contract, dogeId) => {
    const dogeInfo = await contract.getSiringDogeInfo(dogeId)
    const seller = dogeInfo[0]
    const startingPrice = dogeInfo[1]
    const endingPrice = dogeInfo[2]
    const duration = dogeInfo[3]
    const startedAt = dogeInfo[4]
    const owner = await contract.getOwnerDogeHero(dogeId)
    return {
        dogeId,
        seller,
        startingPrice,
        endingPrice,
        duration,
        owner,
        startedAt
    }

}

export const getListSiringDogeInfo = async (myDoges, contract) => {

    const arrayPromise = []
    for (let myDoge of myDoges) {
        arrayPromise.push(getSiringDogeInfo(contract, myDoge))
    }
    const data = await Promise.all(arrayPromise)
    return data
}