import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHistoryAttackAll, getHistoryBuySellAll, getHistorySiringAddress, getHistorySiringAll } from '../../Redux/action/api/dogeReducer';
import Table from '../Table';
import { strWallet } from '../../commons/blockchain'
import TableCustom from '../HistorySiringAll/TableHistoryBuySell';
import PaginationCustom from '../PaginationCustom';
import { saleAuction, siringAuction } from '../../contractAccount';
function HistorySiringAddress(props) {
    const dispatch = useDispatch()
    const { historySiringAddress } = useSelector(state => state.dogesApiReducer)
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const arrayTitle = [

        {
            title: "Image",
            key: "image"
        },
        {
            title: "Doge ID",
            key: "defender"
        },
        {
            title: "Trans ID",
            key: "status"
        },
        {
            title: "From",
            key: "reward"
        },
        {
            title: "To",
            key: "created_at"
        },
        {
            title: "Price",
            key: "created_at"
        },
        {
            title: "Time",
            key: "created_at"
        },
    ]
    const arraySrouce = []
    // if (HistorySiringAddress.array.length > 0) {
    //     for (let item of HistorySiringAddress?.array) {
    //         let obj = item
    //         let strStatus = 'Win'
    //         let classDiv = 'success'
    //         if (item?.status == 0) {
    //             classDiv = 'warning'
    //             strStatus = 'Draw'
    //         }
    //         else {
    //             strStatus = 'Lose'
    //             classDiv = 'danger'
    //         }
    //         obj.statusStr = <>
    //             <p className={`text-${classDiv}`}>{strStatus}</p>
    //         </>
    //         obj.attacker = strWallet(item?.attacker)
    //         obj.defender = strWallet(item?.defender)
    //         arraySrouce.push(obj)
    //     }
    // }
    function funcPagination(limit, page) {
        setLimit(limit)
        setPage(page)
    }
    useEffect(async () => {
        siringAuction.events.AuctionSuccessful({})
            .on('data', async event => {
                await dispatch(getHistorySiringAddress({ limit, page }))
            })
            .on('changed', changed => console.log(changed))
            .on('error', err => console.log('error', err.message, err.stack))
            .on('connected', str => console.log(str, "buy now AuctionSuccessful"))
    }, [])
    useEffect(async () => {
        await dispatch(getHistorySiringAddress({ limit, page }))
    }, [limit, page])
    return (
        <>
            <div className='bgHistory py-3'>
                <TableCustom arrayTitle={arrayTitle} arrayData={historySiringAddress?.array} title={`My Siring`} />
                <PaginationCustom total={historySiringAddress?.total} limit={limit} page={page} funcPagination={funcPagination} />
            </div>
        </>
    );
}

export default HistorySiringAddress;