import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import bnb from '../../asset/images/logobnb.png'
import './style.scss'
import Metamask from './Metamask';
import Binance from './Binance';
import { useDispatch, useSelector } from 'react-redux';
import { openPopupConnectAction } from '../../Redux/userSlice';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    bgcolor: '#fff',
    border: "1px solid rgba(0,0,0,.2);",
    boxShadow: 24,
    p: 4,
    maxWidth: "500px",
    borderRadius: "0.3rem",
    flex: '1 1 auto',
    padding: '1rem'
};

export default function ConnectWallet({ open, handleOpen, handleClose, handleChange }) {

    const { openPopupConnect } = useSelector(state => state.userReducer)
    const dispatch = useDispatch()
    return (
        <>

            <button className='connect' onClick={handleOpen}>Wallet Connect</button>
            <Modal
                open={open || openPopupConnect}
                onClose={() => {
                    handleClose()
                    handleChange()
                    dispatch(openPopupConnectAction(false))
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" sx={{
                        textAlign: "center"
                    }} variant="h6" component="h2">
                   
                            CONNECT TO A WALLET
                 
                    </Typography>
                    <Typography id="modal-modal-description" className="connect-wallet-wrap" sx={{ mt: 2 }} component="div">
                        <Metamask handleClose={handleClose} />

                        <Binance handleClose={handleClose} />
                        {/* <Typography className="connect-wallet-item" component="div">
                            <img src={bnb} width={40} height={40} alt="" />
                            <div>Binance Chain</div>
                        </Typography> */}
                    </Typography>
                </Box>
            </Modal>
        </>
    );
}
