import { useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Web3 from 'web3';
import { changeNetwork } from "../../../commons/blockchain";
import { connectWallet } from "../../../Redux/action/userReducer";

// import { resolveSrv } from 'dns';
let web3 = null
const injected = new InjectedConnector({ supportedChainIds: [56, 97] });
function Login({ handleClose }) {
    const dispatch = useDispatch()
    const { activate, account, library, connector, active, deactivate } = useWeb3React()

    async function handleSignMessage(publicAddress, nonce) {
        let signature = await web3.eth.personal.sign(web3.utils.fromUtf8("Log in with " + nonce), publicAddress)
        return signature;
    }
    async function handleClick(e) {
        try {
            e.preventDefault()
            if (!window.ethereum) {
                window.alert('Please install Metamask Wallet.');
                return;
            }
            await changeNetwork(window.ethereum)

            await activate(injected)
            const accountsS = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const addressWallet = accountsS[0];

            localStorage.setItem('wallet', JSON.stringify({ address: addressWallet, wallet: "metamask" }))
            setTimeout(() => {
                localStorage.removeItem('wallet')
            }, 60000 * 300)
            dispatch(connectWallet({ address: addressWallet, wallet: "metamask" }))
            window.location.reload()
            handleClose()
            return
            // connect()
            // return
            if (!window.web3) {
                window.alert('Please install MetaMask first.');
                return;
            }

            if (!web3) {
                web3 = new Web3(window.web3.currentProvider);
            }
            await activate(injected)
            // const network = await web3.eth.net.getId()
            // let signature = await handleSignMessage()
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            // var accounts = await web3.eth.getAccounts()
            // var check = checkNetwork(network)
            // if (!check) {
            //     messageError('Please switch to Binance Smart Chain network.')
            //     return;
            // }
            const address = accounts[0];
            await web3.eth.personal.sign(web3.utils.fromUtf8("Log in with " + address), address)

            // const checkAddress = await createApiBody({ address }, `api/user/getnonce`)
            // let nonce = checkAddress.data.data


            localStorage.setItem('wallet', JSON.stringify({ address, wallet: "metamask" }))
            dispatch(connectWallet({ address }))
            handleClose()
            // let nonce = 'asdasdasdasasfasf'



        } catch (error) {
            console.log(error);
        }
    }
  
    useEffect(() => {

    }, [])
    return (
        <div>
            {!window.web3 ?
                <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn" target="_blank" className="btn btn-dark w-100">Install MetaMask</a> :
                <>
                    <div className="connect-wallet-item" onClick={handleClick} >
                        <img src="https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg" width={40} height={40} alt="" />
                        <div>Metamask</div>
                    </div>
                    {/* <LoginFacebook /> */}
                </>
            }

        </div>
    );
}

export default Login;