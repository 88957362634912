import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { strWallet } from '../../../commons/blockchain';
import ConnectWallet from '../../../Component/ConnectWallet';
import ProfileWallet from '../../../Component/ProfileWallet';
import Logo from '../../../asset/images/logos.png'

import './style.scss'
function Header(props) {
    const { profile } = useSelector(state => state.userReducer)
    const [open, setOpen] = React.useState(false);
    const [active, setActive] = useState(false)
    const [change, setChange] = React.useState(false);
    const reponsive = window.innerWidth > 750 ? false : true
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleChange = (flag) => setChange(flag);
    const elementWallet = <li>{profile?.address && !open ?
        <ProfileWallet openPopup={open} handleChange={handleChange} handleOpenPopup={handleOpen} handleClosePopup={handleClose} address={strWallet(profile.address)} addressFull={profile.address} />
        :
        <ConnectWallet open={open} handleChange={handleChange} handleOpen={handleOpen} handleClose={handleClose} />}</li>
    const connectWallet = (flag) => {
        if (reponsive) {
            return <>
                {flag ?
                    elementWallet
                    :
                    <>   </>
                }
            </>
        } else {
            return <>
                {!flag ?
                    elementWallet
                    :
                    <></>
                }
            </>
        }
    }
    return (
        <>
            <header className="header">
                <div className="container-fluid">
                    <div className="containerCard" style={{ justifyContent: "flex-end", width: "90% !important", margin: "auto" }}>
                        <NavLink to="/" className="logo">
                            <img src={Logo} alt="" width="100%" height="100%" style={{
                                height: "100%",
                                maxHeight: "39px",
                                maxWidth: "53px",
                            }} />
                        </NavLink>
                        <input className="menu-btn" type="checkbox" checked={active} onClick={() => {
                            setActive(!active)
                        }} id="menu-btn" />
                        <label className="menu-icon" htmlFor="menu-btn"><span className="navicon" /></label>
                        <ul className="menu">
                            {connectWallet(true)}
                            <li><NavLink className="menuItem" onClick={() => {
                                setActive(!active)
                            }} to='/'>Marketplace</NavLink></li>
                            <li><NavLink className="menuItem" onClick={() => {
                                setActive(!active)
                            }} to="/siringauction">Siring Auction</NavLink></li>
                            <li><NavLink className="menuItem" onClick={() => {
                                setActive(!active)
                            }} to="/HavestArea" href="#careers">Harvest Area</NavLink></li>
                            {profile?.address && !open ? <li><NavLink onClick={() => {
                                setActive(!active)
                            }} className="menuItem" to="/mydoge">My Doge</NavLink></li> : ""}

                            {connectWallet()}
                        </ul>
                    </div>
                </div>

            </header>


        </>
    );
}

export default Header;