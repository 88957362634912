import React, { useState } from 'react';
import { Checkbox } from '@mui/material';
import HistoryAttackAll from '../HistoryAttackAll';
function RecentlyAttack({valueTab}) {
    const [checkBox, setCheckBox] = useState({

        allAttack: true,
        ownerAttack: false,
        lastMonth: false
    })
    function changeCheckBox(allAttack, ownerAttack, lastMonth) {
        setCheckBox({
            allAttack, ownerAttack, lastMonth
        })
    }
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    return (
        <>
            <div className='my-3 row'>
                <span className="Toolbar-generation--label col-6 col-lg-2">
                    <Checkbox {...label} checked={checkBox.allAttack} color="success" onChange={(value) => {

                        changeCheckBox(true, false)
                    }
                    } />
                    <span className='fz'>All Attack</span>
                </span>
                <span className="Toolbar-generation--label col-6 col-lg-2 ">
                    <Checkbox {...label} checked={checkBox.ownerAttack} color="success" onChange={(value) => {
                        changeCheckBox(false, true)
                    }
                    } />
                    <span className='fz'>Owner Attack</span>
                </span>
              
            </div>
            <HistoryAttackAll valueTab={valueTab} checkBox={checkBox} />
        </>
    );
}

export default RecentlyAttack;