import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllSellingDoges, getMyDogesAction, getMyDogesActionFunc, getMySellingDoges, getMySellingDogesFunc, getMySiringDoges } from './action/contract/dogeReducer';

export const myDogeDispatch = createAsyncThunk('doge/myDogeDispatch', async () => {
    try {
        const [myDoges, myDogesSelling] = await Promise.all([getMyDogesActionFunc(), getMySellingDogesFunc()])
        // const myDoges = await getMyDogesActionFunc()
        // const myDogesSelling = await getMySellingDogesFunc()
        return { myDoges : myDoges.myDoges, myDogesSelling }
    } catch (error) {

    }
})
const initialState = {
    myDoges: [],
    myDogesSelling: [],
    allDogesSelling : [],
    myDogesSiring : []

}
const doge = createSlice({
    name: 'doge',
    initialState,
    reducers: {
        loginAction: (state, action) => {
            state.login = action.payload
        },
    },
    extraReducers: {
        // getMySiringDoges
        [getMySiringDoges.fulfilled]: (state, action) => {
            
            state.myDogesSiring = action?.payload
        },
        [getMyDogesAction.fulfilled]: (state, action) => {
            state.myDoges = action.payload?.myDoges
        },
        [getMySellingDoges.fulfilled]: (state, action) => {
            state.myDogesSelling = action?.payload
        },
        [myDogeDispatch.fulfilled]: (state, action) => {
            state.myDoges = action.payload?.myDoges
            state.myDogesSelling = action?.payload?.myDogesSelling
        },
     
        
    }
});

const { reducer, actions } = doge;
export const {
    loginAction
} = actions;
export default reducer;