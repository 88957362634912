import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHistoryAttackAll, getHistoryBuySellAddress, getHistoryBuySellAll } from '../../Redux/action/api/dogeReducer';
import Table from '../Table';
import { strWallet } from '../../commons/blockchain'
import TableCustom from '../HistoryBuySellAll/TableHistoryBuySell';
import PaginationCustom from '../PaginationCustom';
function HistoryBuySellAddress(props) {
    const dispatch = useDispatch()
    const { historyBuySellAddress } = useSelector(state => state.dogesApiReducer)
    const { profile } = useSelector(state => state.userReducer)
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const arrayTitle = [

        {
            title: "Image",
            key: "image"
        },
        {
            title: "Doge ID",
            key: "defender"
        },
        {
            title: "Trans ID",
            key: "status"
        },
        {
            title: "From",
            key: "reward"
        },
        {
            title: "To",
            key: "created_at"
        },
        {
            title: "Price",
            key: "created_at"
        },
        {
            title: "Time",
            key: "created_at"
        },
    ]
    const arraySrouce = []
    // if (historyBuySellAll.array.length > 0) {
    //     for (let item of historyBuySellAll?.array) {
    //         let obj = item
    //         let strStatus = 'Win'
    //         let classDiv = 'success'
    //         if (item?.status == 0) {
    //             classDiv = 'warning'
    //             strStatus = 'Draw'
    //         }
    //         else {
    //             strStatus = 'Lose'
    //             classDiv = 'danger'
    //         }
    //         obj.statusStr = <>
    //             <p className={`text-${classDiv}`}>{strStatus}</p>
    //         </>
    //         obj.attacker = strWallet(item?.attacker)
    //         obj.defender = strWallet(item?.defender)
    //         arraySrouce.push(obj)
    //     }
    // }
    function funcPagination(limit,page){
        setLimit(limit)
        setPage(page)
    }
    useEffect(async () => {
        await dispatch(getHistoryBuySellAddress({ limit, page }))
    }, [limit,page,profile])
    return (
        <>
           <div className='bgHistory py-3'>
           <TableCustom arrayTitle={arrayTitle} arrayData={historyBuySellAddress?.array} title={`My Buy/Sell`} />
            <PaginationCustom total={historyBuySellAddress?.total} limit={limit} page={page} funcPagination={funcPagination} />
           </div>
      
        </>
    );
}

export default HistoryBuySellAddress;