import { getProvider } from '../commons/blockchain';
import Contract from '../contract';






export const contractServices = async () => {
    console.log("ok")
    let provider = null
    const wallet = JSON.parse(localStorage.getItem('wallet'))
    if (wallet) {
        if (wallet.wallet == "metamask") provider = window.ethereum
        else provider = window.BinanceChain
        const contract = new Contract()
        await contract.loadContract(provider)
        return contract
    }
    return null
};