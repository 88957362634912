import { createAsyncThunk } from "@reduxjs/toolkit"
import { convertTimeToString } from "../../../commons"
import Contract from "../../../contract"
import { createApiBody } from "../../../Services"
import { contractServices } from "../../../Services/contract"
import { getListSiringDogeInfo, getSellingDogeInfoFunc } from "../funcAction"




export const createDoge = async () => {
    const contract = await contractServices()
    await contract.mintHero(contract.currentUserAddress)
}

const getMySiringDogesFunc = async () => {
    try {
        const contract = await contractServices()
        const doges = await contract.getSiringDoges(contract.currentUserAddress)
        if (doges.length <= 0) return []
        const data = await getListSiringDogeInfo(doges, contract)
        return data
    } catch (error) {
    }

}
const getAllSellingDogesFunc = async () => {
    try {
        //get all selling doges on market
    const arrayReturn = []
    const contract = await contractServices()
    const doges = await contract.getAllSellingDoges()

    if (doges.length <= 0) return []
    const data = await getSellingDogeInfoFunc(doges, contract)
    return data
    } catch (error) {
    }
}
const getAllSiringDogesFunc = async () => {
    try {
        const contract = await contractServices()
        const doges = await contract.getAllSiringDoges()
        if (doges.length <= 0) return []
        const data = await getListSiringDogeInfo(doges, contract)
        return data
    } catch (error) {

    }

}
const giveBirth = async (matronId, contract) => {
    //kiểm tra xem doge có đang mang thai và có thể sinh được chưa
    const doge = await contract.getDoge(matronId)
    const isGestating = doge[0]
    const isReady = doge[1]
    // const cooldownIndex = doge[2]
    const nextActionAt = doge[3]
    if (isGestating) {
        if (isReady) {
            // await contract.giveBirth(matronId)
        }
        else {
            //mỗi block trên BSC khoảng 3 giây
            
            //ước lượng thời gian để hiển thị thời điểm chó sẽ đẻ cho người dùng
            // console.log('chó chưa đến thời điểm đẻ, sẽ đẻ vào block số:', nextActionAt)
        }
    } else {
    }
}

const getSellingDogeInfo = async (dogeId, contract) => {
    const dogeInfo = await contract.getSellingDogeInfo(dogeId)
    const seller = dogeInfo[0]
    const startingPrice = dogeInfo[1]
    const endingPrice = dogeInfo[2]
    const duration = dogeInfo[3]
    const startedAt = dogeInfo[4]
    const owner = await contract.getOwnerDogeHero(dogeId)
    return {
        dogeId,
        seller,
        startingPrice,
        endingPrice,
        duration,
        owner,
        startedAt
    }
}
export const getMySellingDogesFunc = async () => {
    try {
        const arrayReturn = []
        const contract = await contractServices()
        const doges = await contract.getSellingDoges(contract.currentUserAddress)
        if (doges.length <= 0) return []
        for await (let doge of doges) {
            const dogeSell = await getSellingDogeInfo(doge, contract)
            arrayReturn.push(dogeSell)
        }
        return arrayReturn
    } catch (error) {

    }
}
const getDogeFunc = async (contract, myDoge, arrayReturn) => {
    const doge = await contract.getDoge(myDoge)
    const isGestating = doge[0]
    const isReady = doge[1]
    const nextActionAt = doge[3]
    doge.dogeId = myDoge
    convertTimeToString(doge)
    doge.owner = await contract.getOwnerDogeHero(myDoge)
    arrayReturn.push(doge)
    if (isGestating) {
        if (isReady) {
            //có thể gọi hàm giveBirth để sinh ra chó con
            await giveBirth(myDoge, contract)
        } else {
            //hiển thị thời gian còn lại đến lúc có thể đẻ, tính bằng ngày/ giờ/ phút/ giây
        }
    }
}

const getListInfoDoge = async (myDoges, contract) => {
    const dataReturn = []
    const arrayPromise = []
    for (let myDoge of myDoges) {
        arrayPromise.push(getDogeFunc(contract, myDoge, dataReturn))
    }
    await Promise.all(arrayPromise)
    return dataReturn
}
export const getMyDogesActionFunc = async (limit,page) => {
    try {
        // contract //
        const contract = await contractServices()
        const myDoges = await contract.getHeroToken2(contract.currentUserAddress)
        if (myDoges.length <= 0) return []
        const data = await getListInfoDoge(myDoges, contract)
        // end contract //
        // contract //
        // console.log("address,limit,page",limit,page);
        // const wallet = JSON.parse(localStorage.getItem('wallet'))
        // const data = await createApiBody({
        //     limit,
        //     page :1,
        //     where : `owner='${wallet.address}'`
        // },'api/doge/getDoge')

        return {
            myDoges: data,
        }
    } catch (error) {

    }
}
export const getMyDogesAction = createAsyncThunk('user/getMyDoges', async (limit,page) => {
    try {
        const data = await getMyDogesActionFunc(limit,page)
        return data
    } catch (error) {
    }
})
export const getMySellingDoges = createAsyncThunk('user/getMySellingDoges', async () => {
    try {
        const data = await getMySellingDogesFunc()
        return data
    } catch (error) {

    }
})
export const getAllSellingDoges = createAsyncThunk('user/getMySellingDoges', async () => {
    try {
        const data = await getAllSellingDogesFunc()
        return data
    } catch (error) {

    }
})
export const getAllSiringDoges = createAsyncThunk('user/getAllSiringDoges', async () => {
    try {
        const data = await getAllSiringDogesFunc()

        return data
    } catch (error) {

    }
})

export const getMySiringDoges = createAsyncThunk('user/getMySiringDoges', async () => {
    try {
        const data = await getMySiringDogesFunc()

        return data
    } catch (error) {
        console.log(error, "getMySiringDogesFunc")

    }
})
