import Web3 from 'web3';
import Contract from '../contract';
import { BNB_BUSDContract, dogeWarInstance, saleAuction } from '../contractAccount';
import { approvedSellAction, loadingFuncAction, approvedSiringAction, profileAction, approvedDepositAction } from '../Redux/userSlice';
import { contractServices } from '../Services/contract';
import { InjectedConnector } from '@web3-react/injected-connector'
import Swal from 'sweetalert2';
import { port } from '../port';
let web3 = new Web3()
function getAmountOut(amountIn, reserveIn, reserveOut) {
    let amountInWithFee = amountIn * 9975
    let numerator = amountInWithFee * reserveOut
    let denominator = reserveIn * 10000 + amountInWithFee
    return (numerator / denominator)
}

export const getPriceBNB = async () => {
    try {
        const reserve = await BNB_BUSDContract.methods.getReserves().call();
        const reserveIn = reserve._reserve0
        const reserveOut = reserve._reserve1
        const price = getAmountOut(1, reserveIn, reserveOut)
        return price
    } catch (error) {
    }
}

const options = {}
export const eventData = async () => {
    saleAuction.events.AuctionSuccessful(options)
        .on('data', async event => {

        })
        .on('changed', changed => console.log(changed))
        .on('error', err => console.log('error', err.message, err.stack))
        .on('connected', str => console.log(str, "buy now AuctionSuccessful"))
}
export const injected = new InjectedConnector({ supportedChainIds: [97] })
export const getProvider = function (wallet) {
    let provider = null
    if (wallet.wallet == "metamask") provider = window.etherum
    else provider = window.BinanceChain
    return provider
}
export const networkId = () => {
    return '97'
}

export const eventListenWallet = (dispatch) => {

    let ethereum
    const wallet = JSON.parse(localStorage.getItem('wallet'))
    if (wallet) {
        if (wallet.wallet == 'binance') {
            ethereum = window.BinanceChain
        } else {
            ethereum = window.ethereum
        }

        if (ethereum) {
            ethereum.on('accountsChanged', accounts => {
                localStorage.setItem('wallet', JSON.stringify({ address: accounts[0], wallet: wallet.wallet }))
                setTimeout(() => {
                    localStorage.removeItem('wallet')
                }, 60000 * 300)
                dispatch(profileAction({ address: accounts[0] }))

                if (wallet.wallet != 'binance') {
                    window.location.reload()

                }

            })
            ethereum.on('chainChanged', (chainId) => {
                // localStorage.removeItem('wallet')
                // window.location.reload()
                // console.log("dissconnac")
                // dispatch(profileAction({ address: false }))
                // this.setState({ metamaskHasDisonnected: true })
            })

            ethereum.on('disconnect', () => {
                // localStorage.removeItem('wallet')
                // window.location.reload()
                // console.log("dissconnac")
                // dispatch(profileAction({ address: false }))
                // this.setState({ metamaskHasDisonnected: true })
            })
        }
    }

    // Listening to Event

}
export const funcWallet = async (dispatch, wallet) => {
    try {

        const contract = await contractServices()
        // dogeHeroInstance.methods.isApprovedForAll
        // dogeHeroInstance.methods.isApprovedForAll(owner, this.saleAuction._address)
        const approvedSellResQuery = contract.checkApproveToSellDoge(contract.currentUserAddress)
        const approvedSiringResQuery = contract.checkApproveToSireDoge(contract.currentUserAddress)
        const approvedDepositResQuery = contract.checkApproveToDepositDoge(contract.currentUserAddress)
        const [approvedSellRes, approvedSiringRes, approvedDepositRes] = await Promise.all([approvedSellResQuery, approvedSiringResQuery, approvedDepositResQuery])
        // loadingFuncAction
        dispatch(loadingFuncAction(false))
        dispatch(approvedSellAction(approvedSellRes))
        dispatch(approvedSiringAction(approvedSiringRes))
        dispatch(approvedDepositAction(approvedDepositRes))
    } catch (error) {
    }
    // Listening to Event

}
export const bigNumber = (amount) => {
    return web3.utils.toWei(amount)
}
// ChainID 0x38 mainnet, 56
// 0x61 test net 97 BSC
const chainId = 97
export const changeNetwork = async (provider) => {
    try {
        if (window.ethereum.networkVersion !== chainId) {
            const web3 = new Web3(provider)
            await provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.toHex(chainId) }],
            });
        }
    } catch (err) {
        if (err.code === 4902) {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    {
                        chainName: 'Smart Chain - Testnet',
                        chainId: web3.utils.toHex(chainId),
                        nativeCurrency: { name: 'BNBT', decimals: 18, symbol: 'BNBT' },
                        rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
                    },
                ],
            });
        }
    }
};
export const eventBlockchain = async (wallet, price) => {
    dogeWarInstance.events.AttackHero({}, (err, event) => {
        if (err) console.error('Error Attack Hero', err)
        else {
            let priceRuby = price.ruby
            let value = event.returnValues
            // || value.defender.toLowerCase() == wallet.address
            if (value.attacker.toLowerCase() == wallet.address) {
                let strStatus = 'Win'
                let classDiv = 'success'
                let valueReward = truncate(value.reward / 1e18, 2)
                let valueUSD = truncate(value.reward / 1e18 * priceRuby, 2)
                if (value.status == 0) {
                    classDiv = 'btnDetailFull'
                    strStatus = 'Draw'
                    valueReward = 0
                    valueUSD = 0
                }
                else if (value.status == 2 && wallet.address == value.defender.toLowerCase()) {
                    strStatus = 'Win'
                    classDiv = 'siringBtn'
                }
                else if (value.status == 1 && wallet.address == value.defender.toLowerCase()) {
                    strStatus = 'Lose'
                    classDiv = 'white btnBuyNow'
                    valueReward = 0
                    valueUSD = 0
                }
                else if (value.status == 2 && wallet.address == value.attacker.toLowerCase()) {
                    strStatus = 'Lose'
                    classDiv = 'white btnBuyNow'
                    valueReward = 0
                    valueUSD = 0
                }
                else if (value.status == 1 && wallet.address == value.attacker.toLowerCase()) {
                    strStatus = 'Win'
                    classDiv = 'siringBtn'
                }
                let htmlWin = ``
                if (strStatus == 'Win') {
                    htmlWin = `<div style='background-color: #f5f4f4;
                    border: 1px solid #e7e7e7;
                    border-radius: 5px;
                    display: flex;
                    justify-content: space-between;
                    padding: 5px 10px; margin-top:8px'>
                        <div className="textPrice" style='color: rgb(250, 194, 40);'>
                             Winning Ruby
                        </div>
                        <div className='priceGroupUSD'>
                            <b class="priceBNB" style='display: flex;
                            align-items: center;'>
                                <img src="/static/media/ruby.7b0e8763.png" height="35" width="35"/>&nbsp;${valueReward}
                            </b>
                            <p className='USDPrice' style='color: #fac228;
                            font-style: italic;
                            margin: 0;
                            font-size: 14px;
                            text-align: right;'>~&nbsp;$ ${valueUSD}</p>
                        </div>
                    </div>`
                }
                Swal.fire({
                    // title: `Attack Result`,
                    showConfirmButton: false,
                    html: `
                        <h2 style="font-size:1.5rem">
                            <img src='${port}images/attack.png'/> Attack Result
                        </h2>
                        <button class='${classDiv}' style="color:white;margin:0px;">${strStatus}</button>
                        ${htmlWin}
                        `
                })
            }
            // if (this.AttackDogeEvent) {
            //     this.AttackDogeEvent(event.returnValues)
            //     //console.log('event FoundVillage, dogelandId:', this.dogelandId)
            // }
        }
    })
}
export const isConnectMetamask = async (wallet) => {
    try {
        if (!web3) web3 = new Web3(window.web3.currentProvider);
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        // console.log(accounts,"accountsaccounts")
        // const accounts = await web3.getSigner(0)
        // console.log(accounts,"accountsaccounts")
        // await web3.eth.personal.sign(web3.utils.fromUtf8("Log in with " + address), address)
        if (accounts.length == 0) return false
        else console.log(" ");
        return {
            address: accounts[0]
        }
    } catch (error) {
        console.error("An error occurred: " + error);
    }
}
export const isConnectBinance = async () => {
    try {
        if (!web3) web3 = new Web3(window.BinanceChain);
        const accounts = await window.BinanceChain.request({ method: 'eth_requestAccounts' });
        // const accounts = await web3.eth.getAccounts()

        if (accounts.length == 0) return false
        else console.log("");
        return {
            address: accounts[0]
        }
    } catch (error) {
        console.error("An error occurred: " + error);
    }
}
export const strWallet = (str) => {
    var strStart = str?.slice(0, 4)
    var strEnd = str?.slice(str?.length - 3, str?.length)
    return `${strStart}...${strEnd}`
}
function toFixed(num, fixed) {
    fixed = fixed || 0;
    fixed = Math.pow(10, fixed);
    return Math.floor(num * fixed) / fixed;
}
function stringToFixed(num, fixed) {
    let string = num?.toString()?.split('.')
    let strReturn = ``
    if (string) {
         strReturn = `${string[0]}.`
        for (let i = 0; i < fixed; i++) {
            if(string[1]?.charAt(i) != undefined ){
                strReturn += string[1]?.charAt(i)
            }else{
             break;
            }
        }
    } return strReturn

}
export const truncate = (value, precision) => {
    let numner = stringToFixed(value, precision)
    let arrayNumber = numner?.toString()?.split('.')

    if (arrayNumber?.length > 0) {
        let Int = arrayNumber[0]?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        let Decimal = arrayNumber[1]
        return `${Int}${!Decimal ? '' : `.${Decimal}`}`
    } else {
        return ``
    }
}
// export const truncate = (value, precision) => {
//     var step = Math.pow(10, precision || 0);
//     var temp = Math.trunc(step * value);

//     var a = temp / step;
//     return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// }
export const checkNetwork = (network) => {
    var array = [56]
    ////97
    var flag = false

    array.forEach(element => {
        if (element == network) {
            flag = true
        }
    })
    return flag
}