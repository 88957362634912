// project imports
import React from 'react';
import PrivateRoute from '../core/PrivateRoute';
import Home from '../Screens/Home';
// import Login from '../Screens/Login';
import MainTemplate from '../Template/MainTemplate';
import Login from '../Screens/Login';
import MarketPlace from '../Screens/MarketPlace';
import MyDoge from '../Screens/MyDoge';
import SiringAuction from '../Screens/SiringAuction';
import DetailDoge from '../Screens/DetailDoge';
import HavestArea from '../Screens/HavestArea';
import DetailHero from '../Screens/DetailHero';

// dashboard routing

// utilities routing


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {

    path: "/",
    element: < MainTemplate />,
    children: [{
        path: '/',
        element: <MarketPlace />,

    },
    {
        path: '/hero/:owner',
        element: <DetailHero />,

    },
    {
        path: '/doge/:dogeId',
        element: <DetailDoge />,

    },
    {
        path: '/HavestArea',
        element: <HavestArea />,

    },
    // {
    //     path: '/mydoge',
    //     element: <MyDoge />,

    // },
    {
        path: '/mydoge',
        element: <PrivateRoute />,
        children: [{
            path: '/mydoge',
            element: < MyDoge />
        },
        ]
    },
    {
        path: '/siringauction',
        element: <SiringAuction />,

    }
    ]

};

export default MainRoutes;