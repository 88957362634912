import React from 'react';
import { NavLink } from 'react-router-dom';
import { strWallet, truncate } from '../../../commons/blockchain';
import { port } from '../../../port';
import BNB from '../../../asset/images/bnb.png'
import './style.scss'
import sold from '../../../asset/images/sold.png'
import { useSelector } from 'react-redux'
function TableCustom({ arrayTitle, arrayData, title }) {
    const { priceCoin } = useSelector(state => state.userReducer)
    const elementTitle = arrayTitle?.map((itemTitle, index) => { return <th className='tdCenter' key={index}>{itemTitle.title}</th> })
    const priceRuby = priceCoin.ruby
    const elementData = arrayData?.map((item, index) => {

        return (
            <>
                <div className=" col-md-6 col-12 col-lg-4 col-xl-3  py-2 px-3">
                    <div className="cardHistory">
                        <div className="itemFlex">
                            <div className="imageDoge">
                                <div className='bgDoge' style={{
                                    backgroundImage: `url('https://market.worldofdoge.io/backgroup/0.png?folder=5')`,
                                    borderRadius: "50%"

                                }}>
                                    <img src={`${port}${item.image}`} width={50} height={50} alt="" />
                                </div>
                                <p>#{item.tokenId}</p>
                            </div>
                            <div className="detailDoge ml-2">
                                <div className="itemDetail">
                                    <p>Trans ID:&nbsp;</p>
                                    <a className='text-pink' href={`https://bscscan.com/tx/${item.transaction}`}>{strWallet(item.transaction)}</a>
                                </div>
                                <div className="group">
                                    <div className="itemDetail">
                                        <p>From:&nbsp;</p>
                                        <a className='text-pink' href={`https://bscscan.com/address/${item.owner}`} target="_blank"> {strWallet(item.owner)}</a>
                                    </div>
                                    <div className="itemDetail ml-2">
                                        <p>To:&nbsp;</p>
                                        <a className='text-pink' href={`https://bscscan.com/address/${item.winner}`} target="_blank"> {strWallet(item.winner)}</a>
                                    </div>
                                </div>
                                <div className="group">
                                    <div className='priceGroup'>
                                        <b class="priceBNB">
                                            <img src={BNB} height={20} />&nbsp;{truncate(item.price, 6)}
                                        </b>
                                        <p>~$ {truncate(item.price * priceCoin.BNB, 2)}</p>
                                    </div>
                                    <div className="time">
                                        <p>
                                            {item.created_at}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )

    })
    return (
        <div className="container-fluid ">
            <div className="row">
                <div className="col-12">
                    <h3
                        className="card-title fzTitle"
                        style={{ color: "black !important" }}
                    >
                        <img src={sold} alt="" width={50} /> {title}
                    </h3>

                </div>
                <div className="row">
                    {elementData}


                </div>
            </div>
        </div>
    );
}

export default TableCustom;