import React, { useState } from 'react';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Wod from '../../asset/images/dogepts.png'
import WithSpin from '../../HOC/WithSpin';
import { port } from '../../port';
import { createApiBody } from '../../Services';
function CardParent({ dogeId }) {
    const [doge, setDoge] = useState({})
    const [isSpin, setIsSpin] = useState(true)
    useEffect(async () => {
        if (dogeId) {
            let data = {}
            data.where = `dogeId = ${dogeId}`
            const dataReturn = await createApiBody(data, 'api/doge/getDogeDetail')
            setDoge(dataReturn.data.data)
            setIsSpin(false)
        }
    }, [dogeId])
    return (
        <>
            <WithSpin color={"#000"} isSpin={isSpin}>
                <NavLink to={`/doge/${doge.dogeId}`} className={` reponsive cardParent`} style={{
                    width: "150px",
                    height: "150px",
                    margin:"5px",
                    background: `${doge.background}`
                }} hover={true}  >
                    {/* <h2 className="name" >ID: {item.dogeId}</h2> */}
                    {/* <p className='id'>ID : 13</p> */}
                    {/* <h2 className="price" >0.1 BNB</h2> */}

                    {/* <button className='buyNow buy' onClick={() => handleClickOpen(item,truncate(price/1e18,6))} >Buy Now</button> */}
                    {/* <a href="#" className='detail'  onClick={() => onShow(index)}>Detail</a> */}
                    {/* <div className="circle" style={{
                                    width: "100px",
                                    height: "100px",
                                    top: "50%",
                                }}></div> */}
                    {/* {image} */}
                    <img src={`${port}${doge.image}`} alt="" style={{
                        top: "50%",
                        left: "52%",
                        maxWidth: "150px",
                        minWidth: "100px",
                        width: "30%"
                    }} className='product' />
                </NavLink>
            </WithSpin>
        </>
    );
}

export default CardParent;