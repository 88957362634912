import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { createDoge } from '../../Redux/action/contract/dogeReducer';
import { getMyDogesAction, getMySellingDoges } from '../../Redux/action/api/dogeReducer';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import CardMyDoge from '../../Component/Card/CardMyDoge';
import DetailItem from '../../Component/Card/DetailItem';
import { useState } from 'react';
import PopupSell from '../../Component/Card/CardMyDoge/PopupSell';
import { toastSuccessMessage } from '../../commons/message';
import { myDogeDispatch } from '../../Redux/dogeSlice';
import WithLoading from '../../HOC/WithLoading';
import MyDogeSiring from '../../Component/MyDogeSiring';
import PopupBreed from '../../Component/PopupBreed';
import MyDogeParent from '../../Component/MyDogeParent';
import banner from '../../asset/images/banner.jpg'

import PaginationCustom from '../../Component/PaginationCustom';
import { funcWallet } from '../../commons/blockchain';
import PopupSiring from '../../Component/CardSiringAuction/CardItem/PopupSiring';
import MyDogeDeposit from '../../Component/MyDogeDeposit';
import './style.scss'
import HistoryBuySellAddress from '../../Component/HistoryBuySellAddress';
import HistorySiringAddress from '../../Component/HistorySiringAddress';
import PopupReward from '../../Component/Card/CardMyDoge/Reward';
function TabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export default function BasicTabs() {
    const [openReward, setOpenReward] = useState({
        flag: false,
        doge: {},
        price: 0
    })

    function handleClickOpenReward() {
        setOpenReward({
            flag: true,

        });
    };
    const [value, setValue] = useState(0);
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(true)
    const { myDoges, myDogesSelling } = useSelector(state => state.dogesApiReducer)

    const [dogeVisible, setDogeVisible] = React.useState({ visible: false, indexDoge: 0 })
    const [openSell, setOpenSell] = useState({
        flag: false,
        doge: {}
    })
    const [openBread, setOpenBread] = useState({
        flag: false,
        doge: {}
    })
    const [limit, setLimit] = useState(12)
    const [page, setPage] = useState(1)
    const [open, setOpen] = useState(false);
    const reponsive = window.innerWidth<750?true:false

    const handleCloseReward = () => {
        setOpenReward({
            flag: false
        });
    };
    const handleCloseBreed = () => {
        setOpenBread({
            flag: false
        });
    };
    const handleClickOpenBreed = (doge, siring) => {
        // doge.price = 
        setOpenBread({
            flag: true,
            doge,
        });
    };
    const handleClickOpen = (doge, siring) => {
        setOpenSell({
            flag: true,
            doge,
            siring: siring ? true : false
        });
    };

    const handleClose = () => {
        setOpenSell({
            flag: false
        });
    };
    function onShow(index) {
        setDogeVisible({
            visible: true,
            indexDoge: index
        })
    }
    function onCloseDraw() {
        setDogeVisible({
            ...dogeVisible,
            visible: false

        })
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const hanldeCreateDoge = async () => {
        await createDoge()
        toastSuccessMessage(`Create successful doge! `)
        await dispatch(getMyDogesAction())
    }
    React.useEffect(async () => {
        // await dispatch(myDogeDispatch())
        //  await dispatch(getMyDogesAction())
        setIsLoading(true)
        const wallet = JSON.parse(localStorage.getItem('wallet'))
        if (wallet) {
            const getMySellingDogesQuery = dispatch(getMySellingDoges({ limit, page }))
            const funcWalletQuery = funcWallet(dispatch, wallet)
            await Promise.all([getMySellingDogesQuery, funcWalletQuery])
        }
        setIsLoading(false)

    }, [limit, page])
    const LoadingmyDogesSelling = WithLoading(myDogesSellingComponent)
    function myDogesComponent() {
        return (
            <>
                {myDoges?.map((item, index) => {
                    return <CardMyDoge item={item} index={index} sell={true} handleClickOpen={handleClickOpen} onShow={onShow} />
                })}
            </>
        )
    }
    const [checkBox, setCheckBox] = useState({
        flag: false,
        doge: [],
    })
    function funcPagination(limit, page) {
        setIsLoading(true)
        setLimit(limit)
        setPage(page)
    }

    function myDogesSellingComponent() {
        return (
            <>
                {myDogesSelling?.array?.map((item, index) => {
                    return <CardMyDoge item={item} index={index} checkBox={checkBox} handleClickOpen={handleClickOpen} onShow={onShow} />
                })}
            </>
        )
    }
    const [index, setIndex] = useState(0)
    return (
        <>
            <div>
                <img src={banner} alt="" width="100%" height="100%" />
            </div>
            <div className='main layerbg pb-3'>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} className="treee pl-4" sx={{ overflow: "auto" }} aria-label="basic tabs example">
                            <Tab sx={{
                                color: "white"
                            }} label={reponsive?'Doges':"My doges"} {...a11yProps(0)} />
                            <Tab sx={{
                                color: "white"
                            }} label={reponsive?'Sale':"My Doge is for Sale"} {...a11yProps(1)} />
                            <Tab sx={{
                                color: "white"
                            }} label={reponsive?'Siring':"My Doge is for Siring"} {...a11yProps(2)}
                                onClick={() => {
                                    setIndex(2)
                                }} />
                            <Tab sx={{
                                color: "white"
                            }} label={reponsive?'Deposit':"My Doge is Deposit"} {...a11yProps(3)} />
                            <Tab sx={{
                                color: "white"
                            }} label="REWARD" {...a11yProps(0)} onClick={() => handleClickOpenReward()} />
                            {/* <button style={{
                                color: "white",
                                background: "none",
                                border: "none",
                                padding: "12px 16px"
                            }} onClick={() => handleClickOpenReward()}
                            >REWARD</button> */}

                        </Tabs>
                    </Box>
                    <TabPanel value={value} sx={{
                        border: "inherit"
                    }} index={0}>
                        {/* <button className='btn btn-dark' onClick={hanldeCreateDoge}>Create a new Doge</button> */}
                        {/* <button className='btn btn-dark ml-1' onClick={handleClickOpenBreed}>Breed my Doge</button> */}
                        <MyDogeParent handleClickOpen={handleClickOpen} handleClickOpenBreed={handleClickOpenBreed} bread={true} onShow={onShow} />
                    </TabPanel>
                    <TabPanel value={value} sx={{
                        border: "inherit"
                    }} index={1}>
                        <div className='containerCard'>
                            <LoadingmyDogesSelling isLoading={isLoading} myDogesSelling={myDogesSelling?.array} />
                        </div>
                        <PaginationCustom limit={limit} page={page} color="#fff" funcPagination={funcPagination} total={myDogesSelling?.total} />


                    </TabPanel>
                    <TabPanel value={value} sx={{
                        border: "inherit"
                    }} index={2}>
                        <MyDogeSiring handleClickOpen={handleClickOpen} onShow={onShow} />

                    </TabPanel>
                    <TabPanel value={value} sx={{
                        border: "inherit"
                    }} index={3}>
                        <MyDogeDeposit />
                    </TabPanel>
                    <TabPanel value={value} sx={{
                        border: "inherit"
                    }} index={4}>
                        {/* <MyDogeDeposit /> */}
                        <MyDogeParent handleClickOpen={handleClickOpen} handleClickOpenBreed={handleClickOpenBreed} bread={true} onShow={onShow} />
                    </TabPanel>
                </Box>
            </div>
            {value == 1 ?
                <>
                    <HistoryBuySellAddress />
                </>
                : <></>
            }
            {value == 2 ?
                <>
                    <HistorySiringAddress />
                </>
                : <></>
            }

            <DetailItem onClose={onCloseDraw} onShow={onShow} visible={dogeVisible.visible} indexDoge={dogeVisible.indexDoge} />
            <PopupReward handleClose={handleCloseReward} handleClickOpen={handleClickOpenReward} open={openReward} />
            <PopupSell handleClose={handleClose} handleClickOpen={handleClickOpen} open={openSell} />
            {/* <PopupBreed handleClose={handleCloseBreed} open={open} myDoges={myDoges?.array} /> */}
            <PopupSiring handleClose={handleCloseBreed} handleClickOpen={handleClickOpenBreed} doge={openBread.doge} price={openBread.price} myBread={true} open={openBread} />
        </>
    );
}
