import { createAsyncThunk } from "@reduxjs/toolkit"
import { convertTimeToString } from "../../../commons"
import Contract from "../../../contract"
import { contractServices } from "../../../Services/contract"
import { profileAction } from "../../userSlice"

const contract = contractServices()

export const getBalanceAction = createAsyncThunk('user/getBalance', async () => {
    try {
        
        const rubyQuery = contract.getRubyBalance(contract.currentUserAddress)
        const wodQuery = contract.getWODBalance(contract.currentUserAddress)
        const [ruby, wod] = await Promise.all([rubyQuery, wodQuery])
        return {
            address: contract.currentUserAddress,
            rubyBalance: ruby / 10 ** 18,
            wodBalance: wod / 10 ** 18
        }
    } catch (error) {
        console.log(error, "GetBalance");
    }
})
export const test = async () => {
    try {
        await contract.loadContract()
        const doges = await contract.getSellingDoges(contract.currentUserAddress)
        if(doges.length > 0){
          //get selling info
          for(let i = 0; i < doges.length; i++){
            await this.getSellingDogeInfo(doges[i])
          }
        }
    } catch (error) {
        console.log(error, "test")
    }
}
// export const app = async () => {
//     return async (dispatch) => {
//         try {
//             dispatch(getBalance())
//         } catch (error) {

//         }
//     }
// }