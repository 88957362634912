import React from 'react';
import './style.scss'
import logo from '../../../asset/images/logo.png'
function Footer(props) {
    return (
        <>
            <div className='footer'>
                <div className='item'>
                    <img src={logo} width={100} height={100} alt="" />
                    <p>Copyright © 2022 Market.worldofdoge.io
                    </p>
                </div>
            </div>
        </>
    );
}

export default Footer;