import React, { useState } from 'react';
import { Checkbox } from '@mui/material';
import HistoryAttackAll from '../HistoryAttackAll';
function CurrentAttack({ valueTab }) {
    const [checkBox, setCheckBox] = useState({

        today: true,
        week: false,
        month: false
    })
    function changeCheckBox(today, week, month) {
        setCheckBox({
            today, week, month
        })
    }
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    return (
        <>
            <div className='my-3 row'>
                <span className="Toolbar-generation--label col-4 col-lg-2">
                    <Checkbox {...label} checked={checkBox.today} color="success" onChange={(value) => {

                        changeCheckBox(true, false, false)
                    }
                    } />
                    <span className='fz'>Today</span>
                </span>
                <span className="Toolbar-generation--label  col-4 col-lg-2">
                    <Checkbox {...label} checked={checkBox.week} color="success" onChange={(value) => {
                        changeCheckBox(false, true, false)
                    }
                    } />
                    <span className='fz'>Week</span>
                </span>
                <span className="Toolbar-generation--label col-4 col-lg-2">
                    <Checkbox {...label} checked={checkBox.month} color="success" onChange={(value) => {
                        changeCheckBox(false, false, true)
                    }
                    } />
                    <span className='fz'>Month</span>
                </span>
            </div>
            <HistoryAttackAll checkBox={checkBox} statistical={true} valueTab={valueTab} current={true} />
        </>
    );
}

export default CurrentAttack;