import React, { useEffect, useState } from 'react';
import './style.scss'
function Sreach(props) {
    const [card, setCard] = useState(false)
    useEffect(() => {
        const options = { speed: 400, max: 25 }
        // tilt.hover()
        const width = window.innerWidth
        if (width < 800) setCard(true)

    }, [])
    return (
        <>
            <div className={`sreachBox ml-5 float-right ${card ? 'sreachReponsive' : ''}`}>
                <div className='shadow'></div>
                <input type="text" placeholder='Sreach' />
                <ion-icon name="search-outline"></ion-icon>
            </div>
        </>
    );
}

export default Sreach;