import { Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WithLoading from '../../HOC/WithLoading';
import { getMyDogesAction, getMyDogesSelectAction } from '../../Redux/action/api/dogeReducer';
import CardMyDoge from '../Card/CardMyDoge';
import PaginationCustom from '../PaginationCustom';
import './style.scss'
function SelectMyDoge({ reponsive, handleSelect,dogeBread,breadMyDoge,popupSiring }) {
    const [isLoading, setIsLoading] = useState(true)
    const { myDoges, myDogesSelling,myDogesSelect } = useSelector(state => state.dogesApiReducer)
    const dispatch = useDispatch()
    const [limit, setLimit] = useState(12)
    const [page, setPage] = useState(1)
    const LoadingMyDoge = WithLoading(myDogesComponent)
    function funcPagination(limit, page) {
        // dispatch(getMyDogesAction({ limit, page }))
        setLimit(limit)
        setPage(page)
        setIsLoading(true)
    }
    function myDogesComponent() {
        if (myDogesSelect?.array?.length <= 0) return <Empty/>
        return (
            <>
                {myDogesSelect?.array?.map((item, index) => {
                    return <CardMyDoge popupSiring={popupSiring} item={item} index={index} breadMyDoge={breadMyDoge} dogeBread={dogeBread} checkBox={{ flag: false }} handleSelect={handleSelect} reponsive={reponsive} breadMyDoge={true} selectItem={true} reponsive={true} sell={true} />
                })}
            </>
        )
    }
    useEffect(async () => {
        // await dispatch(getMyDogesAction())
        document.getElementById('selectMyDoges').scrollTop = 0
        // popupMyDoges
        if(popupSiring){
            await dispatch(getMyDogesSelectAction({ limit, page,dogeBread,selectSiring:true }))
        }else{
            await dispatch(getMyDogesSelectAction({ limit, page,dogeBread }))
        }
        setIsLoading(false)
    }, [limit, page])
    return (
        <>
            <div className=' popupMyDoges' id="selectMyDoges">
                <div className='containerCard'>
                    <LoadingMyDoge isLoading={isLoading} myDoges={myDogesSelect} />
                </div>
                <PaginationCustom total={myDogesSelect.total} limit={limit} page={page} funcPagination={funcPagination} />

            </div>
        </>
    );
}

export default SelectMyDoge;