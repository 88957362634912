import * as React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Skeleton from '@mui/material/Skeleton';

function LoadingCard(props) {
    const array = [1, 2, 3, 4, 5, 6, 7, 8,9,10]
    const { loading = true } = props;
    return (
        <>
            {array.map((item, index) => {
                return (
                    <Card sx={{ maxWidth: 300, m: 2 }}>
                        <CardHeader
                            avatar={
                                <Skeleton animation="wave" variant="circular" sx={{
                                    textAlign: "center"
                                }} width={200} height={200} />
                            }
                            action={
                                null
                            }
                            title={
                                <Skeleton
                                    animation="wave"
                                    height={10}
                                    width="80%"
                                    style={{ marginBottom: 6 }}
                                />
                            }
                            subheader={
                                <Skeleton animation="wave" height={10} width="40%" />
                            }
                        />
                        {/* <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" /> */}


                        <CardContent>
                            <React.Fragment>
                                <Skeleton animation="wave" height={10} width="30%" style={{ marginBottom: 6, margin: "6px auto" }} />
                                <Skeleton animation="wave" height={10} width="70%" style={{ marginBottom: 6, margin: "6px auto" }} />
                                <Skeleton animation="wave" height={10} width="90%" style={{ marginBottom: 6, margin: "6px auto" }} />
                                <Skeleton animation="wave" height={10} width="90%" style={{ marginBottom: 6, margin: "6px auto" }} />

                            </React.Fragment>
                        </CardContent>
                    </Card>
                )
            })}
        </>
    );
}

export default LoadingCard;