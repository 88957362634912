import React from 'react';
import { NavLink } from 'react-router-dom';
import { strWallet, truncate } from '../../../commons/blockchain';
import Ruby from '../../../asset/images/ruby.png'
import { useSelector } from 'react-redux';
function TableCustom({ arrayTitle, arrayData, title }) {
    const { priceCoin, profile } = useSelector(state => state.userReducer)
    const elementTitle = arrayTitle?.map((itemTitle, index) => { return <th className='tdCenter' key={index}>{itemTitle.title}</th> })
    const priceRuby = priceCoin.ruby
    const elementData = arrayData?.map((item, index) => {
        let strStatus = 'Win'
        let classDiv = 'success'
        if (item?.status == 0) {
            classDiv = 'warning'
            strStatus = 'Draw'
        }
        else if (item?.status == 2) {
            strStatus = 'Lose'
            classDiv = 'danger'
        }
        return <tr>
            <td className='tdCenter'>
                <NavLink to={`/hero/${item.attacker}`}>{strWallet(item.attacker)}</NavLink>
            </td>
            <td className='tdCenter'>
                <NavLink to={`/hero/${item.defender}`}>{strWallet(item.defender)}</NavLink>
            </td>
            <td className={`text-${classDiv} tdCenter`}>{strStatus}</td>
            <td className='tdCenter'>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src={Ruby} height={35} />
                    <div className='ml-1 text-left'>
                        <b className='m-0'>       {truncate(item.reward, 0)}</b>
                        <br></br>
                        <span className='USD'>$ {truncate(item.reward * priceRuby, 2)}</span>
                    </div>
                </div>

            </td>
            <td className='tdCenter time'>{item.created_at}</td>
        </tr>
    })
    return (
        <div className="card">
            <div className="card-header">
                <h3
                    className="card-title fzTitle"
                    style={{ color: "black !important" }}
                >
                    {title}
                </h3>
            </div>
            {/* /.card-header */}
            <div className="card-body table-responsive p-0">
                <table className="table table-hover text-nowrap">
                    <thead>
                        <tr>{elementTitle}</tr>
                    </thead>
                    <tbody>{elementData}</tbody>
                </table>
            </div>
        </div>
    );
}

export default TableCustom;