import Swal from "sweetalert2"
import { toast } from 'react-toastify';
export const toastSuccessMessage = (message) =>{
    
    toast.success(message, {
        // position: "top-right",
        // autoClose: 5000,
        // hideProgressBar: false,
        // closeOnClick: true,
        // pauseOnHover: true,
        // draggable: true,
        // progress: undefined,
    });
}
export const toastErrorMessage = (message) =>{
    toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
}
export const messageSuccess = (message) => {
    Swal.fire({
        timer: 1500,
        title: message,
        icon: "success",
        showConfirmButton: false,
    })
}
export const messageError = (message) => {
    Swal.fire({
        timer: 1500,
        title: message,
        icon: "error",
        showConfirmButton: false,
    })
}