import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import VanillaTilt from 'vanilla-tilt';
import { funcWallet, strWallet, truncate } from '../../../commons/blockchain';
import { toastErrorMessage, toastSuccessMessage } from '../../../commons/message';
import Contract from '../../../contract';
import { port } from '../../../port'
import { Statistic } from 'antd';
import './style.scss'
import WithSpin from '../../../HOC/WithSpin';
import { getMyDogesAction } from '../../../Redux/action/api/dogeReducer';
import { getMySiringDoges } from '../../../Redux/action/contract/dogeReducer';
import { myDogeDispatch } from '../../../Redux/dogeSlice';
import { NavLink } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import { contractServices } from '../../../Services/contract';
import logoBNB from '../../../asset/images/bnb.png'
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const { Countdown } = Statistic;

function CardMyDoge({ item, selectDoge, checkBox, index, onShow, popupSiring, deposit, breadMyDoge, selectItem, handleClickOpen, dogeBread, bread, sell, siring, reponsive, handleSelect, handleClickOpenBreed, limit, page }) {

    const tilt = useRef(null);
    const [card, setCard] = useState(false)
    const [copy, setCopy] = useState(false)
    const [isSpinDoge, setIsSpinDoge] = useState(false)
    const [isSpin, setIsSpin] = useState({
        siring: false,
        sell: false,
        cancel: false
    })
    const { approvedSell, approvedSiring, loadingFunc, priceCoin } = useSelector(state => state.userReducer)


    const inputArea = React.useRef(null);
    const [, setPrice] = useState(0)
    const dispatch = useDispatch()
    async function cancel(dogeId) {
        try {

            setIsSpin({ cancel: true })
            const contract = await contractServices()
            if (siring) {
                await contract.cancelSiring(dogeId)
                await dispatch(getMySiringDoges())
            }
            else {
                await contract.cancelSelling(dogeId)
                await dispatch(myDogeDispatch())
            }
            window.location.reload()
            setIsSpin({ cancel: false, ...isSpin })
            toastSuccessMessage(`Cancel success`)
        } catch (error) {
            setIsSpin({ ...isSpin })
        }
    }
    async function approved(siring) {
        try {
            if (siring) setIsSpin({ ...isSpin, siring: true })
            else setIsSpin({ ...isSpin, sell: true })
            const contract = await contractServices()
            if (siring) await contract.approveToSireDoge()
            else await contract.approveToSellDoge()
            setIsSpin({ siring: false, sell: false, cancel: false })
            toastSuccessMessage(`Approved success`)
            await dispatch(getMyDogesAction({ limit: 12, page: 1 }))
            await funcWallet(dispatch)
        } catch (error) {
            toastErrorMessage(`The transaction has been declined`)
            setIsSpin({ siring: false, sell: false, cancel: false })
        }

    }
    function handleCopy(e) {
        navigator.permissions
            .query({ name: "clipboard-write" })
            .then((result) => {
                if (result.state === "granted" || result.state === "prompt") {
                    updateClipboard(inputArea.current?.innerText);
                }
            });
    }
    function updateClipboard(newClip) {
        navigator.clipboard.writeText(newClip).then(
            () => {
                setCopy(true);
                toast.success('Copied!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            },
            () => {
                setCopy(false);
                // setOpen(false)

            }
        );
    }
    function select(doge) {
        handleSelect(doge)
    }
    useEffect(async () => {
        const options = { speed: 400, max: 25 }
        // tilt.hover()
        const width = window.innerWidth
        if (width < 800 || reponsive) setCard(true)
        // else VanillaTilt.init(tilt.current, options);



    }, [])
    //// element func ////////ss/
    const elementFunc = () => {
        if (reponsive) {
            return (
                <>

                </>
            )
        } else if (deposit) {
            return (
                <NavLink to={`/doge/${item.dogeId}`}
                    style={{
                        margin: "auto"
                    }}
                    className='buttonDetail btnVang' ref={tilt} onClick={() => onShow(index)}>
                    Detail
                </NavLink>
            )
        } else {
            return (
                <>
                    {/* item.timeIsGestating */}
                    {item.isGestating == 1 || item.timeIsGestating && item.isReady != 1 ?
                        ///// doge có thai h0ạc đangg thời gian đẻ 
                        <>
                            {/* ///// NEW ////  */}
                            {item.timeIsGestating ?
                                <>
                                    {item.isGestating == 1 ?
                                        <div style={{
                                            position: "absolute",
                                            bottom: "14%",
                                            right: "0",
                                        }}>
                                            <p className='waitingTime fzs'>Pregnant</p>
                                        </div>
                                        :
                                        <div style={{
                                            position: "absolute",
                                            bottom: "14%",
                                            right: "0",
                                        }}>
                                            <p className='waitingTime fzs'>Resting</p></div>}
                                </> :
                                <></>
                            }
                            <button className='btnSiring buttonBuy  ' onClick={() => giveBirth(item.dogeId)} style={item.timeIsGestating ? { cursor: "initial" } : {}} disabled={item.timeIsGestating || isSpinDoge}>
                                {/* Đang có thái */}
                                <WithSpin isSpin={isSpinDoge}>
                                    {item.timeIsGestating ?
                                        <>

                                            <Countdown className='fzs' value={new Date().getTime() + item?.timeIsGestating} onFinish={() => {
                                                if (new Date().getTime() + item.timeIsGestating) {
                                                    dispatch(getMyDogesAction({ limit, page }))
                                                }
                                                dispatch(getMyDogesAction({ limit, page }))
                                            }} format="H : m : s " />
                                        </>
                                        :
                                        <>
                                            {
                                                // item.isGestating == 1 && item.isReady== 1  ?
                                                <>
                                                    Give birth
                                                </>
                                            }
                                        </>
                                    }
                                </WithSpin>

                            </button>
                        </> :
                        //// Nếu không có thì đươc quyền thực hiện các chức năng
                        approvedSell ?
                            <button className={`buttonBuy btnDo`} disabled={sell ? false : isSpin.cancel} onClick={() => sell ? handleClickOpen(item) : cancel(item.dogeId)} ref={tilt}>
                                <WithSpin isSpin={sell ? false : isSpin.cancel}>
                                    {`${sell ? 'Sell Now' : siring ? 'Cancel Siring' : 'Cancel Sale'}`}
                                </WithSpin>

                            </button>


                            :
                            <button className={`buttonBuy btnDo btnApprovedMobile`} disabled={sell ? loadingFunc || isSpin.sell : isSpin.siring} onClick={() => approved()} ref={tilt}>
                                <WithSpin isSpin={sell ? loadingFunc || isSpin.sell : isSpin.siring}>
                                    {`${sell ? 'Approved Sell' : siring ? 'Cancel Siring' : 'Cancel Sale'}`}
                                </WithSpin>
                            </button>
                    }
                    <NavLink to={`/doge/${item.dogeId}`} className='buttonDetail btnVang' ref={tilt} onClick={() => onShow(index)}>
                        Detail
                    </NavLink>
                </>
            )
        }
    }
    const elementSiring = () => {
        if (reponsive) {
            return (
                <>
                    <button className='btnSelect btnSelectMobile' onClick={() => select(item)} >Select</button>
                </>
            )
        } else {
            return (
                <>
                    {!sell ?
                        `` :

                        approvedSiring ?
                            // <button className='btn btn-secondary ' disabled={loadingFunc} onClick={() => handleClickOpen(item, true)} style={{ width: "30%" }}>

                            //     <WithSpin isSpin={loadingFunc}>
                            //         Siring
                            //     </WithSpin>
                            // </button>
                            <>
                            </>
                            :
                            <>
                            </>
                        // <button className='btn btn-secondary ' disabled={loadingFunc || isSpin.siring} onClick={() => approved(true)} style={{ width: "50%" }}>
                        //     <WithSpin isSpin={loadingFunc || isSpin.siring}>
                        //         Approved Siring
                        //     </WithSpin>
                        // </button>
                    }
                    {bread ?
                        <></>
                        // <button className='btn btn-secondary ml-2 ' disabled={loadingFunc} onClick={() => handleClickOpenBreed(item, true)} style={{ width: "30%" }}>
                        //     Bread

                        // </button> 
                        :
                        <></>
                    }
                    {/* handleClickOpen */}
                </>
            )
        }
    }
    // loadingFunc
    let flag = checkCheck(item.dogeId)
    function checkCheck(value) {
        let currentIndex = -1
        for (let i = 0; i < checkBox?.doge?.length; i++) {
            if (checkBox?.doge[i]?.dogeId == value) currentIndex = i
        }
        if (currentIndex == -1) return false
        return true
    }

    async function giveBirth(matronId) {
        try {
            setIsSpinDoge(true)
            //kiểm tra xem doge có đang mang thai và có thể sinh được chưa
            const contract = await contractServices()
            const doge = await contract.getDoge(matronId)
            const isGestating = doge[0]
            const isReady = doge[1]
            const nextActionAt = doge[3]

            if (isGestating) {
                if (isReady) {
                    await contract.giveBirth(matronId)
                    setIsSpinDoge(false)
                    // await dispatch(getMyDogesAction({ limit: 12, page: 1 }))
                    window.location.reload()
                    toastSuccessMessage(`Successful birth`)
                }
                else {
                    toastErrorMessage(`Doge have not reached the time of laying, will give birth in block number: ${nextActionAt}`)
                    setIsSpinDoge(false)

                }
            } else {
                setIsSpinDoge(false)
                toastErrorMessage(`Dog is not pregnant`)
            }
        } catch (error) {
            setIsSpinDoge(false)
        }
    }
    // !popupSiring ||
    return (
        <>
            {selectItem ?
                item.isGestating == 1 || item.timeIsGestating || dogeBread?.dogeId == item.dogeId || !item.canBreed ? <>
                </>

                    :
                    //  select item
                    <div className={`boxCard reponsive height350 reponsiveHeight`} hover={true} key={index} style={{
                        background: `${item.background}`
                    }} ref={tilt}>
                        {/* <div className={`boxCard ${card ? 'reponsive' : ''}`} hover={true} key={index} ref={tilt}> */}
                        <div className='groupIdAndPrice'>
                            <p className='id'>#{item.dogeId}</p>
                            <div className='priceGroup'>

                            </div>
                        </div>
                        {/* <div className='' style={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                            <p className='id'># {item.dogeId}</p>
                            { }


                        </div> */}
                        {!checkBox.flag ?
                            <>
                                <h2 className="owner top85" style={{ top: "85% !important;" }} ref={tilt}>
                                    {elementSiring()}
                                </h2>
                                <h2 className="name" ref={tilt}>
                                    {/* {item.isGestating == 1 || item.timeIsGestating ?
                                        <>
                                            <button className='btn btn-success' onClick={() => giveBirth(item.dogeId)} style={item.timeIsGestating ? { cursor: "initial" } : {}} disabled={item.timeIsGestating}>
                                                <WithSpin isSpin={isSpinDoge}>
                                                    {item.timeIsGestating ?
                                                        <Countdown value={new Date().getTime() + item.timeIsGestating} format="H : m : s " /> :
                                                        <>
                                                            {
                                                                item.isGestating ?
                                                                    <>
                                                                        Ready to give birth
                                                                    </> :
                                                                    <>
                                                                    </>
                                                            }
                                                        </>
                                                    }
                                                </WithSpin>

                                            </button>
                                        </> : <></>
                                    } */}
                                </h2>

                                {elementFunc()}
                            </> :
                            <></>
                        }


                        {/* {image} */}
                        <img ref={tilt} src={`${port}${item?.image}`} style={{ top: "40% !important;" }} alt="" className='product top40' />
                    </div>
                :
                <>

                    <div className={`boxCard reponsive`} hover={true} key={index} ref={tilt}>
                        {/* <div className={`boxCard ${card ? 'reponsive' : ''}`} hover={true} key={index} ref={tilt}> */}
                        <div className='imgTop' style={{
                            background: `${item.background}`
                        }}>
                        </div>
                        <div className='groupIdAndPrice w-100' >
                            <p className='id'>#{item.dogeId}</p>
                            <div className='priceGroup'>
                                {!sell ?
                                    <>
                                        <h6 className="priceBNB" style={{
                                            textOverflow: "ellipsis"
                                        }} ref={tilt}>
                                            <img src={logoBNB} width={20} height={20} alt="" />
                                            &nbsp;
                                            <p>
                                                {truncate(item.price, 6)} BNB
                                            </p>
                                        </h6>
                                        <p className='priceUSD'> ~ $ {truncate(priceCoin.BNB * item.price, 2)}</p>
                                    </> : <></>
                                }
                            </div>
                        </div>
                        {/* <div className='' style={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                           



                        </div> */}


                        {checkBox?.flag ?
                            <>
                                {item.isGestating == 1 || item.timeIsGestating && item.isReady != 1 ? item.isGestating == 1 ?
                                    <div className='flagStatus' style={{
                                
                                    }}>
                                        <p className='waitingTime'>Pregnant</p>
                                    </div>
                                    :
                                    <div className='flagStatus' style={{
                                  
                                    }}>
                                        <p className='waitingTime'>Resting</p>
                                    </div>
                                    :
                                    <>
                                        <div style={{
                                            position: "absolute",
                                            top: "0",
                                            right: "0",
                                        }}>
                                            <Checkbox {...label} checked={flag} onClick={() => selectDoge(item)} color="default" />

                                        </div>
                                    </>
                                }
                            </>
                            :
                            <></>
                        }
                        {!checkBox.flag ?
                            <>
                                <h2 className="owner" ref={tilt}>
                                    {elementSiring()}
                                </h2>
                                {/* <h2 className="name" ref={tilt}>
                                    {item.isGestating == 1 || item.timeIsGestating ?
                                        <>
                                            <button className='btn btn-success' onClick={() => giveBirth(item.dogeId)} style={item.timeIsGestating ? { cursor: "initial" } : {}} disabled={item.timeIsGestating}>
                                                <WithSpin isSpin={isSpinDoge}>
                                                    {item.timeIsGestating ?
                                                        <Countdown value={new Date().getTime() + item.timeIsGestating} format="H : m : s " /> :
                                                        <>
                                                            {
                                                                item.isGestating ?
                                                                    <>
                                                                        Ready to give birth
                                                                    </> :
                                                                    <>
                                                                    </>
                                                            }
                                                        </>
                                                    }
                                                </WithSpin>

                                            </button>
                                        </> : <></>
                                    }
                                </h2> */}
                                <div className='groupButton'>
                                    {elementFunc()}
                                </div>

                            </> :
                            <></>
                        }


                        {/* {image} */}
                        <img ref={tilt} src={`${port}${item?.image}`} alt="" style={{
                            top: "40%"
                        }} className='product ' />
                    </div>
                </>

            }

        </>

    );
}

export default CardMyDoge;