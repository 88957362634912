import { createSlice } from '@reduxjs/toolkit';
import { getBalanceAction } from './action/contract/balanceReducer';
import { getAllSiringDoges } from './action/contract/dogeReducer';
import { getAllSellingDoges } from './action/api/dogeReducer';
// ${localStorage.getItem('valueSreach')||valueDrawFilterSell}
// ${localStorage.getItem('sellGen')?` AND generation=${localStorage.getItem('sellGen')}`:""} ORDER BY ${localStorage.getItem('valueSortSell')||'birthTime'} ${localStorage.getItem('valueOderBySell')||'ASC'}
let valueDrawFilterSell = `AND attack>=0 AND attack<=11160 AND defense>=0 AND defense<=11160 AND health>=0 AND health<=11160 AND share>=0 AND share<=11160`
const initialState = {
    openPopupConnect : false,
    name: 'user',
    login: false,
    profile: {},
    listMartketPlace: {
        array: [],
        total: 0
    },
    listSiring: {
        array: [],
        total: 0
    },
    priceCoin: {
        BNB: 0,
        wod: 0,
        ruby : 0
    },
    approvedSell: false,
    approvedSiring: false,
    approvedDeposit: false,
    loadingFunc: true,
    whereListAllSell: {
        drawFilter: ``,
        filter: ``
    },
    whereListAllSiring: {
        drawFilter: ``,
        filter: ``
    },
    whereListMyDoge: ``,
    whereListMyDogeSell: ``,
    whereListMyDogeSiring: {
        drawFilter: ``,
        filter: ``
    },
    whereListHero : `ORDER BY ranker ASC`

}
const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        openPopupConnectAction : (state, action) => {
            state.openPopupConnect = action.payload
        },
        whereListHeroAction: (state, action) => {
            state.whereListHero = action.payload
        },
        whereListAllSellAction: (state, action) => {
            state.whereListAllSell.filter = action.payload
        },
        whereListAllSellDrawAction: (state, action) => {
            state.whereListAllSell.drawFilter = action.payload
        },
        whereListAllSiringAction: (state, action) => {
            state.whereListAllSiring.filter = action.payload
        },
        whereListAllSiringDrawAction: (state, action) => {
            state.whereListAllSiring.drawFilter = action.payload
        },
        whereListMyDogeAction: (state, action) => {
            state.whereListMyDoge = action.payload
        },
        whereListMyDogeSellAction: (state, action) => {
            state.whereListMyDogeSell = action.payload
        },
        whereListMyDogeSiringAction: (state, action) => {
            state.whereListMyDogeSiring = action.payload
        },
      
        loginAction: (state, action) => {
            state.login = action.payload
        },
        priceCoinBNBAction: (state, action) => {
            state.priceCoin.BNB = action.payload
        },
        priceCoinAction: (state, action) => {
            state.priceCoin.wod = action.payload.wod
            state.priceCoin.ruby = action.payload.ruby
        },
        profileAction: (state, action) => {
            state.profile = action.payload
        },
        marketPlaceAction: (state, action) => {
            state.listMartketPlace = action.payload
        },
        approvedSellAction: (state, action) => {
            state.approvedSell = action.payload
        },
        approvedSiringAction: (state, action) => {
            state.approvedSiring = action.payload
        },
        approvedDepositAction: (state, action) => {
            state.approvedDeposit = action.payload
        },
        loadingFuncAction: (state, action) => {
            state.loadingFunc = action.payload
        }
    },
    extraReducers: {
        [getBalanceAction.fulfilled]: (state, action) => {
            state.profile = action.payload
        },
        [getAllSellingDoges.fulfilled]: (state, action) => {
            state.listMartketPlace = action?.payload
        },
        [getAllSiringDoges.fulfilled]: (state, action) => {
            state.listSiring = action?.payload
        },
        // [getBalanceAction.pending]: (state) => {
        //     console.log(state, "pending");
        //     state.loading = true
        // },
        // [getBalanceAction.rejected]: (state) => {
        //     console.log(state, "rejected");
        //     state.loading = false;
        //     state.error = ''
        // },

    }
});

const { reducer, actions } = user;
export const {
    priceCoinAction,whereListHeroAction,whereListAllSiringDrawAction,whereListMyDogeSiringAction,openPopupConnectAction, whereListMyDogeSellAction, approvedDepositAction, whereListAllSellDrawAction, whereListMyDogeAction, loginAction, whereListAllSiringAction, profileAction, whereListAllSellAction, priceCoinBNBAction, marketPlaceAction, approvedSellAction, loadingFuncAction, approvedSiringAction
} = actions;
export default reducer;