import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllSellingDoges, getMyDogesAction } from './action/contract/dogeReducer';
import { getMyDogesActionFunc, getMySiringDoges, getMySellingDogesFunc, getMyDogesDeposit, getMySellingDoges, getDogeDetail, getAllHeros, getHeroDetail, getHistoryAttackAll, getHistoryAttackHero, getHistoryBuySellAll, getHistoryBuySellAddress, getMyDogesSelectAction, getHistorySiringAll, getHistorySiringAddress } from './action/api/dogeReducer';

// export const myDogeDispatch = createAsyncThunk('doge/myDogeDispatch', async (limit,page) => {
//     try {
//         const [myDoges, myDogesSelling] = await Promise.all([getMyDogesActionFunc(limit,page)])
//         // const [myDoges, myDogesSelling] = await Promise.all([getMyDogesActionFunc(limit,page), getMySellingDogesFunc(limit,page)])
//         // const myDoges = await getMyDogesActionFunc()
//         // const myDogesSelling = await getMySellingDogesFunc()
//         console.log("123",{ myDoges : myDoges.myDoges, myDogesSelling : {} })
//         return { myDoges : myDoges.myDoges, myDogesSelling : {} }
//     } catch (error) {

//     }
// })
// getAllHeros
const initialState = {
    allHeros: {
        array: [],
        total: 0
    },
    myDoges: {
        array: [],
        total: 0
    },
    myDogesSelling: {
        array: [],
        total: 0
    },
    allDogesSelling: {
        array: [],
        total: 0
    },
    myDogesSelect: {
        array: [],
        total: 0
    },
    myDogesSiring: {
        array: [],
        total: 0
    },
    myDogesDeposit: {
        array: [],
        total: 0
    },
    historyAttackAll: {
        array: [],
        total: 0
    },
    historyAttackHero: {
        array: [],
        total: 0
    },
    historySiringAll: {
        array: [],
        total: 0
    },
    historySiringAddress: {
        array: [],
        total: 0
    },
    historyBuySellAll: {
        array: [],
        total: 0
    },
    historyBuySellAddress: {
        array: [],
        total: 0
    },
    // getMyDogesDeposit
    dogeDetail: {},
    heroDetail: {}

}
const doge = createSlice({
    name: 'doge',
    initialState,
    reducers: {
        loginAction: (state, action) => {
            state.login = action.payload
        },
        filterHerosAllAction: (state, action) => {
            state.allHeros = action.payload
        }
    },
    extraReducers: {
        // historySiringAll
        // getHistorySiringAll
        // historySiringAddress
        [getHistorySiringAll.fulfilled]: (state, action) => {
            state.historySiringAll = action.payload
        },
        [getHistorySiringAddress.fulfilled]: (state, action) => {
            state.historySiringAddress = action.payload
        },
        [getMyDogesSelectAction.fulfilled]: (state, action) => {
            state.myDogesSelect = action.payload?.myDoges
        },
        [getHistoryBuySellAll.fulfilled]: (state, action) => {
            state.historyBuySellAll = action?.payload
        },
        [getHistoryBuySellAddress.fulfilled]: (state, action) => {
            state.historyBuySellAddress = action?.payload
        },
        [getHistoryAttackHero.fulfilled]: (state, action) => {
            state.historyAttackHero = action?.payload
        },
        [getHistoryAttackAll.fulfilled]: (state, action) => {
            state.historyAttackAll = action?.payload
        },
        [getMyDogesDeposit.fulfilled]: (state, action) => {
            state.myDogesDeposit = action?.payload
        },
        [getHeroDetail.fulfilled]: (state, action) => {

            state.heroDetail = action?.payload
        },
        [getMySiringDoges.fulfilled]: (state, action) => {

            state.myDogesSiring = action?.payload
        },
        [getMyDogesAction.fulfilled]: (state, action) => {
            state.myDoges = action.payload?.myDoges
        },
        [getMySellingDoges.fulfilled]: (state, action) => {
            state.myDogesSelling = action?.payload
        },
        [getDogeDetail.fulfilled]: (state, action) => {
            state.dogeDetail = action?.payload
        },
        [getAllHeros.fulfilled]: (state, action) => {
            state.allHeros = action?.payload
        },
        // [myDogeDispatch.fulfilled]: (state, action) => {
        //     state.myDoges = action.payload?.myDoges
        //     state.myDogesSelling = action?.payload?.myDogesSelling
        // },


    }
});

const { reducer, actions } = doge;
export const {
    loginAction, filterHerosAllAction
} = actions;
export default reducer;